import React, { useEffect, useState } from "react";
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { AppBarCompany,  } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from "../../../components/common/CustomModal";
import CreateContacts from '../../contacts/createcontacts';
import { getCompanies, getLeadSource, getCountries, getBusinessGroup, getContactDepartments } from "../../../services/CommonServices";
import { Delete_Contact_contactId, GET_contact } from "../../../services/apiRoutes";
import { genericGet } from "../../../services/apiServices";
import { IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import UpdateContacts from "../../contacts/UpdateContacts";
import MessageBox from "../../common/CustomMessageBox";
import CustomDelete from '../../../components/common/CustomDeleteAlert'
import axios from "axios";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate, useParams } from "react-router-dom";
import { useClientCompanyDetails } from "./UseClientCompanyDetails";



export default function PersistentDrawerLeft() {
    const navigate = useNavigate();  // Initialize useNavigate
    const { clientCompanyId } = useParams();
    const { companyDetail } = useClientCompanyDetails(clientCompanyId);
    const [open, setOpen] = useState(true);
    const [leadModal, setLeadModal] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [activeTab, setActiveTab] = useState('Contacts');
    const [modal, setModal] = useState(false);
    const [leadSource, setLeadSource] = useState([]);
    const [countries, setCountries] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [businessGroup, setBusinessGroup] = useState([]);
    const [contactDepartments, setContactDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [updateModalOpen, setUpdateModalOpen] = useState(false); // Correct name
    const [selectedId, setselectedId] = useState(null);
    const [rowId, setRowId] = useState('')
    const [deleteAlert, setDeleteAlert] = useState(false)


    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setLeadModal(true);
    };

    const handleClose = () => {
        setLeadModal(false);
    };

    const handleDelete = async (contactId) => {
        setLoading(true)
        const authToken = sessionStorage.getItem('accessToken');
        try {
            const response = await axios.delete(Delete_Contact_contactId(contactId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                fetchContacts()
                setDeleteAlert(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error deleting contactId", error);
        }
    };


    useEffect(() => {
        fetchContacts();
        getCommonData();
    }, []);

    const fetchContacts = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_contact });
            if (response.data.success && response.data.contacts) {
                const contactData = response.data.contacts.map((contact) => ({
                    contactId: contact.contactId || '',
                    title: contact.title || '',
                    description: contact.description || '',
                    createdAt: contact.createdAt || '',
                    updatedAt: contact.updatedAt || '',
                    user: contact.user?.firstName ? `${contact.user.firstName} ${contact.user.lastName}` : 'N/A',
                    company: contact.company?.companyName || 'N/A',
                    contactOwner: contact.contactOwner || 'N/A',
                    leadSource: contact.leadSource?.leadSourceName || 'N/A',
                    contactGroup: contact.contactGroup?.contactGroupName || 'N/A',
                    contactType: contact.contactType?.contactTypeName || 'N/A',
                    clientCompany: contact.clientCompany || 'N/A',
                    department: contact.department || 'N/A',
                }));
                setContacts(contactData);
            } else {
                setError('No contacts found');
            }
        } catch (error) {
            setError('Error fetching contacts');
            console.error('Error fetching contacts', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    const getCommonData = async () => {
        const leadSourceData = await getLeadSource();
        setLeadSource(leadSourceData);
        const countriesData = await getCountries();
        setCountries(countriesData);
        const companiesData = await getCompanies();
        setCompanies(companiesData);
        const businessGroupData = await getBusinessGroup();
        setBusinessGroup(businessGroupData);
        const contactDepartmentsData = await getContactDepartments();
        setContactDepartments(contactDepartmentsData);
    };

    const handleViewClick = (contactId) => {
        console.log(`View details for leadId: ${contactId}`);
    };

    const handleUpdateClick = (contactId) => {
        handleOpenUpdate(contactId);
    };

    const handleOpenUpdate = (contactId) => {
        setselectedId(contactId);
        setUpdateModalOpen(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModalOpen(false); // Correct name
        setselectedId(null);
    };

    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true)
        setRowId(rowId)
    }

    const CompanyContactColumn = [
        { accessor: "contactId", label: "SN.", id: "contactId" },
        { accessor: "contactName", label: "Contact Name", id: "contactName" },
        { accessor: "contactNo", label: "Contact No.", id: 'contactNo' },
        { accessor: "emailId", label: "Email ID", id: "emailId" },
        { accessor: "company", label: "Company", id: 'company' },
        { accessor: "country", label: "Country", id: "country" },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleViewClick(row.contactId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: "#059212" }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.contactId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.contactId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                    </IconButton>
                </div>
            ),
        },
    ];


    if (!companyDetail) {
        return <p>Loading...</p>;
    }
    const companyName = companyDetail.clientCompanyName;
    const phoneNumber = companyDetail.mobile;
    const initial = companyDetail.clientCompanyName ? companyDetail.clientCompanyName.charAt(0).toUpperCase() : '';
    const handleBackClick = () => {
        navigate("/app/company");
    };

    return (
        <div>
            <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={AppBarCompany(clientCompanyId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick} />
            <Main open={open}>
                <DetailsPageCard
                    columns={CompanyContactColumn}
                    title={'Contacts'}
                    buttonTitle={'Create Contacts'}
                    handleOpen={handleOpen}
                    data={contacts}
                />
                <CustomModal open={leadModal} handleClose={handleClose}>
                    <CreateContacts
                        handleClose={handleClose}
                        leadSource={leadSource}
                        fetchContacts={fetchContacts}
                        setMessage={setMessage}
                        companies={companies}
                        businessGroup={businessGroup}
                        contactDepartments={contactDepartments}
                    />
                </CustomModal>
                <CustomModal
                    open={updateModalOpen} // Corrected name
                    handleClose={handleCloseUpdate} // Corrected function
                >
                    <UpdateContacts
                        handleClose={handleCloseUpdate} // Corrected function
                        contacts={contacts.find((v) => v.contactId === selectedId)}
                        contactId={selectedId}
                        fetchContacts={fetchContacts}
                        setMessage={setMessage}
                        leadSource={leadSource}
                        companies={companies}
                        businessGroup={businessGroup}
                        contactDepartments={contactDepartments}
                    />
                </CustomModal>

                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />
                <CustomDelete
                    handleDelete={handleDelete}
                    open={deleteAlert}
                    rowId={rowId}
                    handleClose={() => setDeleteAlert(false)}
                />
            </Main>
        </div>
    );
}
