import React from "react";
import {
    Divider,
    Button,
    TableCell,
    TableContainer,
    Paper,
    Typography,
    CardHeader,
    Table,
    TableBody,
    TableRow,
    TableFooter,
    Card,
    Box,
    TableHead,
    Grid,
} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { PhoneEnabled, West } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";

const DetailsParticulars = ({ columns, data, title, buttonTitle, handleOpen, footerData, footerCellStyles }) => {
    return (
        <>
            <TableContainer  >
                <Table
                    style={{
                        width: "100%",
                        border: "1px solid #dee2e6",
                        borderCollapse: "collapse",
                    }}
                >
                    <TableHead style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                        <TableRow style={{ border: "1px solid #dee2e6" }}>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    colSpan={column.colSpan || 1}
                                    style={{ ...styles.cellLabel, ...column.style }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.length > 0 ? (
                            data.map((row, rowIndex) => (
                                <TableRow key={rowIndex}
                                    style={{
                                        border: "1px solid #dee2e6",
                                        color: "#212529",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "clip",
                                    }}
                                >
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            style={{ ...styles.cellAccessor, ...column.styleAccessor }}
                                        >
                                            {column.Cell ? (
                                                <column.Cell row={row} />
                                            ) : (
                                                // row[column.accessor]
                                                column.accessor.split('.').reduce((acc, key) => acc && acc[key], row) || ''
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow style={{ border: "1px solid #dee2e6" }}>
                                <TableCell
                                    colSpan={columns.length}
                                    style={{
                                        textAlign: "center",
                                        border: "1px solid #dee2e6",
                                    }}
                                >
                                    No Record Found!
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>

                    {/* Table Footer */}
                    {footerData && (
                        <TableFooter style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                            <TableRow>
                                {footerData.map((footer, index) => (
                                    <TableCell
                                        colSpan={footer.colSpan || 1}
                                        key={index} style={{ ...styles.footerCell, ...footer.style }}>
                                        {footer.label} {footer.footerValue}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableFooter>
                    )}
                </Table>
            </TableContainer>
        </>
    );
};

export default DetailsParticulars;

const styles = {
    cellLabel: {
        border: "1px solid #dee2e6",
        color: "#212529",
        fontWeight: "600",
        fontSize: "14px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "clip",
        padding: "5px",
        textAlign: 'center',

    },
    cellAccessor: {
        border: "1px solid #dee2e6",
        padding: "5px",
        textAlign: "left"

    },
    footerCell: {
        border: "1px solid #dee2e6",
        color: "#212529",
        fontWeight: "600",
        fontSize: "14px",
        whiteSpace: "nowrap",
        padding: "5px",
    },
};