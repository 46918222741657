import axios from 'axios';


export const createTransactionResultFrom = (response) => ({
  ok: response.ok,
  status: response.status,
  headers: response.headers,
});

const isContentTypeJSON = (headers) =>
  headers['content-type']?.includes('application/json') ?? false;



export const genericPost = async (url, body) => {

  const authToken = sessionStorage.getItem('accessToken');

  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    // Create transaction result from response
    const transactionResult = createTransactionResultFrom(response);

    // Assuming createTransactionResultFrom and isContentTypeJSON are utility functions you have
    const isJson = response.headers['content-type']?.includes('application/json');

    if (response.status >= 200 && response.status <= 299) {
      transactionResult.data = isJson ? response.data : undefined;
    }

    if (response.status >= 400 && response.status <= 499) {
      transactionResult.error = isJson ? response.data : undefined;
    }

    return transactionResult;
  } catch (error) {
    // Handle axios-specific error structure
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const isJson = error.response.headers['content-type']?.includes('application/json');
      const errorData = isJson ? error.response.data : undefined;

      return {
        error: errorData,
        status: error.response.status,
        // Optionally add more fields to the transactionResult based on your needs
      };
    } else if (error.request) {
      // The request was made but no response was received
      return {
        error: 'No response received',
        status: 0,
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      return {
        error: error.message,
        status: 0,
      };
    }
  }
};
