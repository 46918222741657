import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Pagination, Divider } from '@mui/material';
import { List, ListItem, ListItemText } from '@mui/material';
import { Container, Grid, } from '@mui/material';
import { Link } from 'react-router-dom';
import BannerSearch from './BannerSearch';


const BlogDetails = () => {


    return (
        <>
            <BannerSearch />
            <Container maxWidth="lg" sx={{ paddingY: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <Box id="brxe-yaloog" sx={styles.sidebar}>
                            <List component="ul" sx={styles.list}>
                                <ListItem sx={styles.listItem}>
                                    <Typography variant="h2" component="h2" sx={styles.heading}>
                                        Categories
                                    </Typography>
                                </ListItem>
                                <ListItem sx={styles.listItem}>
                                    <List component="ul" sx={styles.list}>
                                        {categories.map((category, index) => (
                                            <ListItem key={index} sx={styles.listItem}>
                                                <Link to={category.href} style={{ fontWeight: "700", fontSize: "14px" }}>
                                                    {category.label}
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </List>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9}>

                        <Box sx={{ padding: 2 }}>
                            {currentPosts.map((post, index) => (
                                <Card key={index} sx={{ marginBottom: 2 }}>
                                    <CardContent>
                                        <Typography variant="h3" component="div">
                                            <Link
                                                href={post.url}
                                                aria-label={`Read more about ${post.title}`}
                                                color="inherit"
                                                style={{ textDecoration: 'none', fontWeight: "bold", fontSize: "20px" }}
                                            >
                                                {post.title}
                                            </Link>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Last updated on {post.date} by <Link color="inherit" sx={{ textDecoration: 'none' }}>{post.author}</Link>
                                        </Typography>
                                        <Box sx={{ display: "flex", alignItems: "top", gap: 2 }}>

                                            <Typography variant="body1" sx={{ flex: 1, pt: 2 }}>
                                                {post.excerpt}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography paragraph>
                                                If you’re involved in Global Trade you would be aware that importers and exporters agree to sell goods on agreed payment terms that usually involve a SWIFT Telegraphic Transfer, or T/T payment. Before products can be exported around the world the buyer and seller will agree on the terms of trade (including <Link href="#">Incoterms®</Link>) then include all details in Purchase Orders, Proforma Invoices and Sales Contracts that are countersigned by both parties.
                                            </Typography>
                                            <Typography paragraph>
                                                When the terms in the contracts are agreed, the buyer will need to make an International payment to the overseas supplier. Buyers most often transfer funds internationally by Telegraphic Transfer (T/T) to send money to overseas suppliers. However, there are different payment methods that buyers can use to transfer money Internationally. International money transfers usually require exchanging currencies and come with costs and fees.
                                            </Typography>
                                            <Typography paragraph>
                                                It’s important for importers to understand their payment options and how to secure the best exchange rate for International payments to overseas suppliers. Here’s a list of some types of International payment methods buyers can use to send money overseas:
                                            </Typography>
                                        </Box>
                                        <Typography variant="h2" gutterBottom>
                                            International Wire Transfers via Banks – SWIFT Telegraphic Transfer TT payments
                                        </Typography>
                                        <Box>
                                            <Typography paragraph>
                                                Buyers can make International payments through their local banks. Most banks will use the SWIFT banking system. You can walk into a bank to make an International wire transfer, but unless you have negotiated a good foreign exchange rate with your local business banker, this option will generally not provide a good exchange rate. Some banks will offer an online version of their SWIFT payment system for buyers to login and make payments online. <em><strong>Read more details on how International wire transfers work further down.</strong></em>
                                            </Typography>
                                        </Box>
                                        <Typography variant="h2" gutterBottom>
                                            Foreign Exchange Providers with Good Exchange Rates
                                        </Typography>
                                        <Box>
                                            <Typography paragraph>
                                                Foreign Exchange (FX) Providers are companies that can offer very competitive foreign exchange rates for importers to make SWIFT payments Internationally. They operate independently to banks and can negotiate good deals for International wire transfers.
                                            </Typography>
                                        </Box>
                                        <Typography variant="h2" gutterBottom>
                                            Credit Cards for International payments
                                        </Typography>
                                        <Box>
                                            <Typography paragraph>
                                                Credit Cards are easily accessible for buyers in most countries around the world. Payment by VISA, Mastercard or American Express credit cards can be an easy but quite expensive option to make International payments, especially for high value transactions.
                                            </Typography>
                                        </Box>
                                        <Typography variant="h2" gutterBottom>
                                            PayPal for International Payments
                                        </Typography>
                                        <Box>
                                            <Typography paragraph>
                                                PayPal is a popular alternative payment method that allows users to transfer funds internationally, offered by many vendors around the world. However, making an International foreign exchange payment using PayPal will not give a good exchange rate to the payer. It is not ideal for high value transactions and is generally used for convenience.
                                            </Typography>
                                        </Box>
                                        <Typography variant="h2" gutterBottom>
                                            Western Union Wire Transfer
                                        </Typography>
                                        <Box>
                                            <Typography paragraph>
                                                Western Union is a transfer service that has been operating since the 19th Century. Some suppliers may still request payment via Western Union but making International payments this way have proven to take a long time to clear into the seller’s bank account. Western Union generally do not offer buyers good exchange rates and have high fees and charges.
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <img
                                                width="1024"
                                                height="683"
                                                src="https://incodocs.com/blog/wp-content/uploads/2020/02/Foreign-exchange-international-wire-transfer-1024x683.jpg"
                                                alt="Foreign Exchange Currencies"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        </Box>
                                        <Divider sx={{ my: 2 }} />
                                        <Typography variant="h2" gutterBottom>
                                            An overview on International payments using SWIFT, Wire or Telegraphic Transfer (T/T)
                                        </Typography>
                                        <Box>
                                            <Typography paragraph>
                                                SWIFT is a system that is behind most of the world’s International money and security transfers. Banks and financial institutions use it to securely transmit information and instructions through a standardized system of codes (made up of 8 or 11 characters). Before the SWIFT payment system, Telex was the only means to make International funds transfer which required senders to describe transactions in sentences which were interpreted and executed by the receiver. The Telex process was a slow, unsecure process that was prone to human errors.
                                            </Typography>
                                            <Typography paragraph>
                                                The SWIFT (<Link href="#">Society for Worldwide Interbank Financial Telecommunications</Link>) system, formed in 1974 by 7 major international banks, is widely used to facilitate secure international money transfers using the SWIFT network. It began a cooperative society to operate a global network to transfer financial messages faster and more securely than the Telex system. Today the SWIFT network is the global standard for transferring money Internationally.
                                            </Typography>
                                        </Box>
                                        <Typography variant="h2" gutterBottom>
                                            The Differences Between Telegraphic Transfers and SWIFT Payments
                                        </Typography>
                                        <Box>
                                            <Typography paragraph>
                                                In international business, you’ll hear terms like “Telegraphic Transfer”, “SWIFT wire transfer”, “tt payment”, and “telex transfer”. These aren’t just fancy words; they’re important parts of how money moves around the world. Simply put, A Telegraphic Transfer (T/T) is a method of transferring funds from one bank account to another electronically. SWIFT wire transfers are a special system banks use worldwide to safely share details, including how to move money. It’s important for everyone in business to know what these terms mean to make sure money transfers go smoothly and safely.
                                            </Typography>
                                        </Box>
                                        <Typography variant="h2" gutterBottom>
                                            How long does a SWIFT payment take to clear into the receiver’s bank account?
                                        </Typography>
                                        <Box>
                                            <Typography paragraph>
                                                The SWIFT network relies on transferring funds from one account to another between multiple banks before the funds are received in the seller’s bank account. This process can take anywhere from 1-5 working days, depending on the banks’ processing times and the countries involved in sending and receiving the transaction. Public holidays will add to the time taken to clear money into the beneficiary’s account.
                                            </Typography>
                                        </Box>
                                        <Typography variant="h2" gutterBottom>
                                            What details must the exporter provide to the importer to make an International SWIFT wire transfer?
                                        </Typography>
                                        <Box>
                                            <Typography paragraph>
                                                Exporters will generally share a <Link href="#">Proforma Invoice or Commercial Invoice</Link> that includes all details of the order, along with their SWIFT and IBAN bank account information, requesting the buyer to make a bank transfer via Telegraphic Transfer (T/T). The exporter may request a deposit payment to get the order under production. The bank details will include the below:
                                            </Typography>
                                        </Box>
                                        <Typography variant="h3" gutterBottom>
                                            Beneficiary Details
                                        </Typography>
                                        <Box>
                                            <ul>
                                                <li>Beneficiary Business Name</li>
                                                <li>Beneficiary Business Address</li>
                                            </ul>
                                        </Box>
                                        <Typography variant="h3" gutterBottom>
                                            Payee Bank Details
                                        </Typography>
                                        <Box>
                                            <ul>
                                                <li>SWIFT Code (Bank ID). <em>Each financial institution in the SWIFT network has a unique SWIFT code that is between 8 and 11 characters. This is known as a SWIFT ID or a bank identifier code (BIC). You can <Link href="#">lookup a SWIFT/BIC Code here</Link>. <strong>For example, to make a Bank of America wire transfer, the Bank of America SWIFT code is BOFAUS3N</strong></em></li>
                                                <li>Bank Location (Country)</li>
                                                <li>Bank Location (City)</li>
                                                <li>Bank Name</li>
                                                <li>Intermediary Bank Details (optional). If the seller uses an Intermediary bank to accept payments, the Intermediary bank details should also be included, <em>i.e. SWIFT Code (Bank ID) Bank Location (Country), Bank Location (City), Bank Name.</em></li>
                                                <li>IBAN – International Bank Account Number</li>
                                            </ul>
                                        </Box>
                                        <Typography variant="h3" gutterBottom>
                                            Transaction Details
                                        </Typography>
                                        <Box>
                                            <ul>
                                                <li>Transaction Currency</li>
                                                <li>Transaction Amount</li>
                                                <li>Reference Number (generally a unique Invoice or Purchase Order Number)</li>
                                            </ul>
                                        </Box>
                                        <Typography variant="h2" gutterBottom>
                                            How to make International Wire Transfer (T/T) Payments
                                        </Typography>
                                        <Box>
                                            <Typography paragraph>
                                                When buyers are ready to make an International payment, they can use their local banks or transfer funds via an online International money transfer platform. Importers can either visit their local bank to make an International wire transfer or log in to an online International payment platform such as OFX or Payoneer. The buyer must have all the SWIFT bank details of the beneficiary to complete the transfer.
                                            </Typography>
                                            <Typography paragraph>
                                                To make an International money transfer (Telegraphic Transfer) to the supplier, the buyer will need to include the following information:
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="h3" gutterBottom>
                                                Buyer Information
                                            </Typography>
                                            <ul>
                                                <li>Full Name</li>
                                                <li>Account Number</li>
                                                <li>Bank Name and Address</li>
                                            </ul>
                                            <Typography variant="h3" gutterBottom>
                                                Payee Information
                                            </Typography>
                                            <ul>
                                                <li>Beneficiary Business Name</li>
                                                <li>SWIFT Code (Bank ID)</li>
                                                <li>IBAN</li>
                                                <li>Amount</li>
                                                <li>Reference</li>
                                            </ul>
                                        </Box>
                                        <Typography paragraph>
                                            As explained above, International wire transfers may take 1-5 working days to clear into the payee’s account, depending on the banks’ processing times and the countries involved.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ))}

                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default BlogDetails;

const currentPosts = [
    {
        "title": "An Overview of SWIFT International Telegraphic Transfers and other International payment methods",
        "author": "Ben Thompson",
        "date": "May 28, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2020/01/IncoTerms-2020-Explained-Import-Export-Small-150x150.png",
        "excerpt": "The International Chamber of Commerce have published new Incoterms® 2020 that have come into effect from the 1st of January 2020 . A Bill of Exchange is a document that is used in global trade as a written order binding one party to pay a fixed sum of money",
        "url": "#"
    },

];
const styles = {
    sidebar: {
        // Add custom styles for the sidebar
    },
    list: {
        padding: 0,
        listStyle: 'none',
    },
    listItem: {
        // Add custom styles for ListItems if needed
    },
    heading: {
        // Add custom styles for the Typography heading
    },

};

const categories = [
    { href: '#', label: 'Incoterms®' },
    { href: '#', label: 'Sourcing & Negotiation' },
    { href: '#', label: 'Landed Cost Calculations' },
    { href: '#', label: 'Contracts, Orders & International Payments' },
    { href: '#', label: 'Manufacturing & Export' },
    { href: '#', label: 'Shipping & Logistics' },
    { href: '#', label: 'Shipping Documentation' },
    { href: '#', label: 'Sales & Marketing' },
    { href: '#', label: 'Warehousing & Inventory' },
    { href: '#', label: 'Software, Tools & Resources' },
    { href: '#', label: 'Global Trade News' },
];
