import { Box, Typography, Grid, CircularProgress, Divider, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Card } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import axios from 'axios';
import React, { useState, useEffect } from "react";
import { READ_BL_DRAFT_EXPORT } from '../../../services/apiRoutes';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import DetailsParticulars from '../../common/DetailsPage/DetailsParticulars';



const BLDraftView = ({ handleClose, setMessage, fetchData, blDraftId, }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch data for the commercial invoice
    useEffect(() => {
        if (!blDraftId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_BL_DRAFT_EXPORT(blDraftId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [blDraftId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    // Prepare values for Formik and rendering
    const values = {
        blDraftId: data?.blDraftId || '',
        userId: data?.userId || '',
        companyId: data?.companyId || '',
        clientCompanyId: data?.clientCompanyId || '',
        shipper: data?.shipper || '',
        paymentMethod: data?.paymentMethod || '',
        blNumber: data?.blNumber || '',
        orderMethod: data?.orderMethod || '',
        consignee: data?.consignee || '',
        hsCode: data?.hsCode || '',
        specialRequirement: data?.specialRequirement || '',
        notifyParty: data?.notifyParty || '',
        alsoNotify: data?.alsoNotify || '',
        oceanVesselVoyNo: data?.oceanVesselVoyNo || '',
        portOfLoading: data?.portOfLoading || '',
        excessValueDeclaration: data?.excessValueDeclaration || '',
        portOfDestination: data?.portOfDestination || '',
        finalDestination: data?.finalDestination || '',
        freightPayableAt: data?.freightPayableAt || '',
        noOfOriginals: data?.noOfOriginals || '',
        marks: data?.marks || '',
        noOfPkgs: data?.noOfPkgs || '',
        descriptionOfGoods: data?.descriptionOfGoods || '',
        grossWeightData: data?.grossWeight || '',
        measurementData: data?.measurement || '',
        createdAt: data?.createdAt || '',
        updatedAt: data?.updatedAt || '',
        particulars: data?.particulars || [],
    };


    const labels = [
        { key: 'shipper', label: 'Shipper', sm: 12, md: 6 },
        { key: 'paymentMethod', label: 'Payment Method', sm: 12, md: 6 },
        { key: 'consignee', label: 'Consignee', sm: 12, md: 6 },
        { key: 'blNumber', label: 'BL Number', sm: 12, md: 6 },
        { key: 'orderMethod', label: 'Order Method', sm: 12, md: 6 },
        { key: 'hsCode', label: 'HS Code', sm: 12, md: 6 },
        { key: 'specialRequirement', label: 'Special Requirement', sm: 12, md: 6 },
        { key: 'notifyParty', label: 'Notify Party', sm: 12, md: 6 },
        { key: 'alsoNotify', label: 'Also Notify', sm: 12, md: 6 },
        { key: 'oceanVesselVoyNo', label: 'Ocean Vessel Voy No', sm: 12, md: 6 },
        { key: 'portOfLoading', label: 'Port of Loading', sm: 12, md: 6 },
        { key: 'excessValueDeclaration', label: 'Excess Value Declaration', sm: 12, md: 6 },
        { key: 'portOfDestination', label: 'Port of Destination', sm: 12, md: 6 },
        { key: 'finalDestination', label: 'Final Destination', sm: 12, md: 6 },
        { key: 'freightPayableAt', label: 'Freight Payable At', sm: 12, md: 6 },
        { key: 'noOfOriginals', label: 'No. of Originals', sm: 12, md: 6 },

    ];

    const column = [
        { id: 'marks', label: 'marks', accessor: 'marks' },
        { id: 'noOfPkgs', label: 'No. of Packages', accessor: 'noOfPkgs' },
        { id: 'descriptionOfGoods', label: 'Description of Goods', accessor: 'descriptionOfGoods' },
        { id: 'grossWeightData', label: 'Gross Weight', accessor: 'grossWeightData' },
        { id: 'measurementData', label: 'Measurement', accessor: 'measurementData' },
    ];

    const particularsColumns = [
        { id: 'sealNo', label: 'Seal No', accessor: 'sealNo' },
        { id: 'type', label: 'Type', accessor: 'type' },
        { id: 'noOfPkgs', label: 'No of Packages', accessor: 'noOfPkgs' },
        { id: 'grossWeight', label: 'Gross Weight', accessor: 'grossWeight' },
        { id: 'measurement', label: 'Measurement', accessor: 'measurement' },
    ];
    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik initialValues={values} enableReinitialize>
                {() => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#212529" }}>
                                    BL Draft
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>
                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="print">
                                        <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close" onClick={handleClose} >
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} style={{ marginTop: "5px" }}>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' sx={{ height: "100px" }}>
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Shipper
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px", }}>
                                        {values.shipper}
                                    </Typography>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined'>
                                    <Box sx={containerStyle}>
                                        <Box sx={innerBoxStyle}>
                                            <Typography variant="body1" sx={labelTextStyle}>
                                                Payment Method
                                            </Typography>
                                            <Typography variant="body1"> {values.paymentMethod}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={containerStyle} style={{ paddingTop: "12px", paddingBottom: "12px" }}>
                                        <Box sx={innerBoxStyle}>
                                            <Typography variant="body1" sx={labelTextStyle}>
                                                BL Number
                                            </Typography>
                                            <Typography variant="body1"> {values.blNumber}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={containerStyle}>
                                        <Box sx={innerBoxStyle}>
                                            <Typography variant="body1" sx={labelTextStyle}>
                                                Order Method
                                            </Typography>
                                            <Typography variant="body1"> {values.orderMethod}</Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' sx={{ height: "118px" }}>
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Consignee
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px", }}>
                                        {values.consignee}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                HS Code
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        {values.hsCode}

                                    </Typography>

                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Please Indicate any special requirement below
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        {values.specialRequirement}

                                    </Typography>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Notify Party
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        {values.notifyParty}

                                    </Typography>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Also Notify
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        {values.alsoNotify}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Ocean Vessel Voy No.
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        {values.oceanVesselVoyNo}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>

                                                Port of Loading
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        {values.portOfLoading}

                                    </Typography>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Excess Value Declaration
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        {values.excessValueDeclaration}

                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Port Of Destination
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        {values.portOfDestination}

                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Final Destination
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        {values.finalDestination}

                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Freight Payable at
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        {values.freightPayableAt}

                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                No.of originals
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        {values.noOfOriginals}

                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Marks
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px", height: '250px !important' }}>
                                        {values.marks}

                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} lg={2}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                No. Of PKGS
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px", height: '250px !important' }}>


                                        {values.noOfPkgs}

                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Description of Goods
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px", height: '250px !important' }}>
                                        {values.descriptionOfGoods}

                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} lg={2}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Gross Weight
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px", height: '250px !important' }} >
                                        {values.grossWeight}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} lg={2}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Measurement
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px", height: '250px !important' }} >
                                        {values.measurement}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table
                                        style={{
                                            width: "100%",
                                            border: "1px solid #dee2e6",
                                            borderCollapse: "collapse",
                                        }}
                                    >
                                        <TableHead style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                                            <TableRow style={{ border: "1px solid #dee2e6" }}>
                                                <TableCell style={cellLabel}>Marks</TableCell>
                                                <TableCell style={cellLabel}>No. of Packages</TableCell>
                                                <TableCell style={cellLabel}>Description of Goods</TableCell>
                                                <TableCell style={cellLabel}>Gross Weight</TableCell>
                                                <TableCell style={cellLabel}>Measurement</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                style={{
                                                    border: "1px solid #dee2e6",
                                                    color: "#212529",
                                                    fontWeight: "600",
                                                    fontSize: "14px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "clip",
                                                }}
                                            >
                                                <TableCell style={tableCell}>{values.marks}</TableCell>
                                                <TableCell style={tableCell}>{values.noOfPkgs}</TableCell>
                                                <TableCell style={tableCell}>{values.descriptionOfGoods}</TableCell>
                                                <TableCell style={tableCell}>{values.grossWeightData}</TableCell>
                                                <TableCell style={tableCell}>{values.measurementData}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                        Particulars
                                    </Typography>
                                </Box>
                                <DetailsParticulars
                                    title="Particulars"
                                    columns={particularsColumns}
                                    data={data?.particulars}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box >
    );
};

export default BLDraftView;


const cellLabel = {
    border: "1px solid #dee2e6",
    color: "#212529",
    fontWeight: "600",
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "clip",
    padding: "5px",
    textAlign: 'center',

}

const tableCell = {
    border: "1px solid #dee2e6",
    padding: "5px",
    textAlign: "left"

};
const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 4
};

const innerBoxStyle = {
    display: 'flex',
    alignItems: 'center',
};

const labelTextStyle = {
    fontWeight: '500',
    display: 'block',
    marginRight: '8px',
    backgroundColor: "#e7f0f7",
    pl: "10px",
    width: "170px",
};
