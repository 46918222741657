import React, { useState } from 'react';
import { Box, CardActions, Container, Grid, Typography } from '@mui/material';
import { Divider, styled } from '@mui/material';
import CustomButton from '../common/CustomButton';
import { Save } from '@mui/icons-material';
import CustomTextField from '../common/CustomTextField';
import { TextField, InputAdornment } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomAutocomplete from '../common/CustomAutoComplete';


const SystemCompany = () => {
    const [formData, setFormData] = useState({
        common_sales_executive: '1',
        common_branch: '0',
        cr_dr_setting: '1',
        common_rate: '1',
        common_sales_invnum: '1',
        common_purchase_invnum: '0',
        common_price_level: '1',
        discount: '0',
        add_ledger: '1',
        product_sms_alert: '0',
        product_email_alert: '1',
        product_com: '1',
        bar_code: '0',
        start_current_period: '01/04/2023',
        end_current_period: '31/03/2024',
        book_date: '01/04/2023',
        common_financialyr: '01/04/2023',
        common_deci_format: '0',
        common_signout: ''
    });

    const initialStates = {
        common_sales_executive: true,
        common_branch: true,
        cr_dr_setting: true,
        common_rate: false,
        common_sales_invnum: false,
        common_purchase_invnum: false,
        common_price_level: true,
        discount: false,
        add_ledger: true,
        product_sms_alert: false,
        product_email_alert: true,
        product_com: false,
        bar_code: true
    };

    const [toggleStates, setToggleStates] = useState(initialStates);

    const handleToggleClick = (name) => {
        setToggleStates(prevStates => ({
            ...prevStates,
            [name]: !prevStates[name]
        }));
    };

    const buttonStyle = {
        color: '#ffff',
        padding: '5px 10px',
        border: 'none',
        cursor: 'pointer'
    };

    const onButtonStyle = (isOn) => ({
        ...buttonStyle,
        backgroundColor: isOn ? 'green' : 'red',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
    });

    const offButtonStyle = (isOn) => ({
        ...buttonStyle,
        backgroundColor: isOn ? 'red' : 'green',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
    });

    const handleRadioChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleDateChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };



    const DottedDivider = styled(Grid)(({ theme }) => ({
        borderBottom: '1px dashed #cccccc',
    }));

    const styles = {
        heading: {
            marginBottom: '1px',
            fontSize: '14px'
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1px'
        },
        details: {
            flex: 1,
            marginRight: '1px'
        }
    };

    return (
        <Container sx={{ width: "1000px" }}>
            <div>
                <Accordion style={{ boxShadow: 'none' }} sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={{ fontWeight: "600", fontSize: "14px" }}>
                        Import Shipment
                    </AccordionSummary>
                    <AccordionDetails style={{ marginTop: "-10px" }} >
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                Purchase Order
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                    <Box>Prefix</Box>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                    <Box>Suffix</Box>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div>
                                    <div style={styles.container}>
                                        <Box>
                                            <CustomTextField placeholder="Enter Prefix" name="reference" required={true} />
                                        </Box>
                                        <div>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={onButtonStyle(toggleStates.common_sales_executive)}>
                                                Yes
                                            </button>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={offButtonStyle(toggleStates.common_sales_executive)}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={styles.container}>
                                        <Box>
                                            <CustomTextField placeholder="Enter Suffix" name="reference" required={true} />
                                        </Box>
                                        <div>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={onButtonStyle(toggleStates.common_sales_executive)}>
                                                Yes
                                            </button>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={offButtonStyle(toggleStates.common_sales_executive)}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                Proform Invoice
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                    <Box>Prefix</Box>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                    <Box>Suffix</Box>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div>
                                    <div style={styles.container}>
                                        <Box>
                                            <CustomTextField placeholder="Enter Prefix" name="reference" required={true} />
                                        </Box>
                                        <div>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={onButtonStyle(toggleStates.common_sales_executive)}>
                                                Yes
                                            </button>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={offButtonStyle(toggleStates.common_sales_executive)}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={styles.container}>
                                        <Box>
                                            <CustomTextField placeholder="Enter Suffix" name="reference" required={true} />
                                        </Box>
                                        <div>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={onButtonStyle(toggleStates.common_sales_executive)}>
                                                Yes
                                            </button>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={offButtonStyle(toggleStates.common_sales_executive)}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ boxShadow: 'none', marginTop: "5px" }} sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header" sx={{ fontWeight: "600", fontSize: "14px" }}>
                        Export Shipment
                    </AccordionSummary>
                    <AccordionDetails style={{ marginTop: "-10px" }} >
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                Sales Order
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                    <Box>Prefix</Box>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                    <Box>Suffix</Box>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div>
                                    <div style={styles.container}>
                                        <Box>
                                            <CustomTextField placeholder="Enter Prefix" name="reference" required={true} />
                                        </Box>
                                        <div>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={onButtonStyle(toggleStates.common_sales_executive)}>
                                                Yes
                                            </button>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={offButtonStyle(toggleStates.common_sales_executive)}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={styles.container}>
                                        <Box>
                                            <CustomTextField placeholder="Enter Suffix" name="reference" required={true} />
                                        </Box>
                                        <div>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={onButtonStyle(toggleStates.common_sales_executive)}>
                                                Yes
                                            </button>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={offButtonStyle(toggleStates.common_sales_executive)}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                Commercial Invoice
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                    <Box>Prefix</Box>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                    <Box>Suffix</Box>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div>
                                    <div style={styles.container}>
                                        <Box>
                                            <CustomTextField placeholder="Enter Prefix" name="reference" required={true} />
                                        </Box>
                                        <div>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={onButtonStyle(toggleStates.common_sales_executive)}>
                                                Yes
                                            </button>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={offButtonStyle(toggleStates.common_sales_executive)}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={styles.container}>
                                        <Box>
                                            <CustomTextField placeholder="Enter Suffix" name="reference" required={true} />
                                        </Box>
                                        <div>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={onButtonStyle(toggleStates.common_sales_executive)}>
                                                Yes
                                            </button>
                                            <button type="button" onClick={() => handleToggleClick('common_sales_executive')} style={offButtonStyle(toggleStates.common_sales_executive)}>
                                                No
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <div>
                            <h3 style={styles.heading}>Financial Year</h3>
                            <div style={styles.container}>
                                <div style={styles.details}>
                                    Start Current Period
                                </div>
                                <div style={{ display: "flex", }}>
                                    <CustomTextField
                                        type='date'
                                        name="lastName"
                                        required={true}
                                    />
                                    &nbsp;
                                    <CustomTextField
                                        type='date'
                                        name="lastName"
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div>
                            <h3 style={styles.heading}>Date Formate</h3>
                            <div style={styles.container}>
                                <div style={styles.details}>
                                    Select Date Formate
                                </div>
                                <div style={{ display: "flex", }}>
                                    <CustomAutocomplete
                                        options={dateFormats}
                                        required
                                        getOptionLabel={(option) => option.title}
                                        placeholder="Select Date"
                                        style={{ width: "200px" }}
                                    />

                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <DottedDivider />
                    </Grid>

                </Grid>
                <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomButton
                        startIcon={<Save />}
                        title="Save"
                        size="small"
                        variant="contained"
                    />
                </CardActions>
            </div>
        </Container>
    );
};

export default SystemCompany;
const dateFormats = [
    { id: 1, title: 'DD/MM/YYYY' },
    { id: 2, title: 'MM/DD/YYYY' },
    { id: 3, title: 'YYYY/MM/DD' },
];
