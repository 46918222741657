import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './layouts/Headers';
import Layout from './layouts/Index';
import Home from './Pages/Home';
import { authProtectedRoutes, publicRoutes, openRoutes } from "./routes";
// import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from './routes/route'
import './App.css'
import ScrollToTop from './layouts/Scrolltotop';
const App = () => {
  return (
    <React.Fragment>
      <ScrollToTop />
      <Routes>

        {openRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              route.component
            }
            key={idx}
            exact={true}
          />
        ))}

        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              // route.component
              <Home>{route.component}</Home>
            }
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
}

export default App;
