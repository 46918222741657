import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      leads: "Leads",
      contacts: "Contacts",
      company: "Company",
      importShipment: "Import Shipment",
      exportShipment: "Export Shipment",
      reports: "Reports",
      searchPlaceholder: "Search...",
      addNew: "Add New",
      Date: "Date",
      Client: "Client",
      Mobile: "Mobile",
      LeadSource: "Leads",
      LeadOwner: "Lead Owner",
      Status: "Status",
      Stage: "Stage",
      Priority: "Priority",
      LossReason: "Loss Reason",
      CreatedBy: "Created By",
      Action: "Action",
    },
  },
  hi: {
    translation: {
      leads: "नेतृत्व",
      contacts: "संपर्क",
      company: "कंपनी",
      importShipment: "आयात शिपमेंट",
      exportShipment: "निर्यात शिपमेंट",
      reports: "रिपोर्ट",
      searchPlaceholder: "खोजें...",
      addNew: "नया जोड़ें",
      Date: "तारीख",
      Client: "ग्राहक",
      Mobile: "मोबाइल",
      LeadSource: "लीड स्रोत",
      LeadOwner: "लीड मालिक",
      Status: "स्थिति",
      Stage: "मंच",
      Priority: "प्राथमिकता",
      LossReason: "हानि का कारण",
      CreatedBy: "द्वारा बनाया गया",
      Action: "कार्य",
    },
  },
  zh: {
    translation: {
      leads: "线索",
      contacts: "联系人",
      company: "公司",
      importShipment: "进口货物",
      exportShipment: "出口货物",
      reports: "报告",
      searchPlaceholder: "搜索...",
      addNew: "新增",
      LeadSource: "线索",
      Date: "日期",
      Client: "客户",
      Mobile: "手机号",
      LeadOwner: "潜在客户负责人",
      Status: "状态",
      Stage: "阶段",
      Priority: "优先级",
      LossReason: "失败原因",
      CreatedBy: "创建者",
      Action: "操作",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
