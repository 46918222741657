import { Box, Container, Toolbar } from '@mui/material';
import * as React from 'react';
import Containercarousel from './Containercarousel';
import GetStartedForFree from './GetStartedForFree';
import HomeKeyFeature from './HomeKeyFeature';
import HomepageBenefits from './HomepageBenefits';
import HomepagePlatform from './HomepagePlatform';
import FAQ from './FAQ';
import PricingCard from './PricingCard';
import SubscriptionForm from './Subscription';

const Pricing = () => (
    <Box component="main" sx={{ p: 0 }}>
        <br />
        <PricingCard />

        <SubscriptionForm />

        <HomepageBenefits />
        <br />
        <HomepagePlatform />
        <br />
        <FAQ />
        <br />
    </Box>
);

export default Pricing;
