import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider, Card, TableCell, TableRow, TableHead, Table, TableBody, TableContainer } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import { READ_PACKING_LIST_EXPORT } from '../../../services/apiRoutes';
import CustomCardView from "../../common/CustomCardView";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

const PackingListView = ({ handleClose, setMessage, fetchData, packingListId, companyName, phoneNumber }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!packingListId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_PACKING_LIST_EXPORT(packingListId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [packingListId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const values = {
        packingListId: data?.packingListId || '',
        userId: data?.userId || '',
        companyId: data?.companyId || '',
        clientCompanyId: data?.clientCompanyId || '',
        addressTo: data?.addressTo || '',
        invNo: data?.invNo || '',
        date: data?.date || '',
        loadingPort: data?.loadingPort || '',
        destinationPort: data?.destinationPort || '',
        mark: data?.mark || '',
        description: data?.description || '',
        quantity: data?.quantity || '',
        pkg: data?.pkg || '',
        nw: data?.nw || '',
        tw: data?.tw || '',
        measurement: data?.measurement || '',
        totalPackedIn: data?.totalPackedIn || '',
        totalGrossWeight: data?.totalGrossWeight || '',
        totalNetWeight: data?.totalNetWeight || '',
        casNo: data?.casNo || '',
        iupac: data?.iupac || '',
        createdAt: data?.createdAt || '',
        updatedAt: data?.updatedAt || '',
    };
    const labels = [
        { key: 'addressTo', label: 'To', sm: 12, md: 6 },
        { key: 'invNo', label: 'Inv. No', sm: 12, md: 3, },
        { key: 'date', label: 'date', sm: 12, md: 3, },
        { key: 'loadingPort', label: 'Loading Port', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'destinationPort', label: 'Destination Port', sm: 12, md: 6, sx: { pt: 2 } },
    ];
    const labelFooter = [
        { key: 'totalPackedIn', label: 'Total Packed In', sm: 12, md: 12, },
        { key: 'totalGrossWeight', label: 'Total Gross Weight', sm: 12, md: 12, sx: { pt: 2 } },
        { key: 'totalNetWeight', label: 'Total Net Weight', sm: 12, md: 12, sx: { pt: 2 } },
        { key: 'casNo', label: 'CAS No', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'iupac', label: 'IUPAC', sm: 12, md: 6, sx: { pt: 2 } },
    ];

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik initialValues={values} enableReinitialize>
                {() => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#212529" }}>
                                    Packing List
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>
                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="print">
                                        <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close" onClick={handleClose} >
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                                    <Typography style={{ color: "#212529", fontWeight: 500, fontSize: "15px", textTransform: "uppercase" }}>
                                        Packing List
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <CustomCardView label={labels} data={values} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TableContainer style={{ paddingTop: "0px" }}>
                                    <Table
                                        style={{
                                            width: "100%",
                                            border: "1px solid #dee2e6",
                                            borderCollapse: "collapse",
                                        }}
                                    >

                                        <TableHead style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                                            <TableRow style={{ border: "1px solid #dee2e6" }}>
                                                <TableCell style={cellLabel}>
                                                    Mark
                                                </TableCell>
                                                <TableCell style={cellLabel}>
                                                    Description
                                                </TableCell>
                                                <TableCell style={cellLabel}>
                                                    Quantity
                                                </TableCell>
                                                <TableCell style={cellLabel}>PKG</TableCell>
                                                <TableCell style={cellLabel}>
                                                    Net Weight
                                                </TableCell>
                                                <TableCell style={cellLabel}>
                                                    Total Weight
                                                </TableCell>
                                                <TableCell style={cellLabel}>
                                                    Measurement
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                                style={{
                                                    border: "1px solid #dee2e6",
                                                    color: "#212529",
                                                    fontWeight: "600",
                                                    fontSize: "14px",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "clip",
                                                }}
                                            >
                                                <TableCell style={tableCell}>{values.mark}</TableCell>
                                                <TableCell style={tableCell}>{values.description}</TableCell>
                                                <TableCell style={tableCell}>{values.quantity}</TableCell>
                                                <TableCell style={tableCell}>{values.pkg}</TableCell>
                                                <TableCell style={tableCell}>{values.nw}</TableCell>
                                                <TableCell style={tableCell}>{values.tw}</TableCell>
                                                <TableCell style={tableCell}>{values.measurement}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableRow style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                                            <TableCell style={tablecellfooter} colSpan={2}  > Total</TableCell>
                                            <TableCell style={tablecellfooter}  > {values.quantity}</TableCell>
                                            <TableCell style={tablecellfooter}  >{values.pkg} </TableCell>
                                            <TableCell style={tablecellfooter} > {values.nw}</TableCell>
                                            <TableCell style={tablecellfooter} > {values.tw}</TableCell>
                                            <TableCell style={tablecellfooter} >{values.measurement} </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <br />
                                    <CustomCardView label={labelFooter} data={values} />
                                </Grid>

                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box >
    );
};

export default PackingListView;


const cellLabel = {
    border: "1px solid #dee2e6",
    color: "#212529",
    fontWeight: "600",
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "clip",
    padding: "5px",
    textAlign: 'center',

}

const tableCell = {
    border: "1px solid #dee2e6",
    padding: "5px",
    textAlign: "left"

}

const tablecellfooter = {
    border: '1px solid #dee2e6',
    color: '#212529',
    fontWeight: 600,
    fontSize: '14px',
    textAlign: 'right',
    padding: '1px',
    paddingLeft: "10px",
    paddingRight: "10px",

}
