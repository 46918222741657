import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import { Link } from 'react-router-dom';

const hoverStyles = {
    textAlign: 'center',
    paddingBottom: '40px',
    '&:hover': {
        marginTop: '-20px',
    },
};

const iconStyles = {
    width: '100px',
    height: '100px',
    margin: 'auto',
    textAlign: 'center',
    '@media (max-width:600px)': {
        width: '60px',
        height: '50px',
    },
    '@media (min-width:600px)': {
        width: '130px',
        height: '120px',
    },
    '@media (min-width:600px) and (max-width:1024px)': {
        width: '100px',
        height: '95px',
    },
};

const textStyles = {
    color: '#707070',
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: 'Lato, Bold',
    '@media (max-width:600px)': {
        fontSize: '10px',
    },
    '@media (min-width:600px)': {
        fontSize: '35px',
    },
    '@media (min-width:600px) and (max-width:1024px)': {
        fontSize: '25px',
    },
};

const footerTitleStyle = {
    fontWeight: "600",
    fontSize: "15px",
};

const linkStyle = {
    fontWeight: "300",
    fontSize: "15px",
    textDecoration: "none",
    color: "#FFFF",
};
const sections = [
    {
        title: 'Product',
        links: [
            { name: 'Export Documentation', url: '#' },
            { name: 'Invoices', url: '#' },
            { name: 'Quotes', url: '#' },
            { name: 'Purchase Orders', url: '#' },
            { name: 'Pricing', url: '#' },
            { name: 'Template Library', url: '#' },
            { name: 'Xero Integration', url: '#' },
        ],
    },
    {
        title: 'Resources',
        links: [
            { name: 'Blog', url: '#' },
            { name: 'Affiliate Partner Program', url: '#', external: true },
            { name: 'Downloadable Guides', url: '#' },
            { name: 'Help Centre', url: '/helpcenter', external: true },
            { name: 'API Documentation', url: '/', external: true },
        ],
    },
    {
        title: 'Company',
        links: [
            { name: 'Legal', url: '#', external: true },
            { name: 'Privacy', url: '#', external: true },
            { name: 'Security', url: '#', external: true },
        ],
    },
    {
        title: 'Get In Touch',
        links: [
            { name: 'Contact Us', url: '#' },
            { name: 'Chat with a Trade Specialist', url: '#' },
            { name: 'Request a Feature', url: '#' },
            { name: 'info@exim.com', url: '#', external: true },
            {
                name: (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Link>
                            <LinkedInIcon sx={{ color: "#ffff", fontSize: "20px" }} />
                        </Link>
                        <Link>
                            <InstagramIcon sx={{ color: "#ffff", fontSize: "20px" }} />
                        </Link>
                        <Link>
                            <FacebookOutlinedIcon sx={{ color: "#ffff", fontSize: "20px" }} />
                        </Link>
                        <Link>
                            <XIcon sx={{ color: "#ffff", fontSize: "20px" }} />
                        </Link>
                    </Box>
                ),
                url: '#',
                external: true,
            },],
    },
];

const HomeFooter = () => {
    return (
        <Card variant='outlined' sx={{ pb: 4, pt: 4, backgroundColor: "#007BFF", color: "#FFFF" }}>
            <Box sx={{ py: 3, mt: 'auto' }}>
                <Container>
                    <Grid container spacing={2} justifyContent="flex-end">
                        {sections.map((section, index) => (
                            <Grid item xs={12} sm={4} md={3} key={index}>
                                <Box sx={{ textAlign: 'left' }}>
                                    <Typography variant="h6" gutterBottom sx={footerTitleStyle}>
                                        {section.title}
                                    </Typography>
                                    {section.links.map((link, idx) => (
                                        <Typography sx={{ pt: 1 }} key={idx}>
                                            {link.external ? (
                                                <a href={link.url} style={linkStyle} target="_blank" rel="noopener noreferrer">
                                                    {link.name}
                                                </a>
                                            ) : (
                                                <Link to={link.url} style={linkStyle}>
                                                    {link.name}
                                                </Link>
                                            )}
                                        </Typography>
                                    ))}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </Card>
    );
};

export default HomeFooter;
