import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import { UPDATE_IMPORT_PAYMENT_VOUCHER, READ_IMPORT_PAYMENT_VOUCHER } from '../../../services/apiRoutes';

const ValidationSchema = Yup.object().shape({
    date: Yup.date().required('Date is required'),
    paymentVoucherNo: Yup.string().required('Payment Voucher No is required'),
    paymentTypeId: Yup.number().required('Payment Type is required'),
    amount: Yup.number().required('Amount is required'),
    currencyId: Yup.number().required('Currency is required'),
    baseCurrencyId: Yup.number().required('Base Currency is required'),
    conversionRate: Yup.number().required('Conversion Rate is required'),
    amountInBaseCurrency: Yup.number().required('Amount in Base Currency is required'),
    bankCharges: Yup.number().required('Bank Charges are required'),
    note: Yup.string(),
});


const UpdatePayment = ({ handleClose, setMessage, fetchData, paymentVoucherId, currencyList, paymentType }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!paymentVoucherId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_IMPORT_PAYMENT_VOUCHER(paymentVoucherId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.paymentVoucher);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [paymentVoucherId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_IMPORT_PAYMENT_VOUCHER(paymentVoucherId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Payment Voucher updated successfully",
                    type: "success",
                });
                fetchData();
                setTimeout(() => {
                    handleClose();
                }, 500);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    date: data?.date || '',
                    paymentVoucherNo: data?.paymentVoucherNo || '',
                    paymentTypeId: data?.paymentTypeId || '',
                    amount: data?.amount || '',
                    currencyId: data?.currencyId || '',
                    baseCurrencyId: data?.baseCurrencyId || '',
                    conversionRate: data?.conversionRate || '',
                    amountInBaseCurrency: data?.amountInBaseCurrency || '',
                    bankCharges: data?.bankCharges || '',
                    note: data?.note || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
                                    Update Payment Voucher
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box style={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="date"
                                    fieldLabel="Date"
                                    type="date"
                                    value={values.date}
                                    onChange={handleChange}
                                    error={touched.date && !!errors.date}
                                    helperText={touched.date && errors.date}
                                    placeholder="Enter Date"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="paymentVoucherNo"
                                    fieldLabel="Payment Voucher No"
                                    value={values.paymentVoucherNo}
                                    onChange={handleChange}
                                    error={touched.paymentVoucherNo && !!errors.paymentVoucherNo}
                                    helperText={touched.paymentVoucherNo && errors.paymentVoucherNo}
                                    placeholder="Enter Payment Voucher No"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={paymentType}
                                    fieldLabel="Payment Type"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={paymentType.find((option) => option.id === values.paymentTypeId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('paymentTypeId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Payment Type"
                                />
                                {touched.paymentTypeId && errors.paymentTypeId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.paymentTypeId}</p> : null}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="amount"
                                    fieldLabel="Amount"
                                    type="number"
                                    value={values.amount}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setFieldValue('amountInBaseCurrency', e.target.value * values.conversionRate || 0);
                                    }}
                                    error={touched.amount && !!errors.amount}
                                    helperText={touched.amount && errors.amount}
                                    placeholder="Enter Amount"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.currencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('currencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Currency"
                                />
                                {touched.currencyId && errors.currencyId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.currencyId}</p> : null}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Base Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.baseCurrencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('baseCurrencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Base Currency"
                                />
                                {touched.baseCurrencyId && errors.baseCurrencyId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.baseCurrencyId}</p> : null}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="conversionRate"
                                    fieldLabel="Conversion Rate"
                                    type="number"
                                    value={values.conversionRate}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setFieldValue('amountInBaseCurrency', values.amount * e.target.value || 0);
                                    }}
                                    error={touched.conversionRate && !!errors.conversionRate}
                                    helperText={touched.conversionRate && errors.conversionRate}
                                    placeholder="Enter Conversion Rate"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="amountInBaseCurrency"
                                    fieldLabel="Amount in Base Currency"
                                    type="number"
                                    value={values.amountInBaseCurrency}
                                    onChange={handleChange}
                                    error={touched.amountInBaseCurrency && !!errors.amountInBaseCurrency}
                                    helperText={touched.amountInBaseCurrency && errors.amountInBaseCurrency}
                                    placeholder="Enter Amount in Base Currency"
                                    readOnly={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="bankCharges"
                                    fieldLabel="Bank Charges"
                                    type="number"
                                    value={values.bankCharges}
                                    onChange={handleChange}
                                    error={touched.bankCharges && !!errors.bankCharges}
                                    helperText={touched.bankCharges && errors.bankCharges}
                                    placeholder="Enter Bank Charges"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomTextarea
                                    name="note"
                                    fieldLabel="Note"
                                    value={values.note}
                                    onChange={handleChange}
                                    error={touched.note && !!errors.note}
                                    helperText={touched.note && errors.note}
                                    placeholder="Enter Note"
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdatePayment;
