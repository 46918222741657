import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Card, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import { UPDATE_COO_EXPORT, READ_COO_EXPORT } from '../../../services/apiRoutes';

const ValidationSchema = Yup.object().shape({
    exporter: Yup.string().required('Exporter Name is required'),
    serialNo: Yup.string().required('Serial Number is required'),
    certificateNo: Yup.string().required('Certificate Number is required'),
    consignee: Yup.string().required('Consignee Name is required'),
    transportAndRoute: Yup.string().required('Transport and Route Description is required'),
    certifyingAuthority: Yup.string().required('Certifying Authority is required'),
    countryOrRegion: Yup.string().required('Country or Region is required'),
    marksAndPackages: Yup.string().required('Marks and Packages Description is required'),
    descriptionOfGoods: Yup.string().required('Description of Goods is required'),
    hsCode: Yup.string().required('HS Code is required'),
    quantity: Yup.number().required('Quantity is required').min(1, 'Quantity must be greater than 0'),
    invoiceDetails: Yup.string().required('Invoice Details are required'),
    declarationByExporter: Yup.string().required('Declaration by Exporter is required'),
    certification: Yup.string().required('Certification Details are required'),
});

const UpdateCOO = ({ handleClose, setMessage, fetchData, cooId, mailingCountry, exporter, consignee }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!cooId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_COO_EXPORT(cooId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [cooId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_COO_EXPORT(cooId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "updated successfully",
                    type: "success",
                });
                fetchData();
                setTimeout(() => {
                    handleClose();
                },);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    exporter: data?.exporter || '',
                    serialNo: data?.serialNo || '',
                    certificateNo: data?.certificateNo || '',
                    consignee: data?.consignee || '',
                    transportAndRoute: data?.transportAndRoute || '',
                    certifyingAuthority: data?.certifyingAuthority || '',
                    countryOrRegion: data?.countryOrRegion || '',
                    marksAndPackages: data?.marksAndPackages || '',
                    descriptionOfGoods: data?.descriptionOfGoods || '',
                    hsCode: data?.hsCode || '',
                    quantity: data?.quantity || '',
                    invoiceDetails: data?.invoiceDetails || '',
                    declarationByExporter: data?.declarationByExporter || '',
                    certification: data?.certification || '',

                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
                                    Update COO
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box style={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} style={{ paddingBottom: "2px", paddingTop: "2px" }}>
                            <Grid item xs={4} md={12}>
                                <Typography style={{
                                    color: "#212529",
                                    fontWeight: 500, fontSize: "20px",
                                    margin: "auto",
                                    padding: "auto",
                                    textAlign: "center",
                                    textTransform: "uppercase"
                                }}>
                                    Original
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} >
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Exporter
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            readOnly={true}
                                            name="exporter"
                                            value={values.exporter}
                                            onChange={handleChange}
                                            placeholder="Enter exporter"
                                            error={touched.exporter && !!errors.exporter}
                                            helperText={touched.exporter && errors.exporter}
                                            sx={{ height: '65px !important', textTransform: 'capitalize' }}
                                        />
                                    </Typography>

                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Consignee
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            readOnly={true}
                                            name="consignee"
                                            value={values.consignee}
                                            onChange={handleChange}
                                            placeholder="Enter consignee"
                                            error={touched.consignee && !!errors.consignee}
                                            helperText={touched.consignee && errors.consignee}
                                            sx={{ height: '65px !important', textTransform: 'capitalize' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant="outlined" style={{ backgroundColor: "#e7f0f7" }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
                                        <Box sx={{ width: '130px' }}>
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", marginRight: '16px' }}>
                                                Serial No.
                                            </Typography>
                                        </Box>
                                        <CustomTextField
                                            name="serialNo"
                                            value={values.serialNo}
                                            required={true}
                                            onChange={handleChange}
                                            placeholder="Enter serialNo No"
                                            error={touched.serialNo && !!errors.serialNo}
                                            helperText={touched.serialNo && errors.serialNo}
                                            sx={{ backgroundColor: "#fff" }}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
                                        <Box sx={{ width: '130px' }}>
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", marginRight: '16px' }}>
                                                Certificate No.
                                            </Typography>
                                        </Box>
                                        <CustomTextField
                                            name="certificateNo"
                                            value={values.certificateNo}
                                            required={true}
                                            onChange={handleChange}
                                            placeholder="Enter certificate No"
                                            error={touched.certificateNo && !!errors.certificateNo}
                                            helperText={touched.certificateNo && errors.certificateNo}
                                            sx={{ backgroundColor: "#fff" }}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '16px' }}>
                                        <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", textAlign: 'center' }}>
                                            Certification OF origin
                                            <br />
                                            of
                                            <br />
                                            the People's Republic Of {mailingCountry}
                                            <br />
                                            <br />
                                            <br />
                                        </Typography>
                                    </Box>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Means Of Transport And Route
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="transportAndRoute"
                                            value={values.transportAndRoute}
                                            required={true}
                                            onChange={handleChange}
                                            placeholder="Enter transport and route"
                                            error={touched.transportAndRoute && !!errors.transportAndRoute}
                                            helperText={touched.transportAndRoute && errors.transportAndRoute}
                                            sx={{ height: '65px !important' }}
                                        />
                                    </Typography>

                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Country / region of Destination
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="countryOrRegion"
                                            value={values.countryOrRegion}
                                            required={true}
                                            onChange={handleChange}
                                            error={touched.countryOrRegion && !!errors.countryOrRegion}
                                            helperText={touched.countryOrRegion && errors.countryOrRegion}
                                            placeholder="Enter Country / region of Destination"
                                            sx={{ height: '65px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                For Certifiying authority use Only
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="certifyingAuthority"
                                            value={values.certifyingAuthority}
                                            required={true}
                                            onChange={handleChange}
                                            error={touched.certifyingAuthority && !!errors.certifyingAuthority}
                                            helperText={touched.certifyingAuthority && errors.certifyingAuthority}
                                            placeholder="Enter For Certifiying authority use Only"
                                            sx={{ height: '180px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={3} md={2} lg={2}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '50px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Marks and No.
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="marksAndPackages"
                                            value={values.marksAndPackages}
                                            required={true}
                                            onChange={handleChange} marksAndPackages
                                            error={touched.marksAndPackages && !!errors.marksAndPackages}
                                            helperText={touched.marksAndPackages && errors.marksAndPackages}
                                            placeholder="Enter Marks and No."
                                            sx={{ height: '250px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '50px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                No. and kind of packages; Description of Goods
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="descriptionOfGoods"
                                            value={values.descriptionOfGoods}
                                            required={true}
                                            onChange={handleChange}
                                            error={touched.descriptionOfGoods && !!errors.descriptionOfGoods}
                                            helperText={touched.descriptionOfGoods && errors.descriptionOfGoods}
                                            placeholder="Enter No. and kind of packages; Description of Goods"
                                            sx={{ height: '250px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} lg={2}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '50px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                H.S Code
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="hsCode"
                                            value={values.hsCode}
                                            required={true}
                                            onChange={handleChange}
                                            error={touched.hsCode && !!errors.hsCode}
                                            helperText={touched.hsCode && errors.hsCode}
                                            placeholder="Enter H.S Code"
                                            sx={{ height: '250px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} lg={2}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '50px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Quantity
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="quantity"
                                            value={values.quantity}
                                            required={true}
                                            onChange={handleChange}
                                            error={touched.quantity && !!errors.quantity}
                                            helperText={touched.quantity && errors.quantity}
                                            placeholder="Enter Quantity"
                                            sx={{ height: '250px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '50px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                No. and date of invoices
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="invoiceDetails"
                                            value={values.invoiceDetails}
                                            required={true}
                                            onChange={handleChange}
                                            error={touched.invoiceDetails && !!errors.invoiceDetails}
                                            helperText={touched.invoiceDetails && errors.invoiceDetails}
                                            placeholder="Enter No. and date of invoices"
                                            sx={{ height: '250px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '110px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Declaration by Exporter
                                            </Typography>
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "13px" }}>
                                                The undersigned hereby declares that the above details and statements are correct, that the goods were produced in (China) and that they comply with the rules of origin of the People's Republic of (China).
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="declarationByExporter"
                                            value={values.declarationByExporter}
                                            required={true}
                                            onChange={handleChange}
                                            error={touched.declarationByExporter && !!errors.declarationByExporter}
                                            helperText={touched.declarationByExporter && errors.declarationByExporter}
                                            placeholder="Enter Declaration by Exporter"
                                            sx={{ height: '80px !important' }}
                                        />
                                    </Typography>
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '50px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Place and Date, Signature and stamp of Authorized Signatory
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '110px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Certification
                                            </Typography>
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "13px" }}>
                                                It is hereby certified that the declaration by the exporter is correct.
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="certification"
                                            value={values.certification}
                                            required={true}
                                            onChange={handleChange}
                                            error={touched.certification && !!errors.certification}
                                            helperText={touched.certification && errors.certification}
                                            placeholder="Enter Certification"
                                            sx={{ height: '80px !important' }}
                                        />
                                    </Typography>
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '50px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Place and Date, Signature and stamp of Certifying Authority
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateCOO;


const paymentTypeOptions = [
    { title: 'Cash', id: 1 },
    { title: 'Bank Transfer', id: 2 }
];

const currencyOptions = [
    { title: 'USD', id: 1 },
    { title: 'EUR', id: 2 }
];

const custumerOptions = [
    { title: 'custumerOptions', id: 1 },
    { title: 'custumerOptions', id: 2 }
];