import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { AppBarMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from "../../common/CustomModal";
import CreateProformaInvoice from "../CreateForm/CreateProformaInvoice";
import { useShipmentDetails } from './useShipmentDetails';
import { genericGet } from '../../../services/apiServices';
import { GET_PRODUCTS_LIST, GET_SUPPLIERS_LIST, LIST_PROFORMA_INVOICES } from '../../../services/apiRoutes';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MessageBox from '../../common/CustomMessageBox';
import UpdateProformaInvoice from '../UpdateForm/UpdateProformaInvoice';
import axios from 'axios';
import { genericPost } from '../../../services/authServices';
import ProformaInvoiceView from '../ViewDetails/ProformaInvoiceView';

export default function ProformaInvoiceImport() {
    const { importShipmentId } = useParams();
    const [open, setOpen] = useState(true);
    const [leadModal, setLeadModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Proforma Invoice');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [suppliersList, setSuppliersList] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { shipmentDetails } = useShipmentDetails(importShipmentId);

    const [updateModal, setUpdateModal] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [proformainvoiceId, setProformainvoiceId] = useState('');

    let supplierId, shipmentId, cifFob, currency;

    const hasFetched = useRef(false);

    useEffect(() => {
        if (shipmentDetails) {
            supplierId = shipmentDetails.supplierId;
            shipmentId = shipmentDetails.shipmentId;
            cifFob = shipmentDetails.cifFob.cifFobName;
            currency = shipmentDetails.currency.currency;
            fetchData();
            fetchProductsList();
            getSuppliersList();
            hasFetched.current = true;

        }
    }, [shipmentDetails, proformainvoiceId, leadModal]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                supplierNamesId: supplierId
            };
            const response = await genericPost(LIST_PROFORMA_INVOICES, payload);

            if (response.data.success) {
                const proformaInvoices = response.data.proformaInvoice.map((invoice) => {
                    const totalQty = invoice.particulars.reduce((acc, item) => acc + item.qty, 0);
                    const totalAmount = invoice.particulars.reduce((acc, item) => acc + (item.qty * parseFloat(item.rate)), 0);
                    const avgRate = totalQty > 0 ? (totalAmount / totalQty).toFixed(2) : 0;

                    return {
                        proformainvoiceId: invoice.proformaInvoiceId || '',
                        ciNo: invoice.proformaInvoiceNo || '',
                        date: invoice.date || '',
                        dueDate: invoice.dueDate || '',
                        reference: invoice.reference || '',
                        note: invoice.note || '',
                        upload: invoice.piUpload || '',
                        currencyName: currency || '',
                        totalQty,
                        avgRate,
                        totalAmount: totalAmount.toFixed(2),
                        particulars: invoice.particulars.map(({
                            particular = '',
                            qty = '',
                            rate = '',
                            unit = ''
                        }) => ({ particular, qty, rate, unit }))
                    };
                });

                setData(proformaInvoices);
            } else {
                setError('No proforma invoices found');
            }
        } catch (error) {
            setError('Error fetching proforma invoices');
            console.error('Error fetching proforma invoices', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchProductsList = async () => {
        const authToken = sessionStorage.getItem('accessToken');
        const payload = {
            clientCompanyId: supplierId,
        };

        try {
            const response = await axios.post(GET_PRODUCTS_LIST, payload, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200 && response.data.success) {
                const productData = response.data.productsList;
                console.log(productData);
                setProducts(productData);
            } else {
                console.error('Failed to fetch products list:', response.data);
            }
        } catch (error) {
            console.error('Error fetching products list:', error);
        }
    };

    const getSuppliersList = async () => {
        try {
            const payload = {
                clientCompanyId: supplierId,

            };

            const response = await genericPost(GET_SUPPLIERS_LIST, payload, {
            });

            if (response.data && response.data.success && response.data.clientCompanies) {
                const suppliersData = response.data.clientCompanies.map((supplier) => ({
                    id: supplier.clientCompanyId,
                    title: supplier.clientCompanyName,
                }));
                setSuppliersList(suppliersData);

            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching suppliers list:', error);
            return [];
        }
    };



    if (!shipmentDetails) {
        return <p>Loading...</p>;
    }


    const handleViewClick = (id) => {
        handleOpenView(id);
    };

    const handleUpdateClick = (id) => {
        setProformainvoiceId(id);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setProformainvoiceId('');
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setLeadModal(true);
    };

    const handleClose = () => {
        setLeadModal(false);
    };


    const handleOpenView = (id) => {
        setProformainvoiceId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setProformainvoiceId('');
    };


    const companyName = shipmentDetails.supplier.clientCompanyName;
    const phoneNumber = shipmentDetails.supplier.mobile;
    const supplierIdName = shipmentDetails.supplierId;
    const initial = shipmentDetails.supplier.clientCompanyName ? shipmentDetails.supplier.clientCompanyName.charAt(0).toUpperCase() : '';


    const handleBackClick = () => {
        navigate("/app/import/shipement");
    };

    const proformaInvoiceColumns = [
        { id: 'proformainvoiceId', label: 'SN', accessor: 'proformainvoiceId' },
        { id: 'ciNo', label: 'PI No.', accessor: 'ciNo' },
        { id: 'date', label: 'Date', accessor: 'date' },
        { id: 'productName', label: 'Product Name', accessor: 'productName' },
        {
            id: 'totalQty', label: 'Qty', accessor: 'totalQty', styleAccessor: {
                textAlign: 'right',
            },
        },
        { id: 'unit', label: 'Unit', accessor: 'unit' },
        {
            id: 'avgRate', label: 'Rate', accessor: 'avgRate', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'totalAmount', label: 'Amount', accessor: 'totalAmount', styleAccessor: {
                textAlign: 'right',
            },
        },
        { id: 'currencyName', label: 'Currency', accessor: 'currencyName' },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleViewClick(row.proformainvoiceId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.proformainvoiceId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.proformainvoiceId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];

    return (
        <div>
            <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={AppBarMenu(importShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />
            <Main open={open}>
                <DetailsPageCard
                    columns={proformaInvoiceColumns}
                    title={'Proforma Invoice'}
                    buttonTitle={'Create Proforma Invoice'}
                    handleOpen={handleOpen}
                    data={data}
                    loading={loading}
                    error={error}
                />
                <CustomModal open={leadModal} handleClose={handleClose}>
                    <CreateProformaInvoice
                        fetchData={fetchData}
                        setMessage={setMessage}
                        handleClose={handleClose}
                        suppliersList={suppliersList}
                        productsList={products}
                        supplierIdName={supplierIdName}
                    />
                </CustomModal>

                <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateProformaInvoice
                        fetchData={fetchData}
                        setMessage={setMessage}
                        handleClose={handleCloseUpdate}
                        proformaInvoiceId={proformainvoiceId}
                        suppliersList={suppliersList}
                        productsList={products}
                    />
                </CustomModal>
                <CustomModal open={viewModal} handleClose={handleCloseView}>
                    <ProformaInvoiceView
                        fetchData={fetchData}
                        handleClose={handleCloseView}
                        proformaInvoiceId={proformainvoiceId}
                        suppliersList={suppliersList}
                        productsList={products}
                    />
                </CustomModal>
                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />
            </Main>
        </div>
    );
}
