
import CreateShippingBooking from "../CreateForm/CreateShippingBooking";
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CustomModal from "../../../components/common/CustomModal";
import { LIST_IMPORT_SHIPPING_BOOKINGS } from '../../../services/apiRoutes';
import { ExportMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import { genericPost } from '../../../services/authServices';
import MessageBox from '../../common/CustomMessageBox';
import { getCIFFOBList, getCurrencyList, getCustomClearingAgencyData, getDirectListData, getFCLData, getFreightForwardAgencyData, getShippingLines, getCommanExportShipmentId } from '../../../services/CommonServices';
import UpdateShippingBooking from "../UpdateForm/UpdateShippingBooking";
import ShippingBookingView from "../ViewDetails/ShippingBookingView";

export default function Booking() {
    const [open, setOpen] = useState(true);
    const [leadModal, setLeadModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [shippingBookingId, setShippingBookingId] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('Booking');
    const [shippingLines, setShippingLines] = useState([]);
    const [fclData, setFCLData] = useState([]);
    const [directListData, setDirectListData] = useState([]);
    const [freightForwardAgencyData, setFreightForwardAgencyData] = useState([]);
    const [customClearingAgencyData, setCustomClearingAgencyData] = useState([]);
    const [cifFobList, setCIFFOBList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);


    const navigate = useNavigate();
    const { exportShipmentId } = useParams();
    const { shipmentDetails } = useExportShipmentDetails(exportShipmentId);

    let customerId, shipmentId, cifFob;
    const hasFetched = useRef(false);

    useEffect(() => {
        if (shipmentDetails) {
            customerId = shipmentDetails.customerId;
            shipmentId = shipmentDetails.shipmentId;
            cifFob = shipmentDetails.cifFob.cifFobName;
            fetchData();
            getCommonData();
            hasFetched.current = true;
        }
    }, [shipmentDetails, updateModal, shippingBookingId, leadModal]);



    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                clientCompanyId: customerId,
                commanShipmentId: getCommanExportShipmentId,

            };
            const response = await genericPost(LIST_IMPORT_SHIPPING_BOOKINGS, payload, {
            });

            if (response.data.success && response.data.shippingBooking) {
                const shippingBookingData = response.data.shippingBooking.map((booking) => ({
                    shippingBookingId: booking.shippingBookingId || '',
                    shipmentId: shipmentId || '',
                    portOfLoading: booking.portOfLoading || '',
                    portOfDestination: booking.portOfDestination || '',
                    etaDate: booking.etaDate || '',
                    etdDate: booking.etdDate || '',
                    destinationCharges: booking.destinationCharges || '',
                    shippingLineCharges: booking.shippingLineCharges || '',
                    blCharges: booking.blCharges || '',
                    numberOfContainers: booking.numberOfContainers || '',
                    cifFobName: booking.cifFob?.cifFobName || '',
                    shippingLineName: booking.shippingLines?.shippingLineName || '',
                    agencyName: booking.freightForwardAgency?.agencyName || '',
                    chaName: booking.customClearingAgency?.chaName || '',

                }));
                setData(shippingBookingData);
            } else {
                setError('No shipping bookings found');
            }
        } catch (error) {
            setError('Error fetching shipping bookings');
            console.error('Error fetching shipping bookings', error);
        } finally {
            setLoading(false);
        }
    };


    if (!shipmentDetails) {
        return <p>Loading...</p>;
    }


    const getCommonData = async () => {
        const shippingLines = await getShippingLines();
        setShippingLines(shippingLines);
        const fclData = await getFCLData();
        setFCLData(fclData);
        const directListData = await getDirectListData();
        setDirectListData(directListData);
        const freightForwardAgencyData = await getFreightForwardAgencyData();
        setFreightForwardAgencyData(freightForwardAgencyData);
        const customClearingAgencyData = await getCustomClearingAgencyData();
        setCustomClearingAgencyData(customClearingAgencyData);
        const cifFobListData = await getCIFFOBList();
        setCIFFOBList(cifFobListData);
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
    }
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setLeadModal(true);
    };

    const handleClose = () => {
        setLeadModal(false);
    };


    const handleUpdateClick = (id) => {
        setShippingBookingId(id);
        setUpdateModal(true);
    };


    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setShippingBookingId('');
    };

    const handleViewClick = (id) => {
        setShippingBookingId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setShippingBookingId('');
    };


    const handleBackClick = () => {
        navigate("/app/export/shipement");
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const ShippingBookingCardColumns = [
        { accessor: 'shippingBookingId', label: 'SN', id: 'shippingMarkId' },
        { accessor: 'shipmentId', label: 'Shipment ID', id: 'shipmentId' },
        { accessor: 'portOfLoading', label: 'Port of Loading', id: 'lp' },
        { accessor: 'portOfDestination', label: 'Port of Destination', id: 'dp' },
        { accessor: 'etdDate', label: 'ETD Date', id: 'etdDate' },
        { accessor: 'etaDate', label: 'ETA Date', id: 'etaDate' },
        { accessor: 'cifFobName', label: 'CIF/FOB', id: 'cifFobName' },
        {
            accessor: 'destinationCharges', label: 'Destination Charges', id: 'destinationCharges', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            accessor: 'shippingLineCharges', label: 'Shipping Line Charges', id: 'shippingLineCharges', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            accessor: 'blCharges', label: 'BL Charges', id: 'blCharges', styleAccessor: {
                textAlign: 'right',
            },
        },
        { accessor: 'numberOfContainers', label: 'No. of Containers', id: 'numberOfContainers' },
        { accessor: 'shippingLineName', label: 'Shipping Line', id: 'shippingLineName' },
        { accessor: 'agencyName', label: 'Freight Forward Agency', id: 'agencyName' },
        { accessor: 'chaName', label: 'Clearing Agency (CHA)', id: 'chaName' },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleViewClick(row.shippingBookingId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.shippingBookingId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.shippingBookingId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];

    const companyName = shipmentDetails.customer.clientCompanyName;
    const phoneNumber = shipmentDetails.customer.mobile;
    const customerName = shipmentDetails.customerId;
    const initial = shipmentDetails.customer.clientCompanyName ? shipmentDetails.customer.clientCompanyName.charAt(0).toUpperCase() : '';

    return (
        <div>
            <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={ExportMenu(exportShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />
            <Main open={open}>
                <DetailsPageCard
                    columns={ShippingBookingCardColumns}
                    title={'Shipping Booking'}
                    buttonTitle={'Create Booking'}
                    handleOpen={handleOpen}
                    data={data}
                    loading={loading}
                    error={error}
                />
                <CustomModal open={leadModal} handleClose={handleClose}>
                    <CreateShippingBooking
                        fetchData={fetchData}
                        clientCompanyId={customerName}
                        shippingLines={shippingLines}
                        commanShipmentId={getCommanExportShipmentId}
                        fclData={fclData}
                        directListData={directListData}
                        freightForwardAgencyData={freightForwardAgencyData}
                        customClearingAgencyData={customClearingAgencyData}
                        cifFobList={cifFobList}
                        currencyList={currencyList}
                        setMessage={setMessage}
                        handleClose={handleClose}
                    />
                </CustomModal>

                <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateShippingBooking
                        fetchData={fetchData}
                        setMessage={setMessage}
                        handleClose={handleCloseUpdate}
                        shippingBookingId={shippingBookingId}
                        clientCompanyId={customerName}
                        shippingLines={shippingLines}
                        fclData={fclData}
                        directListData={directListData}
                        freightForwardAgencyData={freightForwardAgencyData}
                        customClearingAgencyData={customClearingAgencyData}
                        cifFobList={cifFobList}
                        currencyList={currencyList}
                    />
                </CustomModal>

                <CustomModal
                    modalStyles={{
                        maxHeight: '50vh',
                        top: "10%",
                    }}
                    open={viewModal} handleClose={handleCloseView}>
                    <ShippingBookingView
                        fetchData={fetchData}
                        setMessage={setMessage}
                        handleClose={handleCloseView}
                        shippingBookingId={shippingBookingId}
                        clientCompanyId={customerName}
                        shippingLines={shippingLines}
                        fclData={fclData}
                        directListData={directListData}
                        freightForwardAgencyData={freightForwardAgencyData}
                        customClearingAgencyData={customClearingAgencyData}
                        cifFobList={cifFobList}
                        currencyList={currencyList}
                    />
                </CustomModal>
                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />
            </Main>
        </div>
    );
}
