import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Typography, Button, IconButton, Avatar, Link, Table, TableBody, TableRow, TableCell } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MailIcon from '@mui/icons-material/Mail';
import CustomButton from '../common/CustomButton';
import { Edit, LinkOffOutlined, Phone } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LinkIcon from '@mui/icons-material/Link';
import CustomTextField from '../common/CustomTextField';
import { genericGet } from "../../services/apiServices";
import { GET_COMPANY_id } from "../../services/apiRoutes";
import { useParams } from 'react-router-dom';
import CustomModal from "../common/CustomModal";
import UpdateCompany from "../../components/MyCompany/UpdateCompany";
import { getCountries, getStates } from "../../services/CommonServices";


const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
};

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const userDetails = {
    name: "Deepali Pawar",
    role: "Sales Executive",
    email: "cnd1.kg@khetigaadi.in",
    phone: "9158075833",
    company: "SAR Agro Industries Private Limited.",
    profileImage: "/images/user-thumbnail.png"
};

const userInfo = [
    { label: 'First Name', value: 'Deepali' },
    { label: 'Last Name', value: 'Pawar' },
    { label: 'Email', value: 'cnd1.kg@khetigaadi.in' },
    { label: 'Role', value: 'Sales Executive' },
    { label: 'Phone', value: '9158075833' }
];

const CompanySetting = () => {
    const { id } = useParams(); // Get the id parameter from the URL

    const [value, setValue] = React.useState(0);
    const [getAllCompany, setGetAllCompany] = useState([]);

    const [addCompanyModal, setAddCompanyModal] = useState(false);
    const [conutries, setConutries] = useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getCompanyList = async () => {
        try {
            const response = await genericGet({ url: GET_COMPANY_id(id) });

            let CompanyData = response.data.company;
            if (CompanyData) {
                setGetAllCompany(CompanyData);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getCompanyList();
    }, [id]);




    const handleClose = () => {
        setAddCompanyModal(false);
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label="User Details"
                            {...a11yProps(0)}
                            sx={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                                color: value === 0 ? '#007BFF' : 'inherit'
                            }}
                        />
                        <Tab
                            label="Company Details"
                            {...a11yProps(1)}
                            sx={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                                color: value === 1 ? '#007BFF' : 'inherit'
                            }}
                        />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "15px" }}>User Details</Typography>
                                <IconButton size="small" color="primary" aria-label="edit" data-toggle="modal" data-target="#editModal">
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} container spacing={2}>
                                <Grid item>
                                    <Box textAlign="center">
                                        <Avatar src={userDetails.profileImage} alt="user-profile" sx={{ width: 100, height: 100, mx: 'auto' }} />
                                    </Box>
                                </Grid>
                                <Grid item xs>
                                    <Box display="flex" alignItems="center" mb={1}>
                                        <Typography variant="h6" fontWeight="bold" sx={{ m: 0, fontSize: "18px", fontWeight: "bold" }}>{userDetails.name}</Typography>
                                        <CustomButton
                                            title={userDetails.role}
                                            size="small"
                                            variant="outlined"
                                            style={{
                                                color: "#007BFF", textTransform: "capitalize", borderColor: '#007BFF',
                                                marginLeft: "10px"
                                            }}
                                        />
                                    </Box>
                                    <Box mb={1}>
                                        <Link variant="body2" color="textSecondary" sx={{ fontSize: "15px" }}>
                                            {userDetails.role} at {userDetails.company}
                                        </Link>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <MailIcon fontSize="small" sx={{ mr: 1 }} />
                                        {userDetails.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box my={2}>
                            <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "bold" }}>User Information</Typography>
                            <Grid container sx={{ pl: 10 }}>
                                <Grid item xs={12}>
                                    <Table size="small" sx={{ '& .MuiTableCell-root': { borderBottom: 'none' } }}>
                                        <TableBody>
                                            {userInfo.map((info, index) => (
                                                <TableRow key={index}>
                                                    <TableCell width="120" sx={{ color: 'text.secondary', fontSize: "14px" }}>{info.label}</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}>{info.value}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </CustomTabPanel>

                {getAllCompany && (
                    <CustomTabPanel value={value} index={1}>
                        <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'start' }}>
                                    <Typography
                                        variant="body2"
                                        sx={{ display: 'flex', alignItems: 'center', fontSize: "15px", fontWeight: "bold" }}
                                    >
                                        {getAllCompany.companyName}
                                        <Edit fontSize="small" sx={{ ml: 2, color: "#007BFF" }} onClick={() => setAddCompanyModal(true)} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <CustomTextField
                                        type="text"
                                        name="contactGroups"
                                        required={false}
                                        placeholder="Company Logo"
                                        value="Company Logo"

                                    />
                                </Grid>
                                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <CustomTextField
                                        type="text"
                                        name="contactGroups"
                                        required={false}
                                        placeholder="Company Logo"
                                        value="Company Logo"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <CustomTextField
                                        type="text"
                                        name="contactGroups"
                                        required={false}
                                        placeholder="Company Logo"
                                        value="Company Logo"

                                    />
                                </Grid>
                                <Grid item xs={12} md={12} >
                                    <Typography
                                        variant="body2"
                                        sx={{ display: 'flex', alignItems: 'center', fontSize: "14px" }}
                                    >
                                        <MailIcon fontSize="small" />
                                        &nbsp;&nbsp;
                                        {getAllCompany.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} >
                                    <Typography
                                        variant="body2"
                                        sx={{ display: 'flex', alignItems: 'center', fontSize: "14px" }}
                                    >
                                        <LinkIcon fontSize="small" />
                                        &nbsp;&nbsp;
                                        {getAllCompany.website}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} >
                                    <Typography
                                        variant="body2"
                                        sx={{ display: 'flex', alignItems: 'center', fontSize: "14px" }}
                                    >
                                        <Phone fontSize="small" />
                                        &nbsp;&nbsp;
                                        {getAllCompany.mobile}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} >
                                    <Typography
                                        variant="body2"
                                        sx={{ display: 'flex', alignItems: 'center', fontSize: "14px" }}
                                    >
                                        <LocationOnIcon fontSize="small" />
                                        &nbsp;&nbsp;
                                        {getAllCompany.address}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} md={6} sx={{ display: 'block', justifyContent: 'start' }}>
                                    <Typography
                                        variant="body2"
                                        sx={{ display: 'flex', alignItems: 'center', fontSize: "15px", fontWeight: "bold" }}
                                    >
                                        Local Information
                                        <Edit fontSize="small" sx={{ ml: 2, color: "#007BFF" }} onClick={() => setAddCompanyModal(true)} />
                                    </Typography>

                                    <Typography
                                        variant="body2"
                                        sx={{ display: 'flex', alignItems: 'center', pt: 2, fontSize: "14px" }}
                                    >
                                        <LinkIcon fontSize="small" /> &nbsp;&nbsp;
                                        {getAllCompany.website}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ display: 'flex', alignItems: 'center', pt: 2, fontSize: "14px" }}
                                    >
                                        <LocationOnIcon fontSize="small" /> &nbsp;&nbsp;
                                        {getAllCompany.address}
                                    </Typography>
                                </Grid>


                                <Grid item xs={12} md={6} sx={{ display: 'block', justifyContent: 'start' }}>
                                    <Typography
                                        variant="body2"
                                        sx={{ display: 'flex', alignItems: 'center', fontSize: "15px", fontWeight: "bold" }}
                                    >
                                        Access URL
                                        <Edit fontSize="small" sx={{ ml: 2, color: "#007BFF" }}
                                            onClick={() => setAddCompanyModal(true)}

                                        />
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ display: 'flex', alignItems: 'center', pt: 2, fontSize: "14px" }}
                                    >
                                        <LinkIcon fontSize="small" />
                                        &nbsp;&nbsp;
                                        {getAllCompany.website}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </CustomTabPanel>
                )}
            </Box>

            <CustomModal open={addCompanyModal} handleClose={handleClose}>
                <UpdateCompany
                    handleClose={handleClose}
                    conutries={conutries}
                    getStates={getStates}
                />
            </CustomModal>

        </>


    );
};

export default CompanySetting;


