import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { Card, Container, Divider, Grid, Typography } from '@mui/material';
import CustomButton from '../common/CustomButton';

const tabButtonStyle = (isActive, side) => ({
    backgroundColor: isActive ? '#007BFF' : '#e7f0ff',
    color: isActive ? 'white' : '#007BFF',
    textTransform: "capitalize",
    fontWeight: "500",
    marginLeft: "3px",
    borderRadius: side === 'right' ? '0 7px 0px 0' : '7px 0 0 0px',
    '&:hover': {
        backgroundColor: isActive ? '#007BFF' : '#e7f0ff',
    },
});

const badgeStyle = {
    fontSize: "12px",
    "& .MuiBadge-dot": {
        backgroundColor: "#4CAF50",
    },
    "& .MuiBadge-badge": {
        color: "#007BFF",
        backgroundColor: "#e7f0ff",
    },
};

const inputStyle = {
    backgroundColor: "#e7effe",
    border: "1px solid #00acff",
    borderRadius: "4px",
    color: "#0f4ec2",
    fontWeight: 600,
    height: "20px",
    // margin: "20px",
    width: "70px",
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [mainTabValue, setMainTabValue] = useState(0);
    const [subTabValue, setSubTabValue] = useState(0);
    const [userCount, setUserCount] = useState(0);

    const handleMainTabChange = (event, newValue) => {
        setMainTabValue(newValue);
        setSubTabValue(0);
        setUserCount(0); // Reset userCount when main tab changes

    };

    const handleSubTabChange = (event, newValue) => {
        setSubTabValue(newValue);
    };

    const handleUserCountChange = (event) => {
        setUserCount(Number(event.target.value));
    };

    const discount = 10;
    const basicfees = mainTabValue === 0 ? 250 : 35;
    const basicfeesYearly = basicfees * 12;
    const symbol = mainTabValue === 0 ? "¥" : "$";
    const time = subTabValue === 0 ? "Monthly" : "Yearly";

    const getPrice = () => {
        const pricePerUser = mainTabValue === 0 ? 90 : 15;
        const months = subTabValue === 1 ? 12 : 1;
        return userCount * pricePerUser * months;
    };

    const totalPrice = getPrice();
    const usertotalPrice = subTabValue === 1 ? basicfeesYearly + getPrice() : basicfees + getPrice();

    const discountedTotalPrice = subTabValue === 1 ? usertotalPrice * (1 - discount / 100) : usertotalPrice;
    const discountAmount = usertotalPrice - discountedTotalPrice;



    return (
        <>
            <br />
            <br />
            <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <Box sx={{ borderColor: 'divider', display: 'flex' }}>
                        <Button
                            onClick={(event) => handleMainTabChange(event, 1)}
                            sx={tabButtonStyle(mainTabValue === 1, 'left')}
                            {...a11yProps(1)}
                        >
                            International
                        </Button>
                        <Button
                            onClick={(event) => handleMainTabChange(event, 0)}
                            sx={tabButtonStyle(mainTabValue === 0, 'right')}
                            {...a11yProps(0)}
                        >
                            China
                        </Button>

                    </Box>
                </Box>

                <Container>
                    <Card variant='outlined'>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={8}>
                                <CustomTabPanel value={mainTabValue} index={0}>
                                    <Box sx={{ borderColor: 'divider', display: 'flex' }}>
                                        <Button
                                            onClick={(event) => handleSubTabChange(event, 0)}
                                            sx={tabButtonStyle(subTabValue === 0, 'left')}
                                            {...a11yProps(0)}
                                        >
                                            Monthly
                                        </Button>
                                        {subTabValue === 1 ? (
                                            <Badge color="secondary" badgeContent={`${discount}% OFF`} sx={badgeStyle}>
                                                <Button
                                                    onClick={(event) => handleSubTabChange(event, 1)}
                                                    sx={tabButtonStyle(subTabValue === 1, 'right')}
                                                    {...a11yProps(1)}
                                                >
                                                    Yearly
                                                </Button>
                                            </Badge>
                                        ) : (
                                            <Button
                                                onClick={(event) => handleSubTabChange(event, 1)}
                                                sx={tabButtonStyle(subTabValue === 1, 'right')}
                                                {...a11yProps(1)}
                                            >
                                                Yearly
                                            </Button>
                                        )}
                                    </Box>


                                    <CustomTabPanel value={subTabValue} index={0}>
                                        <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>Price Estimate</Typography>
                                        <br />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Typography>Basic Subcription Fees
                                                <Typography sx={{ fontSize: "12px", textAlign: 'right', flexGrow: 1, color: "#707070", lineHeight: "8px" }}>2 users Included*</Typography>
                                            </Typography>
                                            <Typography >Per Month</Typography>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {basicfees}</Typography>
                                        </Box>
                                        <br />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, flexGrow: 1 }}>
                                                <Typography> Users Subcription</Typography>
                                                <Typography sx={{ fontWeight: 'bold' }}>{symbol} 90/user</Typography>
                                                <Typography>x <input
                                                    style={inputStyle}
                                                    type="number"
                                                    value={userCount}
                                                    onChange={handleUserCountChange}
                                                /> users x 1 month</Typography>
                                            </Box>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {totalPrice.toFixed(2)}</Typography>
                                        </Box>
                                        <br />
                                        <Divider />
                                        <br />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Typography>{time} Total Subscription Amount</Typography>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {discountedTotalPrice.toFixed(2)}</Typography>
                                        </Box>
                                    </CustomTabPanel>

                                    <CustomTabPanel value={subTabValue} index={1}>
                                        <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>Price Estimate</Typography>
                                        <br />

                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, flexGrow: 1 }}>
                                                <Typography>Basic Subcription Fees
                                                    <Typography sx={{ fontSize: "12px", textAlign: 'right', flexGrow: 1, color: "#707070", lineHeight: "8px" }}>2 users Included*</Typography>
                                                </Typography>
                                                <Typography>x 12 month</Typography>
                                            </Box>

                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {basicfeesYearly}</Typography>
                                        </Box>

                                        <br />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, flexGrow: 1 }}>
                                                <Typography>Users Subcription</Typography>
                                                <Typography sx={{ fontWeight: 'bold' }}>{symbol} 90/user</Typography>
                                                <Typography>x <input
                                                    type="number"
                                                    value={userCount}
                                                    onChange={handleUserCountChange}
                                                    style={inputStyle}
                                                /> users x 12 months</Typography>
                                            </Box>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {totalPrice.toFixed(2)}</Typography>
                                        </Box>
                                        <br />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Typography>Discounted Price ({discount}% OFF)</Typography>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {discountAmount.toFixed(2)}</Typography>
                                        </Box>
                                        <br />
                                        <Divider />
                                        <br />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Typography>{time} Total Subscription Amount</Typography>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {discountedTotalPrice.toFixed(2)}</Typography>
                                        </Box>
                                    </CustomTabPanel>
                                </CustomTabPanel>





                                <CustomTabPanel value={mainTabValue} index={1}>
                                    <Box sx={{ borderColor: 'divider', display: 'flex' }}>
                                        <Button
                                            onClick={(event) => handleSubTabChange(event, 0)}
                                            sx={tabButtonStyle(subTabValue === 0, 'left')}
                                            {...a11yProps(0)}
                                        >
                                            Monthly
                                        </Button>
                                        {subTabValue === 1 ? (
                                            <Badge color="secondary" badgeContent={`${discount}% OFF`} sx={badgeStyle}>
                                                <Button
                                                    onClick={(event) => handleSubTabChange(event, 1)}
                                                    sx={tabButtonStyle(subTabValue === 1, 'right')}
                                                    {...a11yProps(1)}
                                                >
                                                    Yearly
                                                </Button>
                                            </Badge>
                                        ) : (
                                            <Button
                                                onClick={(event) => handleSubTabChange(event, 1)}
                                                sx={tabButtonStyle(subTabValue === 1, 'right')}
                                                {...a11yProps(1)}
                                            >
                                                Yearly
                                            </Button>
                                        )}
                                    </Box>


                                    <CustomTabPanel value={subTabValue} index={0}>
                                        <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>Price Estimate</Typography>
                                        <br />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Typography>Basic Subcription Fees
                                                <Typography sx={{ fontSize: "12px", textAlign: 'right', flexGrow: 1, color: "#707070", lineHeight: "8px" }}>2 users Included*</Typography>
                                            </Typography>
                                            <Typography >Per Month</Typography>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {basicfees}</Typography>
                                        </Box>
                                        <br />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, flexGrow: 1 }}>
                                                <Typography>Users Subcription</Typography>
                                                <Typography sx={{ fontWeight: 'bold' }}>{symbol} 15/user</Typography>
                                                <Typography>x <input
                                                    type="number"
                                                    value={userCount}
                                                    onChange={handleUserCountChange}
                                                    style={inputStyle}
                                                /> users x 1 month</Typography>
                                            </Box>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {totalPrice.toFixed(2)}</Typography>
                                        </Box>
                                        <br />
                                        <Divider />
                                        <br />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Typography>{time} Total Subscription Amount</Typography>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {discountedTotalPrice.toFixed(2)}</Typography>
                                        </Box>
                                    </CustomTabPanel>



                                    <CustomTabPanel value={subTabValue} index={1}>
                                        <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>Price Estimate</Typography>
                                        <br />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, flexGrow: 1 }}>
                                                <Typography>Basic Subcription Fees
                                                    <Typography sx={{ fontSize: "12px", textAlign: 'right', flexGrow: 1, color: "#707070", lineHeight: "8px" }}>2 users Included*</Typography>
                                                </Typography>
                                                <Typography>x 12 month</Typography>
                                            </Box>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {basicfeesYearly}</Typography>
                                        </Box>
                                        <br />
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, flexGrow: 1 }}>
                                                <Typography>Users Subcription</Typography>
                                                <Typography sx={{ fontWeight: 'bold' }}>{symbol} 15/user</Typography>
                                                <Typography>x <input
                                                    type="number"
                                                    value={userCount}
                                                    onChange={handleUserCountChange}
                                                    style={inputStyle}
                                                /> users x 12 months</Typography>
                                            </Box>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {totalPrice.toFixed(2)}</Typography>
                                        </Box>
                                        <br />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Typography>Discounted Price ({discount}% OFF)</Typography>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {discountAmount.toFixed(2)}</Typography>
                                        </Box>
                                        <br />
                                        <Divider />
                                        <br />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Typography>{time} Total Subscription Amount</Typography>
                                            <Typography sx={{ fontWeight: 'bold' }}>{symbol} {discountedTotalPrice.toFixed(2)}</Typography>
                                        </Box>
                                    </CustomTabPanel>
                                </CustomTabPanel>
                            </Grid>



                            <Grid item xs={6} md={4}>
                                <Card sx={{
                                    height: "100%",
                                    backgroundColor: "#E7F0FF",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: 2,
                                }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>

                                        <Typography sx={{ fontWeight: 'bold', color: '#007BFF', fontSize: "25px" }}> {symbol} {discountedTotalPrice.toFixed(2)}/{time}</Typography>
                                        <br />
                                        <CustomButton variant="contained" color="primary" title="Buy Now" />
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
            </Box>

            <br />
        </>
    );
}

























