import React, { useState, useEffect } from 'react';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import DetailsPageCompany from '../../common/DetailsPage/DetailsPageCompany';
import DetailPageClient from '../../common/DetailsPage/DetailPageClient';
import { AppBarCompany,  NotesCardColumns } from '../../../constants/DetailsPage'
import { Main } from '../../../constants/DetailsMain';
import CustomDelete from '../../../components/common/CustomDeleteAlert'
import CustomModal from "../../../components/common/CustomModal";
import CreateLead from "../../Leads/CreateLead";
import { genericGet } from '../../../services/apiServices';
import { Delete_Lead_leadId, GET_LEADS } from '../../../services/apiRoutes';
import { getIndustryType, getRating, getLeadSource, getLeadStatus, getCountries, getBusinessGroup } from '../../../services/CommonServices';
import MessageBox from '../../common/CustomMessageBox';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';



import { IconButton } from '@mui/material';
import axios from 'axios';
import UpdateLeads from '../../Leads/UpdateLeads';
import { useClientCompanyDetails } from './UseClientCompanyDetails';
import { useNavigate, useParams } from 'react-router-dom';


export default function PersistentDrawerLeft() {
    const navigate = useNavigate();  // Initialize useNavigate
    const { clientCompanyId } = useParams();
    const { companyDetail } = useClientCompanyDetails(clientCompanyId);

    const [open, setOpen] = React.useState(true);
    const [leadModal, setLeadModal] = useState(false);
    const [getAllLead, setGetAllLead] = useState([]);
    const [loading, setLoading] = useState(false)
    const [industryType, setIndustryType] = useState([]);
    const [rating, setRating] = useState([]);
    const [leadSource, setLeadSource] = useState([]);
    const [leadStatus, setLeadStatus] = useState([]);
    const [conutries, setConutries] = useState([]);
    const [businessGroup, setBusinessGroup] = useState([]);
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [rowId, setRowId] = useState('')
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedId, setselectedId] = useState(null);


    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })


    const [activeTab, setActiveTab] = React.useState('Lead');

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };


    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false)
    }

    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true)
        setRowId(rowId)
    }


    const getLeadList = async () => {
        setLoading(true)
        try {
            const response = await genericGet({ url: GET_LEADS });
            if (response.data) {
                const LeadData = response.data.leads.map((lead, index) => ({
                    ...lead,
                    leadId: lead.leadId || '',
                    expectedDate: lead.expectedDate || '',
                    company: lead.companyId || '',
                    country: lead.country?.name || lead.name || '',
                    contactPerson: `${lead.firstName || ''} ${lead.lastName || ''}`,
                    contactNo: lead.mobile || lead.phone || '',
                    leadStatus: lead.leadStatus?.leadStatusName || lead.leadStatusName || '',
                    source: lead.leadSource?.leadSourceName || lead.leadSourceName || '',
                    expectedPurchaseDate: lead.expectedDate || '',
                }));
                setGetAllLead(LeadData);
                setLoading(false)

            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error fetching leads", error);
        }
    };


    const handleDelete = async (leadId) => {
        setLoading(true)
        const authToken = sessionStorage.getItem('accessToken');
        try {
            const response = await axios.delete(Delete_Lead_leadId(leadId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                getLeadList()
                setDeleteAlert(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error deleting lead", error);
        }
    };

    const getCommonData = async () => {
        const industryTypeData = await getIndustryType()
        setIndustryType(industryTypeData);
        const ratingData = await getRating()
        setRating(ratingData);
        const leadSourceData = await getLeadSource()
        setLeadSource(leadSourceData)
        const leadStatusData = await getLeadStatus()
        setLeadStatus(leadStatusData)
        const getConutriesData = await getCountries()
        setConutries(getConutriesData)
        const businessGroupData = await getBusinessGroup()
        setBusinessGroup(businessGroupData)
    }

    useEffect(() => {
        getLeadList()
        getCommonData()
    }, [])


    const handleViewClick = (leadId) => {
        // Handle view click logic here
        console.log(`View details for leadId: ${leadId}`);
    };

    const handleUpdateClick = (leadId) => {
        // Handle update click logic here
        handleOpenUpdate(leadId);
    };


    const handleOpenUpdate = (leadId) => {
        setselectedId(leadId);
        setUpdateModalOpen(true);
    };
    const handleCloseUpdate = () => {
        setUpdateModalOpen(false);
        setselectedId(null);
    };


    const CompanyLeadColumn = [
        { accessor: "leadId", label: "SN.", id: "leadId" },
        { accessor: "expectedDate", label: "Date", id: "expectedDate" },
        { accessor: "company", label: "Company", id: "company" },
        { accessor: "country", label: "Country", id: "country" },
        { accessor: "contactPerson", label: "Contact Person", id: "contactPerson" },
        { accessor: "contactNo", label: "Contact No.", id: "contactNo" },
        { accessor: "leadStatus", label: "Lead Status", id: "leadStatus" },
        { accessor: "source", label: "Source", id: "source" },
        {
            accessor: "expectedPurchaseDate",
            label: "Expected Purchase Date",
            id: "expectedPurchaseDate",
        },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => handleViewClick(row.leadId)} style={{ marginRight: '1px' }}>
                            <VisibilityIcon style={{ color: "#059212" }} />
                        </IconButton>
                        <IconButton onClick={() => handleUpdateClick(row.leadId)} style={{ marginRight: '1px' }}>
                            <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteAlert(row.leadId)}>
                            <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                        </IconButton>
                    </div>

                </>
            ),
        },
    ];



    if (!companyDetail) {
        return <p>Loading...</p>;
    }
    const companyName = companyDetail.clientCompanyName;
    const phoneNumber = companyDetail.mobile;
    const initial = companyDetail.clientCompanyName ? companyDetail.clientCompanyName.charAt(0).toUpperCase() : '';
    const handleBackClick = () => {
        navigate("/app/company");
    };
    return (
        <>
            <div>
                <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={AppBarCompany(clientCompanyId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}
                />

                <Main open={open}>

                    <DetailsPageCard
                        columns={CompanyLeadColumn}
                        title={'Lead'}
                        data={getAllLead}
                        buttonTitle={'Create Lead'}
                        handleOpen={handleOpen}
                    />
                    <CustomModal open={leadModal} handleClose={handleClose}>
                        <CreateLead
                            handleClose={handleClose}
                            setMessage={setMessage}
                            industryType={industryType}
                            rating={rating}
                            leadSource={leadSource}
                            leadStatus={leadStatus}
                            conutries={conutries}
                            businessGroup={businessGroup}
                            getLeadList={getLeadList}
                        />
                    </CustomModal>


                    <CustomModal open={updateModalOpen} handleClose={handleCloseUpdate}>
                        <UpdateLeads
                            handleClose={handleCloseUpdate}
                            leadId={selectedId}
                            setMessage={setMessage}
                            industryType={industryType}
                            rating={rating}
                            leadSource={leadSource}
                            leadStatus={leadStatus}
                            conutries={conutries}
                            businessGroup={businessGroup}
                            getLeadList={getLeadList}

                        />
                    </CustomModal>

                    <MessageBox
                        open={message.open}
                        onClose={() => setMessage({ ...message, open: false })}
                        message={message.text}
                        handleClose={() => setMessage({ ...message, open: false })}
                        type={message.type}
                    />
                    <CustomDelete
                        handleDelete={handleDelete}
                        open={deleteAlert}
                        rowId={rowId}
                        handleClose={() => setDeleteAlert(false)}
                    />

                </Main>
            </div >
        </>
    );
}

