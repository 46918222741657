import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import { ClearRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import { READ_EXPENSE_VOUCHER } from '../../../services/apiRoutes';
import CustomCardView from "../../common/CustomCardView";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

const ExpenseVoucherView = ({ handleClose, setMessage, fetchData, ExpenseVoucherId, currencyList, expenseLedger }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!ExpenseVoucherId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_EXPENSE_VOUCHER(ExpenseVoucherId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.expenseVoucher);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [ExpenseVoucherId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;


    const values = {
        voucherNo: data?.voucherNo || '',
        date: data?.date || '',
        userId: data?.userId || '',
        companyId: data?.companyId || '',
        clientCompanyId: data?.clientCompanyId || '',
        commanShipmentId: data?.commanShipmentId || '',
        expensesLedgerId: data?.expenseLedger?.ledgerName || '',
        amount: data?.amount || '',
        currencyId: data?.currency?.currency || '',
        baseCurrencyId: data?.baseCurrency?.currency || '',
        conversionRate: data?.conversionRate || '',
        amountInBaseCurrency: data?.amountInBaseCurrency || '',
        note: data?.note || '',
        createdAt: data?.createdAt || '',
        updatedAt: data?.updatedAt || '',
    };

    const labels = [
        { key: 'voucherNo', label: 'Voucher No', sm: 12, md: 4, },
        { key: 'amount', label: 'Amount', sm: 12, md: 4, },
        { key: 'date', label: 'Date', sm: 12, md: 4, },
        { key: 'expensesLedgerId', label: 'Expenses Ledger', sm: 12, md: 4, },
        { key: 'currencyId', label: 'Currency', sm: 12, md: 4, },
        { key: 'baseCurrencyId', label: 'Base Currency', sm: 12, md: 4, },
        { key: 'conversionRate', label: 'Conversion Rate', sm: 12, md: 4, },
        { key: 'amountInBaseCurrency', label: 'Amount in Base Currency', sm: 12, md: 4, },
        { key: 'note', label: 'Note' },
    ];

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik initialValues={values} enableReinitialize>
                {() => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#212529", pt: "5px" }}>
                                    Expense Voucher
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>
                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="print">
                                        <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close" onClick={handleClose} >
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomCardView label={labels} data={values} />
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default ExpenseVoucherView;
