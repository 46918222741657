import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useTranslation } from "react-i18next";
import DataTable from "../components/common/DataTable";
import CustomModal from "../components/common/CustomModal";
import CreateLead from "../components/Leads/CreateLead";
import { Delete_Lead_leadId, GET_LEADS } from "../services/apiRoutes"
import { genericGet } from "../services/apiServices"
import { getIndustryType, getRating, getLeadSource, getLeadStatus, getCountries, getBusinessGroup, getClientCompany, formatDate, getOwner, getTitles } from '../services/CommonServices'
import UpdateLeads from "../components/Leads/UpdateLeads";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axios from 'axios';
import CustomLoader from "../components/common/CustomLoader";
import MessageBox from "../components/common/CustomMessageBox";
import CustomDelete from '../components/common/CustomDeleteAlert'
import { AuthContext } from "../context/AuthContext";

const title = "Leads Management";

const Lead = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { token } = useContext(AuthContext)
  const [leadModal, setLeadModal] = useState(false);
  const [updateLeadModal, setUpdateLeadModal] = useState(false);

  const [getAllLead, setGetAllLead] = useState([]);
  const [industryType, setIndustryType] = useState([]);
  const [rating, setRating] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [conutries, setConutries] = useState([]);
  const [businessGroup, setBusinessGroup] = useState([]);
  const [clientCompany, setClientCompany] = useState([]);
  const [leadOwner, setLeadOwner] = useState([]);
  const [titles, setTitles] = useState([]);
  const [loading, setLoading] = useState(false)
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [rowId, setRowId] = useState('')
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate);
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5);
  const [message, setMessage] = useState({
    open: false,
    text: '',
    type: ''
  })

  const columns = useMemo(
    () => [
      { accessorKey: "leadId", header: "SN.", size: 50 },
      { accessorKey: "createdAt", header: "Date", size: 50 },
      { accessorKey: "company", header: "Company", size: 50 },
      { accessorKey: "country", header: "Country", size: 50 },
      { accessorKey: "contactPerson", header: "Contact Person", size: 50 },
      { accessorKey: "contactNo", header: "Contact No.", size: 50 },
      { accessorKey: "leadStatus", header: "Lead Status", size: 50 },
      { accessorKey: "source", header: "Source", size: 50 },
      {
        accessorKey: "expectedPurchaseDate",
        header: "Expected Purchase Date",
        size: 300,
      },
      {
        accessorKey: "action",
        header: "Action",
        size: 50,
        Cell: ({ row }) => (
          <>
            <IconButton onClick={() => handleViewClick(row.original.leadId)}>
              <VisibilityIcon style={{ color: "#059212" }} />
            </IconButton>
            <IconButton onClick={() => handleUpdateClick(row.original.leadId)}>
              <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
            </IconButton>
            <IconButton onClick={() => handleOpenDeleteAlert(row.original.leadId)}>
              <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
            </IconButton>
          </>

        ),
      },

    ],
    []
  );

  const [selectedLeadId, setSelectedLeadId] = useState(null); // State to hold selected lead ID

  const handleUpdateClick = (leadId) => {
    setSelectedLeadId(leadId);
    setUpdateLeadModal(true);
  };


  const handleCloseUpdateLead = () => {
    setUpdateLeadModal(false);
  };

  // const getLeadList = async () => {
  //   try {
  //     const response = await genericGet({ url: GET_LEADS })
  //     if (response.data) {
  //       const LeadData = response.data.map((v, i) => ({
  //         sn: i + 1,
  //         date: '',
  //         company: v?.companyId,
  //         country: v?.countryId,
  //         contactPerson: '',
  //         contactNo: v.mobile,
  //         leadStatus: v.leadStatus,
  //         source: v?.leadSource,
  //         expectedPurchaseDate: v.expectedDate
  //       }))
  //       setGetAllLead(LeadData)

  //     }

  //   } catch (error) {
  //     console.log("error", error)
  //   }
  // }


  const handleDelete = async (leadId) => {
    setLoading(true)
    const authToken = sessionStorage.getItem('accessToken');
    try {
      const response = await axios.delete(Delete_Lead_leadId(leadId), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        getLeadList()
        setDeleteAlert(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error("Error deleting lead", error);
    }
  };


  const getLeadList = async () => {
    setLoading(true)
    try {
      const response = await genericGet({ url: GET_LEADS });
      if (response.data) {
        const LeadData = response.data.leads.map((lead, index) => ({
          ...lead,
          leadId: lead.leadId || '',
          createdAt: formatDate(lead.createdAt),
          expectedDate: lead.expectedDate || '',
          company: lead.clientCompany?.clientCompanyName || '',
          country: lead.country?.name || lead.name || '',
          contactPerson: `${lead.firstName || ''} ${lead.lastName || ''}`,
          contactNo: lead.mobile || lead.phone || '',
          leadStatus: lead.leadStatus?.leadStatusName || lead.leadStatusName || '',
          source: lead.leadSource?.leadSourceName || lead.leadSourceName || '',
          expectedPurchaseDate: lead.expectedDate || '',
        }));
        setGetAllLead(LeadData);
        setLoading(false)

      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error("Error fetching leads", error);
    }
  };

  const handleOpenDeleteAlert = (rowId) => {
    setDeleteAlert(true)
    setRowId(rowId)
  }

  useEffect(() => {
    getLeadList()
  }, [token])
  useEffect(() => {
    getCommonData()
  }, [])

  const getCommonData = async () => {
    const industryTypeData = await getIndustryType()
    setIndustryType(industryTypeData);
    const ratingData = await getRating()
    setRating(ratingData);
    const leadSourceData = await getLeadSource()
    setLeadSource(leadSourceData)
    const leadStatusData = await getLeadStatus()
    setLeadStatus(leadStatusData)
    const getConutriesData = await getCountries()
    setConutries(getConutriesData)
    const businessGroupData = await getBusinessGroup()
    setBusinessGroup(businessGroupData)
    const clientCompany = await getClientCompany()
    setClientCompany(clientCompany)
    const leadOwner = await getOwner()
    setLeadOwner(leadOwner)
    const titlesData = await getTitles()
    setTitles(titlesData)
  }

  const handleOpen = () => {
    setLeadModal(true);
  };
  const handleClose = () => {
    setLeadModal(false)
  }

  const handleViewClick = (leadId) => {
    navigate(`/app/leads/${leadId}`);
  };
  return (
    <>
      <Box sx={{ padding: "10px" }}>
        <CustomLoader open={loading} />
        <DataTable
          title={title}
          columns={columns}
          data={getAllLead}
          handleOpen={handleOpen}
          buttonTitle="Create Lead"
        />
        <CustomModal open={leadModal} handleClose={handleClose}>
          <CreateLead
            handleClose={handleClose}
            industryType={industryType}
            clientCompany={clientCompany}
            leadOwner={leadOwner}
            rating={rating}
            leadSource={leadSource}
            titles={titles}
            leadStatus={leadStatus}
            conutries={conutries}
            businessGroup={businessGroup}
            getLeadList={getLeadList} setMessage={setMessage}
          />
        </CustomModal>

        <CustomModal open={updateLeadModal} handleClose={handleCloseUpdateLead}>
          <UpdateLeads
            handleClose={handleCloseUpdateLead}
            industryType={industryType}
            titles={titles}
            rating={rating}
            clientCompany={clientCompany}
            leadSource={leadSource}
            leadStatus={leadStatus}
            leadOwner={leadOwner}
            getAllLead={getAllLead.find((v) => v.leadId == selectedLeadId)}
            conutries={conutries}
            getLeadList={getLeadList}
            setMessage={setMessage}
            leadId={selectedLeadId}
            businessGroup={businessGroup} />
        </CustomModal>
        <MessageBox
          open={message.open}
          onClose={() => setMessage({ ...message, open: false })}
          message={message.text}
          handleClose={() => setMessage({ ...message, open: false })}
          type={message.type}
        />
        <CustomDelete
          handleDelete={handleDelete}
          open={deleteAlert}
          rowId={rowId}
          handleClose={() => setDeleteAlert(false)}
        />
      </Box>
    </>
  );
};
export default Lead;