import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DetailPageCustum from '../common/DetailsPage/DetailPageCustum';
import { GET_contactId } from '../../services/apiRoutes';

const ContactDetail = () => {
    const { contactId } = useParams();
    const [contactDetails, setContactDetails] = useState(null);

    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');

        axios.get(GET_contactId(contactId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setContactDetails(response.data.contact);
            })
            .catch(error => {
                console.error('Error fetching contact details:', error);
            });
    }, [contactId]);

    if (!contactDetails) {
        return <p>Loading...</p>;
    }

    const tableData = [
        { label: 'Contact ID', value: contactDetails.contactId || 'N/A' },
        { label: 'User ID', value: contactDetails.userId || 'N/A' },
        { label: 'Company ID', value: contactDetails.company?.companyName || 'N/A' },
        { label: 'Contact Owner ID', value: contactDetails.contactOwner?.contactOwnerName || 'N/A' },
        { label: 'Lead Source', value: contactDetails.leadSource?.leadSourceName || 'N/A' },
        { label: 'Contact Group', value: contactDetails.contactGroup?.contactGroupName || 'N/A' },
        { label: 'Contact Type', value: contactDetails.contactType?.contactTypeName || 'N/A' },
        { label: 'Title', value: contactDetails.title || 'N/A' },
        { label: 'Description', value: contactDetails.description || 'N/A' },
        { label: 'Created At', value: contactDetails.createdAt ? new Date(contactDetails.createdAt).toLocaleString() : 'N/A' },
        { label: 'Updated At', value: contactDetails.updatedAt ? new Date(contactDetails.updatedAt).toLocaleString() : 'N/A' },
        { label: 'User Name', value: `${contactDetails.user?.firstName || ''} ${contactDetails.user?.lastName || ''}`.trim() || 'N/A' }
    ];

    return <DetailPageCustum title="Contact Details" data={tableData} />;
};

export default ContactDetail;
