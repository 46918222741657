import { useState, useEffect } from 'react';
import axios from 'axios';
import { GET_clientCompany_Id } from '../../../services/apiRoutes';

export const useClientCompanyDetails = (clientCompanyId) => {
    const [companyDetail, setCompanyDetail] = useState(null);

    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');

        axios.get(GET_clientCompany_Id(clientCompanyId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setCompanyDetail(response.data.clientCompany);
            })
            .catch(error => {
                console.error('Error fetching company details:', error);
            });
    }, [clientCompanyId]);

    return { companyDetail };
};
