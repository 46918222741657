
import { useState, useEffect } from 'react';
import axios from 'axios';
import { READ_EXPORT_SHIPMENT } from '../../../../services/apiRoutes';

export const useExportShipmentDetails = (exportShipmentId) => {
    const [shipmentDetails, setShipmentDetails] = useState(null);

    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');

        axios.get(READ_EXPORT_SHIPMENT(exportShipmentId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setShipmentDetails(response.data.shipment);
            })
            .catch(error => {
                console.error('Error fetching shipment details:', error);
            });
    }, [exportShipmentId]);

    return { shipmentDetails };
};


