import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import DataTable from "../../components/common/DataTable";
import CustomModal from "../../components/common/CustomModal";
import CustomLoader from "../../components/common/CustomLoader";
import MessageBox from "../../components/common/CustomMessageBox";

import { AuthContext } from "../../context/AuthContext";
import CreateManageProducts from "./CreateMasters/CreateManageProducts";

import { GET_Financial_Year } from "../../services/apiRoutes";
import { genericGet } from "../../services/apiServices";

const FinancialYear = () => {
    const { token } = useContext(AuthContext);

    const [modal, setModal] = useState(false);
    const [financialData, setFinancialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });

    const columns = useMemo(() => [
        { accessorKey: "financialYearId", header: "SN.", size: 50 },
        { accessorKey: "startDate", header: "Start Date", size: 100 },
        { accessorKey: "endDate", header: "End Date", size: 100 },
        {
            accessorKey: "action",
            header: "Action",
            size: 100,
            Cell: ({ row }) => (
                <>
                    <IconButton>
                        <VisibilityIcon style={{ color: "#059212" }} />
                    </IconButton>
                    <IconButton>
                        <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                    </IconButton>
                    <IconButton>
                        <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                    </IconButton>
                </>
            ),
        },
    ], []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_Financial_Year });
            if (response.data) {
                const formattedData = response.data.financialYear.map((data, index) => ({
                    ...data,
                    chaId: data.chaId || '',
                }));
                setFinancialData(formattedData);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [token]);

    const handleOpen = () => setModal(true);
    const handleClose = () => setModal(false);

    return (
        <Box sx={{ padding: "10px" }}>
            <CustomLoader open={loading} />

            <DataTable
                title="Financial Year"
                columns={columns}
                data={financialData}
                handleOpen={handleOpen}
                buttonTitle="Create Financial Year"
            />

            <CustomModal open={modal} handleClose={handleClose}>
                <CreateManageProducts
                    handleClose={handleClose}
                    fetchData={fetchData}
                    setMessage={setMessage}
                />
            </CustomModal>

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
        </Box>
    );
};

export default FinancialYear;
