import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from "react-i18next";
import DataTable from '../components/common/DataTable';
import MessageBox from "../components/common/CustomMessageBox";
import CustomDelete from '../components/common/CustomDeleteAlert';
import CustomModal from "../components/common/CustomModal";
import Createcompany from "../components/company/createcompany";
import UpdateClientCompany from "../components/company/UpdateClientCompany";
import { getIndustryType, getRating, getLeadSource, getLeadStatus, getCountries, getBusinessGroup, getStates, getCities, getCompanyTypes, getCompanyOwnershipType, getOwner } from '../services/CommonServices';
import { Delete_clientCompanies, GET_clientCompanies } from "../services/apiRoutes";
import { genericGet } from "../services/apiServices";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import CustomLoader from "../components/common/CustomLoader";

const title = 'Company';

const Company = () => {
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null); // State to hold selected ID
  const [clientCompanies, setClientCompanies] = useState([]);
  const [owner, setOwner] = useState([]);

  const [message, setMessage] = useState({
    open: false,
    text: '',
    type: ''
  })
  const [getAllLead, setGetAllLead] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowId, setRowId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [industryType, setIndustryType] = useState([]);
  const [businessGroup, setBusinessGroup] = useState([]);
  const [rating, setRating] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [companyOwnershipTypes, setCompanyOwnershipTypes] = useState([]);
  const { token } = useContext(AuthContext)



  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleUpdateClick = (clientCompanyId) => {
    setSelectedId(clientCompanyId); // Updated to use selectedId
    setUpdateModal(true);
  };

  const handleOpenDeleteAlert = (rowId) => {
    setDeleteAlert(true);
    setRowId(rowId);
  };

  const handleCloseUpdate = () => {
    setUpdateModal(false);
  };

  const handleDelete = async (clientCompanyId) => {
    setLoading(true);
    const authToken = sessionStorage.getItem('accessToken');
    try {
      const response = await axios.delete(Delete_clientCompanies(clientCompanyId), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        fetchClientCompanies();
        setDeleteAlert(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error deleting client company", error);
    }
  };

  useEffect(() => {
    fetchClientCompanies()
  }, [token])

  useEffect(() => {
    getCommonData();
  }, []);

  const columns = useMemo(
    () => [
      { accessorKey: "clientCompanyId", header: "SN.", size: 50 },
      { accessorKey: "clientCompanyName", header: "Company", size: 150 },
      { accessorKey: "country", header: "Country", size: 100 },
      { accessorKey: "mobile", header: "Contact No.", size: 150 },
      { accessorKey: "email", header: "Email ID", size: 200 },
      { accessorKey: "Contacts", header: "Company Admin", size: 100 },
      {
        accessorKey: "action",
        header: "Action",
        size: 50,
        Cell: ({ row }) => (
          <>
            <IconButton onClick={() => handleViewClick(row.original.clientCompanyId)}>
              <VisibilityIcon style={{ color: "#059212" }} />
            </IconButton>
            <IconButton onClick={() => handleUpdateClick(row.original.clientCompanyId)}>
              <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
            </IconButton>
            <IconButton onClick={() => handleOpenDeleteAlert(row.original.clientCompanyId)}>
              <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
            </IconButton>
          </>
        ),
      },
    ],
    []
  );

  const fetchClientCompanies = async () => {
    setLoading(true);
    try {
      const response = await genericGet({ url: GET_clientCompanies });

      if (response.data.success && response.data.clientCompanies) {
        const clientCompanyData = response.data.clientCompanies.map((company) => ({
          clientCompanyId: company.clientCompanyId || '',
          clientCompanyName: company.clientCompanyName || '',
          phone: company.phone || '',
          mobile: company.mobile || '',
          email: company.email || '',
          address: company.address || '',
          country: company.mailingCountry?.name || '',
          Contacts: company.companyAdmin?.firstName || '',
          Contacts: `${company.companyAdmin?.firstName || ''} ${company.companyAdmin?.lastName || ''}`,

        }));
        setClientCompanies(clientCompanyData);
      } else {
        setError('No client companies found');
      }
    } catch (error) {
      setError('Error fetching client companies');
      console.error('Error fetching client companies', error);
    } finally {
      setLoading(false);
    }
  };


  // const getCommonData = async () => {
  //   try {
  //     const [industryTypeData, ratingData, conutriesData] = await Promise.all([
  //       getIndustryType(),
  //       getRating(),
  //       getCountries()
  //     ]);

  //     setIndustryType(industryTypeData);
  //     setRating(ratingData);
  //     setConutries(conutriesData);
  //   } catch (error) {
  //     console.error('Error fetching common data', error);
  //   }
  // };
  const getCommonData = async () => {

    const industryTypeData = await getIndustryType();
    setIndustryType(industryTypeData);

    const ratingData = await getRating();
    setRating(ratingData);

    const countriesData = await getCountries();
    setCountries(countriesData);

    const businessGroupData = await getBusinessGroup();
    setBusinessGroup(businessGroupData);
    const owner = await getOwner();
    setOwner(owner);
    const companyTypesData = await getCompanyTypes();
    setCompanyTypes(companyTypesData);

    const companyOwnershipData = await getCompanyOwnershipType();
    setCompanyOwnershipTypes(companyOwnershipData);
  };

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleViewClick = (clientCompanyId) => {
    navigate(`/app/company/${clientCompanyId}`);
  };

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <CustomLoader open={loading} />
        <DataTable
          title={title}
          columns={columns}
          data={clientCompanies}
          handleOpen={handleOpen}
          buttonTitle="Create Company"
        />
        <CustomModal open={modal} handleClose={handleClose}>
          <Createcompany
            handleClose={handleClose}
            fetchClientCompanies={fetchClientCompanies}
            setMessage={setMessage}
            owner={owner}
            industryType={industryType}
            businessGroups={businessGroup}
            rating={rating}
            countries={countries}
            states={states}
            cities={cities}
            companyTypes={companyTypes}
            companyOwnershipTypes={companyOwnershipTypes}

          />
        </CustomModal>

        <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
          <UpdateClientCompany
            handleClose={handleCloseUpdate}
            getAllLead={getAllLead.find((v) => v.leadId == selectedId)}
            clientCompanyId={selectedId}
            fetchClientCompanies={fetchClientCompanies}
            setMessage={setMessage}
            owner={owner}
            industryType={industryType}
            businessGroups={businessGroup}
            rating={rating}
            countries={countries}
            states={states}
            cities={cities}
            companyTypes={companyTypes}
            companyOwnershipTypes={companyOwnershipTypes}
          />
        </CustomModal>

        <MessageBox
          open={message.open}
          onClose={() => setMessage({ ...message, open: false })}
          message={message.text}
          handleClose={() => setMessage({ ...message, open: false })}
          type={message.type}
        />
        <CustomDelete
          handleDelete={handleDelete}
          open={deleteAlert}
          rowId={rowId}
          handleClose={() => setDeleteAlert(false)}
        />
      </Box>
    </>
  );
};

export default Company;
