import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, Divider, Table, TableBody, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import { GET_Lead_leadId } from '../../../services/apiRoutes';

const cardStyle = {
    // marginBottom: '1rem',
    // boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
};

const cardHeaderStyle = {
    backgroundColor: '#fff',
};

const cardBodyStyle = {
    padding: '1rem',
};

const tableStyle = {
    marginBottom: 0,
    borderCollapse: 'collapse', // Remove space between table cells
};

const tableCellStyle = {
    width: '150px',
    color: '#6c757d',
    border: 'none',
    borderBottom: '0',
};

const tableCellStylemiddle = {
    width: '10px',
    color: '#6c757d',
    border: 'none',
    borderBottom: '0',
};

const tableCellStylelast = {
    border: 'none', // Remove all borders
    borderBottom: '0', // Remove bottom border from all cells
};

const tableRow = {
    height: '20px',
};

const ClientCompanyDetails = () => {
    const { leadId } = useParams();
    const [companyDetails, setCompanyDetails] = useState(null);

    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');

        axios.get(GET_Lead_leadId(leadId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setCompanyDetails(response.data.lead);
            })
            .catch(error => {
                console.error('Error fetching company details:', error);
            });
    }, [leadId]);

    if (!companyDetails) {
        return <p>Loading...</p>;
    }


    return (
        <Box id="overview" >
            <Card style={cardStyle} variant="outlined">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '1.2rem', padding: '0.5rem' }}>
                        Client Company Details
                    </span>
                    <span style={{ marginRight: "12px" }}>
                        <EditIcon style={{ color: "#007bff", cursor: "pointer" }} />
                    </span>
                </div>
                <Divider />
                <CardContent style={cardBodyStyle}>
                    <Table style={tableStyle} size="small" aria-label="client company details">
                        <TableBody>
                            <TableRow style={tableRow}>
                                <TableCell style={tableCellStyle}>Company Name</TableCell>
                                <TableCell style={tableCellStylemiddle}>-</TableCell>
                                <TableCell style={tableCellStylelast}>{companyDetails.companyId}</TableCell>
                            </TableRow>
                            <TableRow style={tableRow}>
                                <TableCell style={tableCellStyle}>Email</TableCell>
                                <TableCell style={tableCellStylemiddle}>-</TableCell>
                                <TableCell style={tableCellStylelast}> {companyDetails.email}</TableCell>
                            </TableRow>
                            <TableRow style={tableRow}>
                                <TableCell style={tableCellStyle}>Mobile</TableCell>
                                <TableCell style={tableCellStylemiddle}>-</TableCell>
                                <TableCell style={tableCellStylelast}>{companyDetails.mobile}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>

            </Card>
        </Box>
    );
};

export default ClientCompanyDetails;
