import React from 'react';
import {
    AppBar,
    Box,
    Container,
    IconButton,
    InputBase,
    Toolbar,
    Typography,
    useMediaQuery,
    Menu,
    MenuItem,
    Avatar,
    Grid,
    Card,
    CardMedia
} from '@mui/material';
import {
    Menu as MenuIcon,
    Search as SearchIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Logo from '../../assets/images/HRVERSE_logo.svg';
import BannerSearch from './BannerSearch';

const HelpCenterDetail = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <BannerSearch />
            <Container>
                <Typography variant="h1" sx={{ fontWeight: 'bold', color: '#007BFF', marginBottom: 2 }}>
                    Advice and answers from the Exim Team
                </Typography>
                <Grid container spacing={2}>
                    {cardData.map((card, index) => (
                        <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                            <Card sx={cardStyles}>
                                <Box sx={cardIconContainerStyles}>
                                    <CardMedia
                                        component="img"
                                        image="https://intercom.help/eximcrmhelp/assets/svg/icon:comms-video/000000"
                                        alt="Icon"
                                        sx={cardIconStyles}
                                    />
                                </Box>
                                <Box sx={cardHeaderStyles}>
                                    <Typography sx={cardTitleStyles}>
                                        {card.title}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" color="textSecondary" component="p" sx={{ textAlign: 'left' }}>
                                    {card.content}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, pt: 2 }}>
                                    <Avatar src="https://static.intercomassets.com/avatars/878640/square_128/BenS-1499930535.jpg" alt="Ben Thompson" sx={avatarStyles} />
                                    <Typography variant="body2" color="textSecondary">
                                        By Ben and 1 other
                                    </Typography>
                                    <svg width="4" height="4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                                        <path d="M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8Z"></path>
                                    </svg>
                                    <Typography variant="body2" color="textSecondary">
                                        {card.articles} articles
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <br />

                <Box sx={{ lineHeight: 1.5 }}>
                    {paragraphsData.map((paragraph, index) => (
                        <React.Fragment key={index}>
                            {paragraph.heading && (
                                <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: 2 }}>
                                    {paragraph.heading}
                                </Typography>
                            )}
                            {paragraph.content && (
                                <Typography paragraph>
                                    {paragraph.content}
                                </Typography>
                            )}
                            {paragraph.image && (
                                <Box sx={{ mb: 2 }}>
                                    <Link to={paragraph.link} target="_blank" rel="noopener noreferrer">
                                        <img src={paragraph.image} alt={paragraph.imageAlt} style={{ width: '100%', height: 'auto' }} />
                                    </Link>
                                </Box>
                            )}
                        </React.Fragment>
                    ))}
                </Box>
            </Container>
        </>
    );
};

const cardStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align items to the left
    justifyContent: 'center',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'left', // Align text to the left
};

const cardIconContainerStyles = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
};

const cardIconStyles = {
    width: '64px',
    height: '64px',
    objectFit: 'contain',
};

const cardHeaderStyles = {
    marginBottom: '8px',
};

const cardTitleStyles = {
    fontWeight: 'bold',
    color: '#007BFF',
};

const avatarStyles = {
    width: '24px',
    height: '24px',
};

const cardData = [
    {
        title: "How to use EximCRM - Video Tips & Guides",
        content: "Read and download useful Import / Export information.",
        articles: 40,
    },
];

const paragraphsData = [
    {
        content: "The payments features in EximCRM allows sellers to share invoices to buyers so that they can click to pay via Credit Card.",
    },
    {
        heading: "Step 1: Setup your Stripe account",
        content: "Note: Make sure you allow pop-ups on your internet browser. We recommend using Google Chrome.",
        image: "https://downloads.intercomcdn.com/i/o/438500598/03dcbf16e66b25f6bc2b5229/Setup-Stripe-Account.png?expires=1722924900&amp;signature=6140973583a20a020cbef2fb6a98addc2367bdf4c5637bebe47b38f2ea818020",
        imageAlt: "Stripe Account Setup",
        link: "#",
    },
    {
        content: "You will be taken to the EximCRM + Stripe setup page. From here you can enter your email address to either:",
    },
    {
        content: "Sign up for a new Stripe account (if you don't have one) or Log in if you have a Stripe account.",
    },
    {
        content: "Follow the prompts to complete the Stripe account setup.",
    },
    {
        heading: "Step 2: Link Stripe account to EximCRM",
        content: "After completing the setup in Stripe, you will be redirected back to EximCRM where you will need to confirm you have linked your account.",
    },
    {
        content: "Check the box next to 'I have connected my Stripe account' then click 'Save'.",
    },
];

export default HelpCenterDetail;
