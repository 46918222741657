import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Divider, IconButton, Menu, MenuItem } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ExportPieChart = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState('1');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (value) => {
        setSelectedValue(value);
        setAnchorEl(null);
    };

    useEffect(() => {
        const fetchData = () => {
            const data = [
                { label: 'Electronics', series: 49, value: 1 },
                { label: 'Textiles', series: 55, value: 1 },
                { label: 'Furniture', series: 13, value: 1 },
                { label: 'Machinery', series: 43, value: 1 },
                { label: 'Automobiles', series: 28, value: 1 },
                { label: 'Home Appliances', series: 60, value: 2 },
                { label: 'Clothing', series: 70, value: 2 },
                { label: 'Office Supplies', series: 20, value: 2 },
                { label: 'Construction Equipment', series: 50, value: 2 },
                { label: 'Sports Gear', series: 35, value: 2 },
                { label: 'Mobile Devices', series: 35, value: 3 },
                { label: 'Footwear', series: 40, value: 3 },
                { label: 'Garden Tools', series: 15, value: 3 },
                { label: 'Heavy Equipment', series: 25, value: 3 },
                { label: 'Motorcycles', series: 10, value: 3 },
                { label: 'Wearable Tech', series: 55, value: 4 },
                { label: 'Accessories', series: 65, value: 4 },
                { label: 'Home Decor', series: 22, value: 4 },
                { label: 'Industrial Machinery', series: 45, value: 4 },
                { label: 'Trucks', series: 30, value: 4 },

            ];

            const filteredData = data.filter(item => item.value.toString() === selectedValue);
            const seriesData = filteredData.map(item => item.series);
            const labelsData = filteredData.map(item => item.label);

            setSeries(seriesData);
            setLabels(labelsData);
            setLoading(false);
        };

        fetchData();
    }, [selectedValue]);

    const options = {
        chart: {
            width: 380,
            type: 'pie'
        },
        labels: labels,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <Card>
            <CardHeader
                title="Volume of Containers Completed - Export"
                action={
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                }
            />
            <Divider />
            <div id="chart">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="pie"
                        width={450}
                    />
                )}
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleMenuItemClick('1')}>Shipper/Supplier</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('2')}>Shipping line</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('3')}>Country</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('4')}>Product Group</MenuItem>
            </Menu>
        </Card>
    );
};

export default ExportPieChart;
