import { Box, Container, Toolbar } from '@mui/material';
import * as React from 'react';
import Containercarousel from './Containercarousel';
import GetStartedForFree from './GetStartedForFree';
import HomeKeyFeature from './HomeKeyFeature';
import HomepageBenefits from './HomepageBenefits';
import HomepagePlatform from './HomepagePlatform';
import FAQ from './FAQ';

const Features = () => {
    return (
        <Box component="main" sx={{ p: 0 }}>
            {/* <Toolbar /> */}
            <HomeKeyFeature />
            <br />
            <HomepageBenefits />
            <br />
            <HomepagePlatform />
            <br />
            <FAQ />
            <br />
        </Box>
    );
};

export default Features;
