import React, { useEffect, useState } from "react";
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { AppBarCompany, } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MessageBox from "../../common/CustomMessageBox";
import CustomDelete from '../../../components/common/CustomDeleteAlert';
import axios from "axios";
import CustomModal from "../../../components/common/CustomModal";
import { getCIFFOBList, getCurrencyList, getShipmentTypes, getSuppliersList } from '../../../services/CommonServices';
import { genericGet } from "../../../services/apiServices";
import { IconButton } from "@mui/material";
import { DELETE_IMPORT_SHIPMENT, LIST_ALL_IMPORT_SHIPMENT } from "../../../services/apiRoutes";
import Createimportshipement from "../../importshipement/createimportshipement";
import UpdateImportshipement from "../../importshipement/UpdateImportshipement";
import { useNavigate, useParams } from "react-router-dom";
import { useClientCompanyDetails } from "./UseClientCompanyDetails";

export default function PersistentDrawerLeft() {
    const navigate = useNavigate();  // Initialize useNavigate
    const { clientCompanyId } = useParams();
    const { companyDetail } = useClientCompanyDetails(clientCompanyId);
    const [open, setOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('Import Shipment');
    const [leadModal, setLeadModal] = useState(false);
    const [importShipments, setImportShipments] = useState([]);
    const [cifFobList, setCIFFOBList] = useState([]);
    const [shipmentTypes, setShipmentTypes] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [suppliersList, setSuppliersList] = useState([]);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [rowId, setRowId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null); // State to hold selected ID
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });

    useEffect(() => {
        fetchImportShipments();
        getCommonData();
    }, []);

    const handleUpdateClick = (importShipmentId) => {
        setSelectedId(importShipmentId);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
    };

    const handleOpen = () => {
        setLeadModal(true);
    };

    const handleClose = () => {
        setLeadModal(false);
    };

    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true);
        setRowId(rowId);
    };

    const handleViewClick = (importShipmentId) => {
        console.log(`View details for importShipmentId: ${importShipmentId}`);
    };

    const handleDelete = async (importShipmentId) => {
        setLoading(true);
        const authToken = sessionStorage.getItem('accessToken');
        try {
            const response = await axios.delete(DELETE_IMPORT_SHIPMENT(importShipmentId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                fetchImportShipments();
                setDeleteAlert(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error deleting import shipment", error);
        }
    };

    const fetchImportShipments = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: LIST_ALL_IMPORT_SHIPMENT });

            if (response.data.success && response.data.shipments) {
                const shipmentData = response.data.shipments.map((shipment) => ({
                    importShipmentId: shipment.importShipmentId || '',
                    companyName: shipment.company.companyName || '',
                    shipmentId: shipment.shipmentId || '',
                    expectedEtd: shipment.expectedEtd || '',
                    noOfContainers: shipment.noOfContainers || '',
                }));
                setImportShipments(shipmentData);
            } else {
                setError('No import shipments found');
            }
        } catch (error) {
            setError('Error fetching import shipments');
            console.error('Error fetching import shipments', error);
        } finally {
            setLoading(false);
        }
    };

    const getCommonData = async () => {
        try {
            const cifFobListData = await getCIFFOBList();
            setCIFFOBList(cifFobListData);

            const shipmentTypesData = await getShipmentTypes();
            setShipmentTypes(shipmentTypesData);

            const currencyListData = await getCurrencyList();
            setCurrencyList(currencyListData);

            const suppliersListData = await getSuppliersList();
            setSuppliersList(suppliersListData);
        } catch (error) {
            console.error('Error fetching common data', error);
        }
    };

    const ImportShipmentColumn = [
        { accessor: "sn", label: "SN.", id: "sn" },
        { accessor: "date", label: "Date", id: "date" },
        { accessor: "shipmentId", label: "Shipment ID", id: "shipmentId" },
        { accessor: "supplierName", label: "Supplier Name", id: "supplierName" },
        { accessor: "country", label: "Country", id: "country" },
        { accessor: "noOfContainer", label: "No. Of Container", id: "noOfContainer" },
        { accessor: "cifFob", label: "CIF/FOB", id: "cifFob" },
        { accessor: "currency", label: "Currency", id: "currency" },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleViewClick(row.importShipmentId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: "#059212" }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.importShipmentId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.importShipmentId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                    </IconButton>
                </div>
            ),
        },
    ];

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;


    if (!companyDetail) {
        return <p>Loading...</p>;
    }
    const companyName = companyDetail.clientCompanyName;
    const phoneNumber = companyDetail.mobile;
    const initial = companyDetail.clientCompanyName ? companyDetail.clientCompanyName.charAt(0).toUpperCase() : '';
    const handleBackClick = () => {
        navigate("/app/company");
    };


    return (
        <>
            <div>
                <DetailsPageAppbar
                    handleScrollTo={setActiveTab}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={() => setOpen(true)}
                    handleDrawerClose={() => setOpen(false)}
                    AppBarMenu={AppBarCompany(clientCompanyId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}
                />

                <Main open={open}>
                    <DetailsPageCard
                        columns={ImportShipmentColumn}
                        title={'Import Shipment'}
                        buttonTitle={'Create Import Shipment'}
                        handleOpen={handleOpen}
                        data={importShipments}
                    />

                    <CustomModal open={leadModal} handleClose={handleClose}>
                        <Createimportshipement
                            handleClose={handleClose}
                            fetchImportShipments={fetchImportShipments}
                            setMessage={setMessage}
                            cifFobList={cifFobList}
                            shipmentTypes={shipmentTypes}
                            currencyList={currencyList}
                            suppliersList={suppliersList}
                        />
                    </CustomModal>

                    <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                        <UpdateImportshipement
                            handleClose={handleCloseUpdate}
                            importShipmentId={selectedId}
                            fetchImportShipments={fetchImportShipments}
                            setMessage={setMessage}
                            cifFobList={cifFobList}
                            shipmentTypes={shipmentTypes}
                            currencyList={currencyList}
                            suppliersList={suppliersList}
                        />
                    </CustomModal>

                    <MessageBox
                        open={message.open}
                        onClose={() => setMessage({ ...message, open: false })}
                        message={message.text}
                        handleClose={() => setMessage({ ...message, open: false })}
                        type={message.type}
                    />

                    <CustomDelete
                        handleDelete={handleDelete}
                        open={deleteAlert}
                        rowId={rowId}
                        handleClose={() => setDeleteAlert(false)}
                    />
                </Main>
            </div>
        </>
    );
}
