import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Card, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import { READ_COO_EXPORT } from '../../../services/apiRoutes';
import CustomCardView from "../../common/CustomCardView";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';

const COOView = ({ handleClose, setMessage, fetchData, cooId, mailingCountry }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!cooId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_COO_EXPORT(cooId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [cooId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;
    const values = {
        cooId: data?.cooId || '',
        userId: data?.userId || '',
        companyId: data?.companyId || '',
        clientCompanyId: data?.clientCompanyId || '',
        exporter: data?.exporter || '',
        serialNo: data?.serialNo || '',
        certificateNo: data?.certificateNo || '',
        consignee: data?.consignee || '',
        transportAndRoute: data?.transportAndRoute || '',
        certifyingAuthority: data?.certifyingAuthority || '',
        countryOrRegion: data?.countryOrRegion || '',
        marksAndPackages: data?.marksAndPackages || '',
        descriptionOfGoods: data?.descriptionOfGoods || '',
        hsCode: data?.hsCode || '',
        quantity: data?.quantity || '',
        invoiceDetails: data?.invoiceDetails || '',
        declarationByExporter: data?.declarationByExporter || '',
        certification: data?.certification || '',
        createdAt: data?.createdAt || '',
        updatedAt: data?.updatedAt || '',
    };

    const labels = [
        { key: 'exporter', label: 'Exporter', sm: 12, md: 6, },
        { key: 'consignee', label: 'Consignee', sm: 12, md: 6, },
        { key: 'serialNo', label: 'Serial No', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'certificateNo', label: 'Certificate No', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'transportAndRoute', label: 'Transport and Route', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'countryOrRegion', label: 'Country/Region', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'marksAndPackages', label: 'Marks and Packages', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'hsCode', label: 'HS Code', sm: 12, md: 3, sx: { pt: 2 } },
        { key: 'quantity', label: 'Quantity', sm: 12, md: 3, sx: { pt: 2 } },
        { key: 'descriptionOfGoods', label: 'Description of Goods', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'invoiceDetails', label: 'Invoice Details', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'certifyingAuthority', label: 'Certifying Authority', sm: 12, md: 10, sx: { pt: 2 } },

    ];
    const labelsFooter = [
        { key: 'declarationByExporter', label: 'Declaration by Exporter', sm: 12, md: 12, sx: { pt: 2 } },
        { key: 'certification', label: 'Certification', sm: 12, md: 12, sx: { pt: 2 } },
    ];
    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>

            <Formik initialValues={values} enableReinitialize>
                {() => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#212529" }}>
                                    COO
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>
                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="print">
                                        <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close" onClick={handleClose} >
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                </Box>

                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={0}>

                            <Grid item xs={12}>
                                <CustomCardView label={labels} data={values} />
                            </Grid>
                            <TableContainer style={{ paddingTop: "20px" }}>
                                <Table
                                    style={{
                                        width: "100%",
                                        border: "1px solid #dee2e6",
                                        borderCollapse: "collapse",
                                    }}
                                >
                                    <TableHead style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                                        <TableRow style={{ border: "1px solid #dee2e6" }}>
                                            <TableCell style={cellLabel}>
                                                Marks and No.
                                            </TableCell>
                                            <TableCell style={cellLabel}>
                                                No. and kind of packages; Description of Goods
                                            </TableCell>
                                            <TableCell style={cellLabel}>
                                                H.S Code
                                            </TableCell>
                                            <TableCell style={cellLabel}>Quantity</TableCell>
                                            <TableCell style={cellLabel}>
                                                No. and date of invoices
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            style={{
                                                border: "1px solid #dee2e6",
                                                color: "#212529",
                                                fontWeight: "600",
                                                fontSize: "14px",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "clip",
                                            }}
                                        >
                                            <TableCell style={tableCell}>{values.marksAndPackages}</TableCell>
                                            <TableCell style={tableCell}>{values.descriptionOfGoods}</TableCell>
                                            <TableCell style={tableCell}>{values.hsCode}</TableCell>
                                            <TableCell style={tableCell}>{values.quantity}</TableCell>
                                            <TableCell style={tableCell}>{values.invoiceDetails}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <br />

                        </Grid>
                        <Grid item xs={12}>
                            <CustomCardView label={labelsFooter} data={values} />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default COOView;

const cellLabel = {
    border: "1px solid #dee2e6",
    color: "#212529",
    fontWeight: "600",
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "clip",
    padding: "5px",
    textAlign: 'center',

}

const tableCell = {
    border: "1px solid #dee2e6",
    padding: "5px",
    textAlign: "left"

}