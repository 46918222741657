import React from 'react';
import { Grid, Box, Container, Typography, Button } from '@mui/material';
import CustomButton from '../common/CustomButton';
import { Padding } from '@mui/icons-material';

const HomepageBanners = () => {
    return (
        <Box sx={{ flexGrow: 1, mt: 8, mb: 8 }}>
            <Box sx={{ backgroundColor: "#007BFF", }}>
                <Grid container spacing={2} justifyContent="center" sx={{ pt: 8, }}>
                    <Grid item xs={12} md={7} lg={7} >
                        <Box

                            component="img"
                            src="https://incodocs.com/_next/static/images/homepage-Footer-Mockup-1cef1ac4e75a599392099de74a606e36.webp"
                            alt="Homepage Banner"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                display: 'block',
                                margin: 'auto',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} lg={5}>
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="h1" sx={{ color: "#fff", fontWeight: 'bold', fontSize: "3rem" }}>
                                Free to start,
                                <br />
                                Easy to use.
                                <br />
                                Setup in 5 mins.
                                <br />
                            </Typography>
                            <Box mt={2}>
                                <CustomButton
                                    variant="contained"
                                    color="secondary"
                                    title=" Get Started for Free"
                                    style={{
                                        backgroundColor: "#FFFF",
                                        color: "#007BFF",
                                        fontWeight: "bold",
                                        fontSize: 13,
                                        textTransform: "capitalize"
                                    }}
                                />
                                <Typography variant="body2" sx={{ color: "#fff", mt: 1 }}>
                                    <em>No installation or credit card required</em>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </Box>
    );
};

export default HomepageBanners;
