import React from 'react';
import { Grid, Card, CardContent, Typography, Container, Box } from '@mui/material';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const MyCard = ({ beforeHover, afterHover, icon }) => {
    return (
        <Card
            sx={{
                // backgroundColor: 'rgba(51,112,235,.07)',
                backgroundColor: '#007BFF',
                maxWidth: 345,
                height: "150px",

                '&:hover': {
                    backgroundColor: '#FFFF',

                },
                '&:hover .show-before': {
                    display: 'none',
                },
                '&:hover .show-after': {
                    display: 'block',
                    color: '#007BFF',

                },
            }}
        >
            <CardContent>
                <Typography
                    variant="body2"
                    color="text.secondary"
                    className="show-before"
                    sx={{
                        display: 'block',
                        fontWeight: 'bold',
                        fontSize: "30px",
                        color: "#FFFF",
                        textAlign: "center"
                    }}
                >
                    {icon}
                </Typography>

                <Typography
                    variant="body2"
                    color="text.secondary"
                    className="show-before"
                    sx={{
                        display: 'block',
                        fontWeight: 'bold',
                        color: "#FFFF",


                    }}
                >
                    {beforeHover}
                </Typography>

                <Typography
                    variant="body2"
                    color="text.secondary"
                    className="show-after"
                    sx={{
                        display: 'none',
                    }}
                >
                    {afterHover}
                </Typography>
            </CardContent>
        </Card>
    );
};

const HomepagePlatform = () => {
    const cardData = [
        { title: 'Card 1', beforeHover: 'Export Documentation', afterHover: 'Create or generate a full set of export documents using UNLK compliant templates & features to eliminate re-entry.', icon: <LibraryBooksIcon /> },
        { title: 'Card 2', beforeHover: 'Export Documentation  ', afterHover: 'Create or generate a full set of export documents using UNLK compliant templates & features to eliminate re-entry.  ', icon: <LibraryBooksIcon /> },
        { title: 'Card 3', beforeHover: 'Export Documentation  ', afterHover: 'Create or generate a full set of export documents using UNLK compliant templates & features to eliminate re-entry.  ', icon: <LibraryBooksIcon /> },
        { title: 'Card 4', beforeHover: 'Export Documentation ', afterHover: 'Create or generate a full set of export documents using UNLK compliant templates & features to eliminate re-entry.  ', icon: <LibraryBooksIcon /> },
        { title: 'Card 5', beforeHover: 'Export Documentation  ', afterHover: 'Create or generate a full set of export documents using UNLK compliant templates & features to eliminate re-entry.  ', icon: <LibraryBooksIcon /> },
        { title: 'Card 6', beforeHover: 'Export Documentation  ', afterHover: 'Create or generate a full set of export documents using UNLK compliant templates & features to eliminate re-entry. ', icon: <LibraryBooksIcon /> },
        { title: 'Card 7', beforeHover: 'Export Documentation ', afterHover: 'Create or generate a full set of export documents using UNLK compliant templates & features to eliminate re-entry. ', icon: <LibraryBooksIcon /> },
        { title: 'Card 8', beforeHover: 'Export Documentation  ', afterHover: 'Create or generate a full set of export documents using UNLK compliant templates & features to eliminate re-entry.  ', icon: <LibraryBooksIcon /> },
    ];

    return (
        <>
            {/* sx={{ fontWeight: "bold", mb: 4, fontSize: "2.5rem" }} */}
            <Box sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: "2.5rem" }}>
                <Typography sx={{ fontWeight: 'bold', }}>
                    A complete workspace for your
                </Typography>
                <Typography component="span" sx={{ fontWeight: "bold" }} >
                    exports team
                </Typography>
                <Typography component="span" sx={{ color: "#007BFF", fontWeight: "bold" }} >
                    - in the cloud.
                </Typography>
                <Typography sx={{ fontSize: "20px", fontWeight: "500", pt: 2, pb: 2 }}>
                    Teams no longer have to work across scattered systems &amp; struggle to
                    <br />
                    maintain various spreadsheets. It's all here, all-in-one.
                </Typography>
            </Box>
            <br />
            <br />
            <Container>
                <Grid container spacing={2}>
                    {cardData.map((card, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                            <MyCard
                                icon={card.icon}
                                beforeHover={card.beforeHover}
                                afterHover={card.afterHover}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
};

export default HomepagePlatform;
