import React, { useState } from "react";
import { Box, Grid, Button, Typography, Divider } from "@mui/material";
import { ClearRounded, SaveRounded, Add } from "@mui/icons-material";
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";

export default function CustomizedDialogs() {
    const [formData, setFormData] = useState({
        leadName: "",
        leadOwner: null,
        leadSource: null,
        contactName: null,
        clientCompany: "",
        leadStatus: "",
        stage: "",
        priority: "",
        lossReason: "",
        productType: "",
        expectedTimeline: "",
        productRadio: "0",
        leadDescription: "",
    });

    const [customFields, setCustomFields] = useState([{ value: "" }]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCustomFieldChange = (index, event) => {
        const newCustomFields = customFields.map((field, i) => {
            if (i === index) {
                return { ...field, value: event.target.value };
            }
            return field;
        });
        setCustomFields(newCustomFields);
    };

    const addCustomField = () => {
        setCustomFields([...customFields, { value: "" }]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form data:", formData);
        console.log("Custom fields:", customFields);
        // You can handle form submission logic here
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                        <Typography
                            sx={{ fontWeight: 600 }}
                            style={{ color: "#212529", fontSize: "18px" }}
                        >
                            Create Loading Photo
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Box style={{ display: "flex" }}>
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title="Save"
                                size="small"
                                variant="contained"
                            />
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title="Save and New"
                                size="small"
                                variant="contained"
                            />
                            <CustomButton
                                startIcon={<ClearRounded />}
                                title="Cancel"
                                size="small"
                                variant="contained"
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Divider />

                <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        {customFields.map((field, index) => (
                            <CustomTextField
                                type="file"
                                name={`customField${index}`}
                                fieldLabel={`Capture Upload ${index + 1}`}
                                value={field.value}
                                onChange={(event) => handleCustomFieldChange(index, event)}
                                required={true}
                            />
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <br />
                        <CustomButton
                            startIcon={<Add />}
                            onClick={addCustomField}
                            title="Add Photo"
                            size="small"
                            variant="contained"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomTextarea
                            name="country"
                            type="text"
                            fieldLabel="Note"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Write a Note"
                            sx={{
                                height: '100px !important',
                            }}
                        />
                    </Grid>
                </Grid>
            </form>
            <br />
        </>
    );
}

const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
];
