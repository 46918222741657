// src/components/LeadSources.js

import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import DataTable from "../../components/common/DataTable";
import { getLeadSource } from "../../services/CommonServices";

const title = "Lead Sources";

const LeadSources = () => {
    const [leadSources, setLeadSources] = useState([]);
    const columns = useMemo(
        () => [
            { accessorKey: 'id', header: 'SN', size: 50 },
            { accessorKey: 'title', header: 'Lead Source', size: 200 },
            { accessorKey: 'shortName', header: 'Short Name', size: 150 },
        ],
        []
    );


    const fetchLeadSources = async () => {
        const leadSourcesData = await getLeadSource();
        setLeadSources(leadSourcesData);
    };

    useEffect(() => {
        fetchLeadSources();
    }, []);

    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={leadSources}
                />
            </Box>
        </>
    );
};

export default LeadSources;
