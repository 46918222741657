import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import SubscriptionPlans from './Subscription';
import Company from '../components/AuthPages/Company';

const steps = ['Add Company', 'Complete Setup'];

const AddCompanyForm = ({ handleNext }) => {
  return (

    <Company handleNext={handleNext} />

  );
};

const SubscriptionOptions = () => {
  return (
    <Box>

      {/* <Card sx={{ boxShadow: 3 }}> */}
      {/* <CardContent> */}
      <SubscriptionPlans isFirstTimeLogin={true} />


      {/* </CardContent> */}

      {/* </Card> */}

      {/* <Card sx={{ boxShadow: 3 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Start Your 15-Day Free Trial
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Enjoy all the premium features with no cost for 15 days.
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" variant="contained">Start Trial</Button>
        </CardActions>
      </Card> */}

    </Box>
  );
};

const Finish = () => {
  return (
    <Box>

      <Card sx={{ boxShadow: 3 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Start Your 15-Day Free Trial
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Enjoy all the premium features with no cost for 15 days.
          </Typography>
        </CardContent>
        {/* <CardActions>
          <Button size="small" variant="contained">Start Trial</Button>
        </CardActions> */}
      </Card>

    </Box>
  );
};

const SetUp = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => steps.length;
  const completedSteps = () => Object.keys(completed).length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    setCompleted({ ...completed, [activeStep]: true });
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <AddCompanyForm handleNext={handleNext} />;
      case 1:
        return <SubscriptionOptions />;

    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <Card sx={{ width: '60%', boxShadow: 3 }}>
        <CardContent>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]} onClick={handleNext
              }>
                <StepButton color="inherit" >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>

          <div>
            {allStepsCompleted() ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>

                {renderStepContent(activeStep)}

              </React.Fragment>
            )}
          </div>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }} />
      </Card>
    </Box>
  );
};

export default SetUp;
