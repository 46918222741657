import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Divider, CircularProgress } from "@mui/material";
import { Card } from "@mui/material";
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import axios from "axios";
import CustomButton from "../../common/CustomButton";
import { Get_task_taskId, Update_task } from '../../../services/apiRoutes';

// Define the validation schema
const ValidationSchema = Yup.object().shape({
    taskOwnerId: Yup.number().required('Task Owner ID is required'),
    assignToId: Yup.number().required('Assign To ID is required'),
    contactId: Yup.number().required('Contact ID is required'),
    clientCompanyId: Yup.number().required('Client Company ID is required'),
    moduleId: Yup.number().required('Module ID is required'),
    moduleModelId: Yup.number().required('Module Model ID is required'),
    leadId: Yup.number().required('Lead ID is required'),
    taskName: Yup.string().required('Task Name is required'),
    subject: Yup.string().required('Subject is required'),
    dueDate: Yup.date().required('Due Date is required'),
    taskStatusId: Yup.number().required('Task Status ID is required'),
    priorityId: Yup.number().required('Priority ID is required'),
    description: Yup.string().required('Description is required'),
});

// Define a taskId if needed or pass it as a prop

const UpdateTasks = ({ handleClose, setMessage, taskId, fetchTaskData }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!taskId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(Get_task_taskId(taskId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.task);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [taskId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(Update_task(taskId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "call added successfully",
                    type: "success",
                });
                fetchTaskData()
                setTimeout(() => {
                    handleClose()
                }, 3000)
                handleClose()
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    taskOwnerId: data?.taskOwnerId || '',
                    assignToId: data?.assignToId || '',
                    contactId: data?.contactId || '',
                    clientCompanyId: data?.clientCompanyId || '',
                    moduleId: data?.moduleId || '',
                    moduleModelId: data?.moduleModelId || '',
                    leadId: data?.leadId || '',
                    taskName: data?.taskName || '',
                    subject: data?.subject || '',
                    dueDate: data?.dueDate || '',
                    taskStatusId: data?.taskStatusId || '',
                    priorityId: data?.priorityId || '',
                    description: data?.description || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={4} md={2}>
                                <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
                                    Update Task
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={10} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box style={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ px: "5%" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="taskOwnerId"
                                    required={true}
                                    fieldLabel="Task Owner"
                                    placeholder="Select"
                                    options={taskOwners}
                                    value={values.taskOwnerId}
                                    onChange={(event, newValue) => setFieldValue("taskOwnerId", newValue?.id || '')}
                                    error={touched.taskOwnerId && !!errors.taskOwnerId}
                                    helperText={touched.taskOwnerId && errors.taskOwnerId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="assignToId"
                                    fieldLabel="Assign To"
                                    required={true}
                                    options={assignees}
                                    placeholder="Select"
                                    value={values.assignToId}
                                    onChange={(event, newValue) => setFieldValue("assignToId", newValue?.id || '')}
                                    error={touched.assignToId && !!errors.assignToId}
                                    helperText={touched.assignToId && errors.assignToId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="contactId"
                                    fieldLabel="Select Contact Person"
                                    required={true}
                                    options={contacts}
                                    placeholder="Select"
                                    value={values.contactId}
                                    onChange={(event, newValue) => setFieldValue("contactId", newValue?.id || '')}
                                    error={touched.contactId && !!errors.contactId}
                                    helperText={touched.contactId && errors.contactId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="taskName"
                                    fieldLabel="Task"
                                    required={true}
                                    value={values.taskName}
                                    onChange={handleChange}
                                    error={touched.taskName && !!errors.taskName}
                                    helperText={touched.taskName && errors.taskName}
                                    placeholder="Enter Task"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <CustomTextField
                                    name="subject"
                                    fieldLabel="Subject"
                                    required
                                    value={values.subject}
                                    onChange={handleChange}
                                    error={touched.subject && !!errors.subject}
                                    helperText={touched.subject && errors.subject}
                                    placeholder="Enter Subject"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="dueDate"
                                    fieldLabel="Due Date"
                                    type="datetime-local"
                                    required
                                    value={values.dueDate}
                                    onChange={handleChange}
                                    error={touched.dueDate && !!errors.dueDate}
                                    helperText={touched.dueDate && errors.dueDate}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="taskStatusId"
                                    fieldLabel="Status"
                                    placeholder="Select "
                                    required
                                    options={statuses}
                                    value={values.taskStatusId}
                                    onChange={(event, newValue) => setFieldValue("taskStatusId", newValue?.id || '')}
                                    error={touched.taskStatusId && !!errors.taskStatusId}
                                    helperText={touched.taskStatusId && errors.taskStatusId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    placeholder="Select"
                                    name="priorityId"
                                    fieldLabel="Priority"
                                    options={priorities}
                                    value={values.priorityId}
                                    onChange={(event, newValue) => setFieldValue("priorityId", newValue?.id || '')}
                                    error={touched.priorityId && !!errors.priorityId}
                                    helperText={touched.priorityId && errors.priorityId}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <CustomTextarea
                                    sx={{ height: "100px !important" }}
                                    name="description"
                                    fieldLabel="Description"
                                    required
                                    value={values.description}
                                    onChange={handleChange}
                                    error={touched.description && !!errors.description}
                                    helperText={touched.description && errors.description}
                                    placeholder="Enter Description"
                                    rows={3}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
                                {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
                                {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
                                -------------------------------Not confirm Feild---------------------------
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    name="clientCompanyId"
                                    fieldLabel="Client Company"
                                    required
                                    options={companies}
                                    value={values.clientCompanyId}
                                    onChange={(event, newValue) => setFieldValue("clientCompanyId", newValue?.id || '')}
                                    error={touched.clientCompanyId && !!errors.clientCompanyId}
                                    helperText={touched.clientCompanyId && errors.clientCompanyId}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    name="moduleId"
                                    fieldLabel="Module"
                                    required
                                    options={modules}
                                    value={values.moduleId}
                                    onChange={(event, newValue) => setFieldValue("moduleId", newValue?.id || '')}
                                    error={touched.moduleId && !!errors.moduleId}
                                    helperText={touched.moduleId && errors.moduleId}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    name="moduleModelId"
                                    fieldLabel="Module Model"
                                    required
                                    options={moduleModels}
                                    value={values.moduleModelId}
                                    onChange={(event, newValue) => setFieldValue("moduleModelId", newValue?.id || '')}
                                    error={touched.moduleModelId && !!errors.moduleModelId}
                                    helperText={touched.moduleModelId && errors.moduleModelId}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    name="leadId"
                                    fieldLabel="Lead"
                                    required
                                    options={leads}
                                    value={values.leadId}
                                    onChange={(event, newValue) => setFieldValue("leadId", newValue?.id || '')}
                                    error={touched.leadId && !!errors.leadId}
                                    helperText={touched.leadId && errors.leadId}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateTasks;


const taskOwners = [
    { id: 1, label: 'Owner 1' },
    { id: 2, label: 'Owner 2' },
    // Add more task owners here
];

const assignees = [
    { id: 1, label: 'Assignee 1' },
    { id: 2, label: 'Assignee 2' },
    // Add more assignees here
];

const contacts = [
    { id: 1, label: 'Contact 1' },
    { id: 2, label: 'Contact 2' },
    // Add more contacts here
];

const companies = [
    { id: 1, label: 'Company 1' },
    { id: 2, label: 'Company 2' },
    // Add more companies here
];

const modules = [
    { id: 1, label: 'Module 1' },
    { id: 2, label: 'Module 2' },
    // Add more modules here
];

const moduleModels = [
    { id: 1, label: 'Module Model 1' },
    { id: 2, label: 'Module Model 2' },
    // Add more module models here
];

const leads = [
    { id: 1, label: 'Lead 1' },
    { id: 2, label: 'Lead 2' },
    // Add more leads here
];

const statuses = [
    { id: 1, label: 'Status 1' },
    { id: 2, label: 'Status 2' },
    // Add more statuses here
];

const priorities = [
    { id: 1, label: 'Priority 1' },
    { id: 2, label: 'Priority 2' },
    // Add more priorities here
];


const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
];



