import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField, FormLabel, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    height: "35px",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    padding: "0 0",
  },
}));

const CustomAutocomplete = ({
  options,
  getOptionLabel,
  value,
  onChange,
  fieldLabel,
  placeholder,
  required,
  style,
  ...props
}) => {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    // limit: 5,
  });
  return (
    <>
      <Box style={{ marginBottom: "2px" }}>
        <FormLabel style={fieldLabelstyle}>{fieldLabel}</FormLabel>
        <Autocomplete
          filterOptions={filterOptions}
          options={options}
          getOptionLabel={getOptionLabel}
          value={value}
          onChange={onChange}
          style={required ? { ...autocompleteform, ...requiredStyle, ...style } : autocompleteform}
          disableClearable={true}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              placeholder={placeholder}
              variant="outlined"
            />
          )}
          {...props}
        />
      </Box>
    </>
  );
};

export default CustomAutocomplete;
const autocompleteform = {
  width: "100%",
  border: "1px solid #CED4DA",
  borderRadius: "4px",
  //   paddingLeft: "10px",
  height: "35px",
  marginTop: "4px",
  fontFamily: "Poppins, sans-serif",
  color: "#495057",
  fontSize: "14px",
};
const requiredStyle = {
  borderLeft: "2px solid #F27B77",
}


const fieldLabelstyle = {
  color: "#212529",
  fontWeight: "600",
  fontSize: "14px"
};