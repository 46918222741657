import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Card, Grid, Typography, Divider, Container } from "@mui/material";
import CustomButton from "./CustomButton";
import {
  ClearRounded,
  SaveRounded,
  Replay10Rounded,
} from "@mui/icons-material";

const style = {
  position: "absolute",
  // top: "50%",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: "70%",
  bgcolor: "background.paper",
  border: 0,
  boxShadow: 24,
  // maxHeight: '95vh',
  height: '100%',
  // maxHeight: '100%',
  overflowY: "auto",
  // p: 2,

};
const cardStyle = {
  // padding: 2,
  // overflowY: 'auto',
  // flexGrow: 1,
  // maxHeight: '70vh',
  marginBottom: '10px',


};



const CustomModal = ({ open, children, handleClose, modalStyles }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      width={"xl"}
    >
      {/* <Box sx={style}> */}
      <Box sx={{
        position: "absolute",
        // top: "50%",
        left: "50%",
        transform: "translate(-50%, 0%)",
        width: "70%",
        bgcolor: "background.paper",
        border: 0,
        boxShadow: 24,
        // maxHeight: '95vh',
        height: '100%',
        // maxHeight: '100%',
        overflowY: "auto",
        // p: 2,
        ...modalStyles // Spread the custom styles here
      }}>

          {/* <Box >
            <Grid
              container
              spacing={1}
              sx={{ paddingBottom: 2 }}
            >
              <Grid item xs={4} md={2} >
                <Typography
                  style={{ color: "#212529", fontWeight: 500, fontSize: "20px" }}
                >
                  Create Lead
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                md={10}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box style={{ display: "flex" }}>
                  <CustomButton
                    startIcon={<ClearRounded />}
                    title="Cancel"
                    size="small"
                    variant="contained"
                    onClick={handleClose}
                  />
                  <CustomButton
                    startIcon={<SaveRounded />}
                    title=" Save and New"
                    size="small"
                    variant="contained"
                  />
                  <CustomButton
                    startIcon={<SaveRounded />}
                    title="Save"
                    size="small"
                    variant="contained"
                  />
                  <CustomButton
                    startIcon={<Replay10Rounded />}
                    title="Convert To Quotation"
                    size="small"
                    variant="contained"
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            &nbsp;
          </Box> */}
          <Container maxWidth="lg">
            <Box style={cardStyle}> {children}</Box>
          </Container>
      </Box>
    </Modal>
  );
};

export default CustomModal;
