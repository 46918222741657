// src/theme.js
import { createTheme } from '@mui/material/styles';
import '@fontsource/poppins'; // Import Poppins font

const theme = createTheme({
    typography: {
        fontFamily: "Poppins, sans-serif !important",
        fontSize: '14px',
    },

    link: {
        fontFamily: "Poppins, sans-serif !important",
        textDecoration: 'none',

    },
    MuiAppBar: {
        elevation4: {
            // backgroundColor: "red",
            // boxShadow: '0px 5px 5px -3px rgba(189, 195, 199,1.0), 0px 2px 10px 1px rgba(189, 195, 199,1.0)',
            boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)"
            // boxShadow: "0px 3px 3px 0px rgba(206, 214, 224,1.0)",

        },
        root: {
            boxShadow: "0 7px 30px -10px rgba(150,170,180,0.5)"
        },

    },
    MuiInput: {
        input: {
            height: 'auto',
            overflow: 'hidden',
            // backgroundColor: 'red',
            minHeight: '1.1876em',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
            border: '1px solid #e8eaef',
            borderRadius: '4px',
            height: '9px',
            marginTop: '1px',
            fontFamily: 'sans-serif',
            color: '#64728c',
            fontSize: '12px',
            padding: '10px 0px'
        },
        underline: {
            '&:before': {
                borderBottom: 'none', // Remove the bottom line
            },
            '&:after': {
                borderBottom: 'none', // Remove the bottom line
            },
            '&:hover': {
                '&:before': {
                    borderBottom: 'none', // Remove the bottom line on hover
                },
                '&:after': {
                    borderBottom: 'none', // Remove the bottom line on hover
                },
            },
        },
    },


});

export default theme;
