import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const Authmiddleware = ({ children }) => {
  const location = useLocation();
  const accessToken = sessionStorage.getItem("accessToken");

  if (!accessToken) {
    return (
      <Navigate
        to="/login"
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export default Authmiddleware;
