import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CustomModal from '../../../components/common/CustomModal';
import CreateShippingMark from '../CreateForm/CreateShippingMark';
import { GET_PRODUCTS_LIST, LIST_IMPORT_SHIPPING_MARKS } from '../../../services/apiRoutes';
import { AppBarMenu } from '../../../constants/DetailsPage';
import { useShipmentDetails } from './useShipmentDetails';
import { genericGet } from '../../../services/apiServices';
import { Main } from '../../../constants/DetailsMain';
import UpdateShippingMark from '../UpdateForm/UpdateShippingMark';
import MessageBox from '../../common/CustomMessageBox';
import { genericPost } from '../../../services/authServices';
import { getCommanImportShipmentId } from '../../../services/CommonServices';
import ShippingMarkView from '../ViewDetails/ShippingMarkView';
import CustomLoader from '../../common/CustomLoader';

const ShippingMark = () => {
    const [open, setOpen] = useState(true);
    const [viewModal, setViewModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const { importShipmentId } = useParams();
    const [products, setProducts] = useState([]);

    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [updateModal, setUpdateModal] = useState(false);
    const [shippingMarkId, setShippingMarkId] = useState('');

    const [activeTab, setActiveTab] = useState('Shipping Mark');
    const navigate = useNavigate();

    const { shipmentDetails, loading: shipmentLoading } = useShipmentDetails(importShipmentId);

    let supplierId, shipmentId, cifFob;

    const hasFetched = useRef(false);
    useEffect(() => {
        if (shipmentDetails) {
            supplierId = shipmentDetails.supplierId;
            shipmentId = shipmentDetails.shipmentId;
            cifFob = shipmentDetails.cifFob.cifFobName;
            fetchData();
            fetchProductsList();
            hasFetched.current = true;
        }
    }, [shipmentDetails, updateModal, shippingMarkId, modal]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                clientCompanyId: supplierId,
                commanShipmentId: getCommanImportShipmentId,

            };
            const response = await genericPost(LIST_IMPORT_SHIPPING_MARKS, payload, {
            });
            if (response.data.success && response.data.shippingmark) {
                const shippingMarkData = response.data.shippingmark.map((mark) => ({
                    shippingMarkId: mark.shippingMarkId || '',
                    companyId: mark.companyId || '',
                    BatchNo: mark.batchNo || '',
                    ProductId: mark.productsList.productName || '',
                    ShippingMark: mark.shippingMark || '',
                    createdAt: mark.createdAt || '',
                    updatedAt: mark.updatedAt || '',
                }));
                setData(shippingMarkData);
            } else {
                setError('No shipping marks found');
            }
        } catch (error) {
            setError('Error fetching shipping marks');
            console.error('Error fetching shipping marks', error);
        } finally {
            setLoading(false);
        }
    };




    const fetchProductsList = async () => {
        const payload = {
            clientCompanyId: supplierId,
        };

        try {
            const response = await genericPost(GET_PRODUCTS_LIST, payload, {

            });

            if (response.status === 200 && response.data.success) {
                const productData = response.data.productsList.map(product => ({
                    id: product.productsId,
                    title: product.productName
                }));
                console.log(productData);
                setProducts(productData);
            } else {
                console.error('Failed to fetch products list:', response.data);
            }
        } catch (error) {
            console.error('Error fetching products list:', error);
        }
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpenModal = () => {
        setModal(true);
    };

    const handleCloseModal = () => {
        setModal(false);
    };


    const handleViewClick = (id) => {
        handleOpenView(id);
    };
    const handleUpdateClick = (call) => {
        handleOpenUpdate(call);
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleBackClick = () => {
        navigate("/app/import/shipement");
    };

    const handleOpenUpdate = (shippingMarkId) => {
        setShippingMarkId(shippingMarkId); // Update state here
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setShippingMarkId(''); // Reset state here
    };

    const handleOpenView = (id) => {
        setShippingMarkId(id);  // Update state here
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setShippingMarkId(''); // Reset state here
    };

    if (shipmentLoading) {
        return <CustomLoader open={shipmentLoading} />;
    }


    const companyName = shipmentDetails.supplier.clientCompanyName;
    const phoneNumber = shipmentDetails.supplier.mobile;
    const clientCompanyId = shipmentDetails.supplierId;
    const initial = shipmentDetails.supplier.clientCompanyName ? shipmentDetails.supplier.clientCompanyName.charAt(0).toUpperCase() : '';

    const ShippingMarkCardColumns = [
        { accessor: 'shippingMarkId', label: 'SN', id: 'shippingMarkId' },
        { accessor: 'ProductId', label: 'Product Name', id: 'ProductId' },
        { accessor: 'ShippingMark', label: 'Shipping Mark', id: 'ShippingMark' },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleViewClick(row.shippingMarkId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.shippingMarkId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.shippingMarkId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];

    return (
        <div>
            <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={AppBarMenu(importShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />
            <Main open={open}>
                <DetailsPageCard
                    columns={ShippingMarkCardColumns}
                    title={'Shipping Mark'}
                    buttonTitle={'Create Shipping Mark'}
                    handleOpen={handleOpenModal}
                    data={data}
                    loading={loading}
                    error={error}
                />
                <CustomModal open={modal} handleClose={handleCloseModal}>
                    <CreateShippingMark
                        fetchData={fetchData}
                        productsList={products}
                        commanShipmentId={getCommanImportShipmentId}
                        clientCompanyId={clientCompanyId}
                        setMessage={setMessage}
                        handleClose={handleCloseModal}

                    />
                </CustomModal>

                <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateShippingMark
                        handleClose={handleCloseUpdate}
                        shippingMarkId={shippingMarkId}
                        productsList={products}
                        fetchData={fetchData}
                        setMessage={setMessage}

                    />
                </CustomModal>

                <CustomModal
                    modalStyles={{
                        maxHeight: '35vh',
                        top: "10%",
                    }}
                    open={viewModal}
                    handleClose={handleCloseView}>
                    <ShippingMarkView
                        handleClose={handleCloseView}
                        shippingMarkId={shippingMarkId}
                        productsList={products}
                        fetchData={fetchData}
                        setMessage={setMessage}
                    />
                </CustomModal>

                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />
            </Main>
        </div>
    );
};

export default ShippingMark;
