import { Box, Container, InputBase } from '@mui/material';
import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';

const BannerSearch = () => {
    const backgroundImageUrl = 'https://hrvers.com/static/media/Group%20856@2x.753b4fb7c6dc5919307e.png';
    // const backgroundImageUrl = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbZKWHiJuGVM1dTxB4xBU3uBj3vxKiXRjlJQ&s';

    const containerStyle = {
        width: '100%',
        height: 'auto',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const imageStyle = {
        width: '100%',
        height: '40vh',
        // objectFit: 'contain',
        objectFit: 'cover',
    };

    return (
        <>
            <div style={containerStyle}>
                <img src={backgroundImageUrl} alt="Banner" style={imageStyle} />
                <div style={{
                    position: 'absolute',
                    zIndex: 1, // Ensures the content is above the image
                    color: '#fff', // Text color for better visibility
                    textAlign: 'center',
                    width: '100%',
                }}>
                    <Container>
                        <form action="/en/" autoComplete="off">
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', mx: 'auto' }}>
                                <Box sx={{ position: 'relative', width: '100%' }}>
                                    <InputBase
                                        placeholder="Search for articles..."
                                        sx={{
                                            width: '100%',
                                            height: 50,
                                            borderRadius: 2,
                                            border: '1px solid #707070',
                                            backgroundColor: '#FFFF',
                                            padding: '12px 12px 12px 48px',
                                            fontSize: '1.125rem',
                                            color: '#707070',
                                            '&:hover': { backgroundColor: '#FFFF' },
                                            '&:focus': { backgroundColor: 'white', color: 'black', boxShadow: '0 0 4px rgba(0,0,0,0.3)' },
                                            '&::placeholder': { color: '#707070' }
                                        }}
                                    />
                                    <Box sx={{ position: 'absolute', top: '55%', left: 16, transform: 'translateY(-50%)', pointerEvents: 'none' }}>
                                        <SearchIcon sx={{ color: "#707070", fontSize: 25 }} />
                                    </Box>
                                </Box>
                            </Box>
                        </form>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default BannerSearch;
