import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton, CircularProgress } from "@mui/material";
import {
    SaveRounded,
} from "@mui/icons-material";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from "../common/CustomAutoComplete";
import CustomButton from "../common/CustomButton";
import { genericGet } from "../../services/apiServices"
import { GET_UNIT_LIST, CREATE_UNIT_LIST } from "../../services/apiRoutes";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { genericPost } from '../../services/authServices';
import MessageBox from "../common/CustomMessageBox";
import { getUnitQualityCode } from "../../services/CommonServices";


const title = "Unit Master";

const UnitValidationSchema = Yup.object().shape({
    unitType: Yup.number().required('Unit Type is required'),
    shortFormOfUnit: Yup.string().required('Short Form is required'),
    formalName: Yup.string().required('Formal Name is required'),
    unitQualityCode: Yup.number().required('Unit Quality Code is required'),
    noOfDecimalPlaces: Yup.number().min(0, 'Cannot be negative').required('No. of Decimal Places is required'),
});
const UnitMaster = () => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [unitMasterList, setUnitMasterList] = useState([]);
    const [unitQuality, setUnitQuality] = useState([]);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const columns = useMemo(
        () => [
            { accessorKey: 'unitId', header: 'SN', size: 50 },
            {
                accessorKey: 'unitType',
                header: 'Unit Type',
                size: 100,
                Cell: ({ row }) => {
                    const unitType = unitTypeOptions.find(option => option.id === row.original.unitType);
                    return unitType ? unitType.title : '';
                }
            },
            { accessorKey: 'shortFormOfUnit', header: 'Unit Name', size: 100 },
            { accessorKey: 'formalName', header: 'Formula Name', size: 100 },
            { accessorKey: 'unitQualityCode', header: 'Unit Quality Code (UQC)', size: 200 },
        ],
    );
    const getUnitMasterList = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_UNIT_LIST });
            if (response.data) {
                const unitData = response.data.unitMasters.map((unit, index) => ({
                    ...unit,
                    unitId: unit.unitId || '',
                    unitType: unit.unitType || '',
                    shortFormOfUnit: unit.shortFormOfUnit || '',
                    formalName: unit.formalName || '',
                    unitQualityCode: unit.unitQuality.title || '',
                    noOfDecimalPlaces: unit.noOfDecimalPlaces || 0,
                    createdAt: unit.createdAt || '',
                    updatedAt: unit.updatedAt || '',
                }));
                setUnitMasterList(unitData);
                setLoading(false);

            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching unit master list", error);
        }
    };
    const getCommonData = async () => {
        const unitQualityCode = await getUnitQualityCode();
        setUnitQuality(unitQualityCode);
    }
    useEffect(() => {
        getUnitMasterList()
        getCommonData()
    }, [])
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                unitType: values.unitType,
                shortFormOfUnit: values.shortFormOfUnit,
                formalName: values.formalName,
                unitQualityCode: values.unitQualityCode,
                noOfDecimalPlaces: values.noOfDecimalPlaces,
            };
            const response = await genericPost(CREATE_UNIT_LIST, payload);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Unit added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    getUnitMasterList();
                }, 1000);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };
    const [formData] = useState({
        unitType: '',
        shortFormOfUnit: '',
        formalName: '',
        unitQualityCode: '',
        noOfDecimalPlaces: "",
    });
    return (
        <>
            <Box sx={{ paddingLeft: "20px", paddingRight: "10px", paddingTop: "20px" }} >
                <Formik
                    initialValues={formData}
                    validationSchema={UnitValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, errors, touched, setFieldValue, values, handleChange }) => (
                        <Form>
                            <Grid container spacing={2} sx={{ px: "0%" }}>
                                <Grid item xs={12} md={2}>
                                    <CustomAutocomplete
                                        options={unitTypeOptions}
                                        fieldLabel="Unit Type"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={unitTypeOptions.find((option) => option.id === values.unitType) || null}
                                        onChange={(e, newValue) =>
                                            setFieldValue('unitType', newValue ? newValue.id : '')
                                        }
                                        placeholder="Enter Unit Type"
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CustomTextField
                                        placeholder="Enter Short Form"
                                        name="shortFormOfUnit"
                                        fieldLabel="Short Form of Unit"
                                        required
                                        value={values.shortFormOfUnit}
                                        onChange={handleChange}
                                        error={touched.shortFormOfUnit && !!errors.shortFormOfUnit}
                                        helperText={touched.shortFormOfUnit && errors.shortFormOfUnit}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CustomTextField
                                        placeholder="Enter Formal Name"
                                        name="formalName"
                                        fieldLabel="Formal Name"
                                        required
                                        value={values.formalName}
                                        onChange={handleChange}
                                        error={touched.formalName && !!errors.formalName}
                                        helperText={touched.formalName && errors.formalName}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CustomAutocomplete
                                        options={unitQuality}
                                        fieldLabel="Unit Quality Code (UQC)"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={unitQuality.find((option) => option.id === values.unitQualityCode) || null}
                                        onChange={(e, newValue) =>
                                            setFieldValue('unitQualityCode', newValue ? newValue.id : '')
                                        }
                                        placeholder="Unit Quality Code"
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <CustomTextField
                                        placeholder="Enter Decimal Places"
                                        name="noOfDecimalPlaces"
                                        fieldLabel="No. Of Decimal Places"
                                        required
                                        type="number"
                                        value={values.noOfDecimalPlaces}
                                        onChange={handleChange}
                                        error={touched.noOfDecimalPlaces && !!errors.noOfDecimalPlaces}
                                        helperText={touched.noOfDecimalPlaces && errors.noOfDecimalPlaces}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Box sx={{ pt: 3 }}>
                                        <CustomButton
                                            startIcon={<SaveRounded />}
                                            title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                            size="small"
                                            variant="contained"
                                            type="submit"
                                            disabled={isSubmitting}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>

            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={unitMasterList}
                />
            </Box>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
        </>
    );
};
export default UnitMaster;

const unitTypeOptions = [
    { title: "Simple", id: 1 },
];
