import React, { useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import CustomModal from "../../components/common/CustomModal";
import CreateLead from "../../components/Leads/CreateLead";
import CreateUserManagement from "./CreateMasters/CreateUserManagement";
const title = "Admin Login";

const UserManagement = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [leadModal, setLeadModal] = useState(false);
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5);

    const columns = useMemo(
        () => [
            { accessorKey: 'id', header: 'ID', size: 50 },
            { accessorKey: 'username', header: 'Username', size: 150 },
            { accessorKey: 'email', header: 'Email', size: 200 },
            { accessorKey: 'userLevel', header: 'User Level', size: 100 },
            { accessorKey: 'adminId', header: 'Admin ID', size: 100 },
            { accessorKey: 'creditAmount', header: 'Credit Amount', size: 100 },
            { accessorKey: 'expireDate', header: 'Expire Date', size: 150 },
            { accessorKey: 'mobile', header: 'Mobile', size: 120 },
            { accessorKey: 'companyName', header: 'Company Name', size: 180 },
            { accessorKey: 'companyId', header: 'Company ID', size: 100 },

            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton onClick={() => handleViewClick(row.original.sn)}>
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );

    const data = [
        {
            sn: "1",
            date: "23/03/2024",
            company: "KG 2",
            mobile: "37263274",
            leadSource: "New",
            leadOwner: "new",
            status: "In progress",
            priority: "low",
            lossReason: "low quality",
            createdBy: "KG",
        },
        {
            sn: "2",
            date: "23/03/2024",
            company: "AG",
            mobile: "37263274",
            leadSource: "AG pvt",
            leadOwner: "AG Pvt",
            status: "In progress",
            priority: "low",
            lossReason: "Transportation delayed",
            createdBy: "AG",
        },
    ];
    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false)
    }

    const handleViewClick = (id) => {
        navigate(`/app/leads/${id}`);
    };
    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                    handleOpen={handleOpen}
                    buttonTitle="Create Admin Login"
                />
                <CustomModal open={leadModal} handleClose={handleClose}>
                    <CreateUserManagement />
                </CustomModal>
            </Box>
        </>
    );
};
export default UserManagement;
