import React, { useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import { genericPost } from '../../../services/authServices';
import { Update_clientCompany_tax } from '../../../services/apiRoutes';
import axios from "axios";

const validationSchema = Yup.object().shape({
    clientCompanyId: Yup.number().required('clientCompanyId ID is required'),
    panNumber: Yup.string().required('panNumber is required'),
    iecNumber: Yup.string().required('iecNumber is required'),
    gstNumber: Yup.string().required('gstNumber is required'),
    note: Yup.string().required('Note is required'),
});

const UpdateCompanyInfo = ({ handleClose, setMessage, companyDetail, clientCompanyTax, clientCompanyId, tax, fetchCompanyTax }) => {


    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(Update_clientCompany_tax(clientCompanyId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Shipping Booking updated successfully",
                    type: "success",
                });
                fetchCompanyTax();
                setTimeout(() => {
                    handleClose();
                }, 500);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        clientCompanyId: clientCompanyTax,
        panNumber: tax.panNumber,
        iecNumber: tax.iecNumber,
        gstNumber: tax.gstNumber,
        note: tax.note,
    });

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid
                            container
                            spacing={1}
                            style={{ position: "sticky" }}
                        >
                            <Grid item xs={4} md={4} >
                                <Typography
                                    sx={{ fontWeight: 600 }}
                                    style={{ color: "#212529", fontSize: "18px", }}
                                >
                                    Update Company Tax
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={8}
                                md={8}
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                                <Box style={{ display: "flex" }}>

                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%" }}>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="text"
                                    name="panNumber"
                                    fieldLabel="PAN Number"
                                    required={true}
                                    value={values.panNumber}
                                    onChange={handleChange}
                                    error={touched.panNumber && !!errors.panNumber}
                                    helperText={touched.panNumber && errors.panNumber}
                                    placeholder="Enter PAN Number"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="text"
                                    name="iecNumber"
                                    fieldLabel="IEC Number"
                                    required={true}
                                    value={values.iecNumber}
                                    onChange={handleChange}
                                    error={touched.iecNumber && !!errors.iecNumber}
                                    helperText={touched.iecNumber && errors.iecNumber}
                                    placeholder="Enter IEC Number"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="text"
                                    name="gstNumber"
                                    fieldLabel="GST Number"
                                    required={true}
                                    value={values.gstNumber}
                                    onChange={handleChange}
                                    error={touched.gstNumber && !!errors.gstNumber}
                                    helperText={touched.gstNumber && errors.gstNumber}
                                    placeholder="Enter GST Number"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <CustomTextarea
                                    name="note"
                                    fieldLabel="Note"
                                    required={true}
                                    value={values.note}
                                    onChange={handleChange}
                                    error={touched.note && !!errors.note}
                                    helperText={touched.note && errors.note}
                                    placeholder="Enter Note"
                                />

                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateCompanyInfo;
