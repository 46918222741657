import { Box, Typography, Grid, CircularProgress, Divider, Card } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from "react";
import { READ_PURCHASE_ORDER } from '../../../services/apiRoutes';
import DetailsParticulars from '../../common/DetailsPage/DetailsParticulars';
import CustomCardView from '../../common/CustomCardView';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

const PurchaseOrderView = ({ handleClose, purchaseOrderId, productsList }) => {
    const [data, setData] = useState(null);
    const [dataParticular, setDataParticular] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalQty, setTotalQty] = useState(0); // State for total quantity
    const [totalAmount, setTotalAmount] = useState(0); // State for total rate
    const [amountData, setAmount] = useState(0);

    useEffect(() => {
        if (!purchaseOrderId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_PURCHASE_ORDER(purchaseOrderId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (response.data.success) {
                    const purchaseOrder = response.data.purchaseOrder;
                    const particulars = purchaseOrder.particulars.map(particular => ({
                        qty: particular.qty || 0,
                        amount: parseFloat(particular.amount) || 0,
                        rate: parseFloat(particular.rate).toFixed(2),
                        amounts: parseFloat(particular.amount).toFixed(2),
                        productName: particular.products.productName,
                        unitName: particular.productsUnit.unitName,
                    }));
                    const totalAmount = particulars.reduce((acc, item) => acc + item.amount, 0).toFixed(2);
                    const totalQuantity = particulars.reduce((acc, item) => acc + item.qty, 0);
                    setData(purchaseOrder);
                    setAmount(totalAmount);
                    setDataParticular(particulars);
                    setTotalQty(totalQuantity);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [purchaseOrderId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const particularsColumns = [
        {
            id: 'productName',
            label: 'Particular Name',
            accessor: 'productName',
        },
        {
            id: 'qty',
            label: 'Quantity',
            accessor: 'qty',
            styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'rate',
            label: 'Rate',
            accessor: 'rate',
            styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'unitName',
            label: 'Unit',
            accessor: 'unitName',
            styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'amounts',
            label: 'Amount',
            accessor: 'amounts',
            styleAccessor: {
                textAlign: 'right',
            },
        },

    ];


    const footerData = [
        {
            label: 'Total',
            footerValue: '',
            style: {
                textAlign: 'right',
            },
            colSpan: 1,

        },
        {
            label: '',
            footerValue: totalQty,
            style: {
                textAlign: 'center',

            },

        },
        {
            label: '',
            footerValue: amountData,
            style: {
                textAlign: 'right',

            },
            colSpan: 3,

        },
    ];

    const labels = [
        { key: 'supplier', label: "Supplier", sm: 12, md: 4, },
        { key: 'dueDate', label: "Due Date", sm: 12, md: 4, },
        { key: 'quotationNo', label: "Purchase Order No.", sm: 12, md: 4, },
        { key: 'contact', label: "Contact", sm: 12, md: 8, },
        { key: 'date', label: "Date", sm: 12, md: 4, },
        { key: 'reference', label: "Reference", sm: 12, md: 12, },

    ];

    const values = {
        supplier: data?.supplier?.clientCompanyName || '',
        reference: data?.reference || '',
        quotationNo: data?.quotationNo || '',
        date: data?.date || '',
        dueDate: data?.dueDate || '',
        note: data?.note || '',
        contact: `${data?.Contacts?.firstName || ''} ${data?.Contacts?.lastName || ''}`

    };


    return (
        <Box sx={{ mx: 'auto', mb: 0 }}>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
                        Purchase Order
                    </Typography>

                </Grid>
                <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Box>
                        <IconButton aria-label="delete">
                            <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                        </IconButton>
                        <IconButton aria-label="print">
                            <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                        </IconButton>
                        <IconButton aria-label="close" onClick={handleClose} >
                            <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Divider />

            <Grid container spacing={0} sx={{ px: "0%" }}>
                <Grid item xs={12} md={12} >
                    <CustomCardView
                        title="Bill To"
                        label={labels}
                        data={values}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                            Particulars
                        </Typography>
                    </Box>
                    <DetailsParticulars
                        title={'Particulars'}
                        columns={particularsColumns}
                        data={dataParticular}
                        footerData={footerData}
                    />
                </Grid>

                <Grid item xs={7}>
                    <br />
                    <Box>
                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                            In Words
                        </Typography>
                        <Typography style={{ color: "#212529", fontWeight: 400, fontSize: "14px", textTransform: "uppercase" }}>
                            Fifteen thousand four hundred forty one.
                            &nbsp;Only
                        </Typography>
                    </Box>
                    <Box sx={{ pt: 5 }}>
                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                            Note :
                        </Typography>
                        <Typography style={{ color: "#212529", fontWeight: 400, fontSize: "14px", textTransform: "capitalize" }}>
                            {values.note}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <br />
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                            Summary
                        </Typography>
                    </Box>
                    <Card sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 1, maxWidth: 400 }} variant="outlined">
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>Total Quantity:</Typography>
                            <Typography>{totalQty}</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>Amount:</Typography>
                            <Typography>₹ {amountData}</Typography>
                        </Box>

                        <Divider sx={{ my: 0 }} />

                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography fontWeight="bold">Grand Total:</Typography>
                            <Typography fontWeight="bold">₹ {amountData}</Typography>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box >
    );
};

export default PurchaseOrderView;
