import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DataTable from "../../components/common/DataTable";
import CustomModal from "../../components/common/CustomModal";
import { CREATE_CUSTOM_CLEARING_AGENCY, GET_CUSTOM_CLEARING_AGENCY_LIST } from "../../services/apiRoutes"
import { genericPost } from '../../services/authServices';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomLoader from "../../components/common/CustomLoader";
import MessageBox from "../../components/common/CustomMessageBox";
import { AuthContext } from "../../context/AuthContext";
import CreateManageProducts from "./CreateMasters/CreateManageProducts";
import { genericGet } from "../../services/apiServices";


import { CircularProgress } from "@mui/material";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
    SaveRounded,
} from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import CustomButton from "../common/CustomButton";
import CustomTextarea from "../common/CustomTextarea";
import { getCountries } from "../../services/CommonServices";
import CustomAutocomplete from "../common/CustomAutoComplete";


const title = "Custom Clearing Agency";
const ValidationSchema = Yup.object().shape({
    chaName: Yup.string().required('Company Name is required'),
    address: Yup.string().required('Address is required'),
    countryId: Yup.number().required('Country ID is required'),
    contactPerson: Yup.number().required('Contact Person is required'),
    phoneNo: Yup.string()
        .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
        .required('Phone number is required'),
    mobileNo: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Mobile number is required'),
    emailId: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    note: Yup.string().required('Note is required'),
});

const CustomClearingAgency = () => {
    const { token } = useContext(AuthContext)
    const [modal, setModal] = useState(false);
    const [conutries, setConutries] = useState([]);
    const [customClearingAgencyList, setCustomClearingAgencyList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);

    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })

    const columns = useMemo(
        () => [
            { accessorKey: "chaId", header: "SN.", size: 50 },
            { accessorKey: "chaName", header: "Agency Name", size: 50 },
            { accessorKey: "contactPerson", header: "Contact Person", size: 50 },
            { accessorKey: "phoneNo", header: "Phone No.", size: 50 },
            { accessorKey: "mobileNo", header: "Mobile No.", size: 50 },
            { accessorKey: "emailId", header: "Email", size: 50 },

            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <>
                        <IconButton
                        >
                            <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                        </IconButton>
                        <IconButton
                        >
                            <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                        </IconButton>
                    </>

                ),
            },

        ],
        []
    );


    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await genericGet({ url: GET_CUSTOM_CLEARING_AGENCY_LIST });
            if (response.data) {
                const CustumData = response.data.customClearingAgencyList.map((data, index) => ({
                    ...data,
                    chaId: data.chaId || '',

                }));
                setCustomClearingAgencyList(CustumData);
                setLoading(false)

            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error fetching ", error);
        }
    };


    const getCommonData = async () => {
        const getConutriesData = await getCountries()
        setConutries(getConutriesData)
    }


    useEffect(() => {
        fetchData()
        getCommonData()
    }, [token])

    const handleOpen = () => {
        setModal(true);
    };
    const handleClose = () => {
        setModal(false)
    }


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const payload = {
                chaName: values.chaName,
                address: values.address,
                countryId: values.countryId,
                contactPerson: values.contactPerson,
                phoneNo: values.phoneNo,
                mobileNo: values.mobileNo,
                emailId: values.emailId,
                note: values.note,
            };

            const response = await genericPost(CREATE_CUSTOM_CLEARING_AGENCY, payload);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                }, 1000);
                resetForm();
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        chaName: '',
        address: '',
        countryId: '',
        contactPerson: '',
        phoneNo: '',
        mobileNo: '',
        emailId: '',
        note: '',

    });

    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <Box sx={{ paddingLeft: "20px", paddingRight: "10px", paddingTop: "10px" }} >
                    <Formik
                        initialValues={formData}
                        validationSchema={ValidationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, errors, touched, setFieldValue, values, handleChange }) => (
                            <Form>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            placeholder="Enter Agency Name"
                                            name="chaName"
                                            fieldLabel="Agency Name"
                                            required
                                            value={values.chaName}
                                            onChange={handleChange}
                                            error={touched.chaName && !!errors.chaName}
                                            helperText={touched.chaName && errors.chaName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <CustomTextField
                                            placeholder="Enter Contact Person"
                                            name="contactPerson"
                                            fieldLabel="Contact Person"
                                            required
                                            value={values.contactPerson}
                                            onChange={handleChange}
                                            error={touched.contactPerson && !!errors.contactPerson}
                                            helperText={touched.contactPerson && errors.contactPerson}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <CustomTextField
                                            type="number"
                                            placeholder="Enter Mobile Number"
                                            name="mobileNo"
                                            fieldLabel="Mobile Number"
                                            required
                                            value={values.mobileNo}
                                            onChange={handleChange}
                                            error={touched.mobileNo && !!errors.mobileNo}
                                            helperText={touched.mobileNo && errors.mobileNo}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <CustomTextField
                                            type="email"
                                            placeholder="Enter Email"
                                            name="emailId"
                                            fieldLabel="Email"
                                            required
                                            value={values.emailId}
                                            onChange={handleChange}
                                            error={touched.emailId && !!errors.emailId}
                                            helperText={touched.emailId && errors.emailId}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <CustomTextField
                                            type="number"
                                            placeholder="Enter Phone Number"
                                            name="phoneNo"
                                            fieldLabel="Phone Number"
                                            required
                                            value={values.phoneNo}
                                            onChange={handleChange}
                                            error={touched.phoneNo && !!errors.phoneNo}
                                            helperText={touched.phoneNo && errors.phoneNo}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <CustomAutocomplete
                                            options={conutries}
                                            fieldLabel="Country"
                                            required={true}
                                            getOptionLabel={(option) => option.title}
                                            value={conutries.find((option) => option.id === values.countryId) || null}
                                            onChange={(e, newValue) => {
                                                setFieldValue('countryId', newValue ? newValue.id : '');
                                            }}
                                            placeholder="Select Country"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            placeholder="Enter Address"
                                            name="address"
                                            fieldLabel="Address"
                                            required
                                            value={values.address}
                                            onChange={handleChange}
                                            error={touched.address && !!errors.address}
                                            helperText={touched.address && errors.address}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <CustomTextarea
                                            multiline
                                            rows={4}
                                            placeholder="Enter Note"
                                            name="note"
                                            fieldLabel="Note"
                                            value={values.note}
                                            onChange={handleChange}
                                            error={touched.note && !!errors.note}
                                            helperText={touched.note && errors.note}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ pt: 3 }}>
                                            <CustomButton
                                                startIcon={<SaveRounded />}
                                                title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                                size="small"
                                                variant="contained"
                                                type="submit"
                                                disabled={isSubmitting}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Form>
                        )}
                    </Formik>
                </Box>
                <CustomLoader open={loading} />
                <DataTable
                    title={title}
                    columns={columns}
                    data={customClearingAgencyList}
                    handleOpen={handleOpen}
                />
                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />

            </Box>
        </>
    );
};
export default CustomClearingAgency;

