import axios from "axios";
import {
  BUSINESS_GROUP,
  INDUSTRY_TYPE,
  LEAD_SOURCE,
  LEAD_STATUSES,
  GET_RATING,
  GET_COMPANIES,
  GET_Countries,
  GET_COUNTRIES,
  GET_STATES,
  GET_CITY,
  GET_COMPANY_TYPES,
  GET_COMPANY_OWNERSHIP_TYPES,
  GET_CALL_PURPOSE_TYPES,
  GET_CALL_OUTCOME_TYPES,
  GET_PRIORITIES_LOSS_REASONS,
  GET_COMPANY_MODULES,
  GET_CONTACT_DEPARTMENTS,
  GET_CONTACT_GROUPS,
  GET_CONTACT_TYPES,
  GET_MODULES,
  GET_TASK_STATUSES,
  GET_LEAD_STAGES,
  GET_PRIORITIES,
  GET_CIF_FOB_LIST,
  GET_SHIPMENT_TYPES,
  GET_CURRENCY_LIST,
  GET_SUPPLIERS_LIST,
  GET_clientCompanies,
  GET_LEAD_OWNER,
  GET_CONTACT_PERSON_LIST,
  GET_SHIPPING_LINE,
  GET_FCL_LIST,
  GET_DIRECT_LIST,
  GET_FREIGHT_FORWARD_AGENCY_LIST,
  GET_CUSTOM_CLEARING_AGENCY_LIST,
  GET_PAYMENT_TYPE_LIST,
  GET_EXPENSE_LEDGER_LIST,
  GET_TITLES,
  GET_SHIPPING_STATUS,
  GET_UNIT_QUALITY_CODE,
  GET_UNIT_LIST,
  GET_VOUCHER
} from "./apiRoutes";
import { genericGet } from "./apiServices";

const getIndustryType = async () => {
  try {
    const response = await genericGet({ url: INDUSTRY_TYPE });
    if (response.data) {
      let newData = response.data?.industryTypes.map((data) => ({
        id: data.industryTypeId,
        title: data.industryTypeName,
      }));
      return newData || [];
    } else {
      return [];
    }
  } catch {
    return [];
  }
};

const getTitles = async () => {
  try {
    const response = await genericGet({ url: GET_TITLES });
    if (response.data) {
      let newData = response.data?.titles.map((data) => ({
        id: data.titleId,
        title: data.titleName,
      }));
      return newData || [];
    } else {
      return [];
    }
  } catch {
    return [];
  }
};

const getBusinessGroup = async () => {
  try {
    const response = await genericGet({ url: BUSINESS_GROUP });
    if (response.data) {
      let newData = response.data?.businessGroups.map((data) => ({
        id: data.businessGroupId,
        title: data.businessGroupName,
      }));
      return newData || [];
    } else {
      return [];
    }
  } catch {
    return [];
  }
};
const getLeadSource = async () => {
  try {
    const response = await genericGet({ url: LEAD_SOURCE });
    if (response.data) {
      let newData = response.data?.leadSources.map((data) => ({
        id: data.leadSourceId,
        title: data.leadSourceName,
      }));
      return newData || [];
    } else {
      return [];
    }
  } catch {
    return [];
  }
};

const getLeadStatus = async () => {
  try {
    const response = await genericGet({ url: LEAD_STATUSES });
    if (response.data) {
      let newData = response.data?.leadStatuses.map((data) => ({
        id: data.leadStatusId,
        title: data.leadStatusName,
      }));
      return newData || [];
    } else {
      return [];
    }
  } catch {
    return [];
  }
};

const getRating = async () => {
  try {
    const response = await genericGet({ url: GET_RATING });
    if (response.data) {
      let newData = response.data?.ratings.map((data) => ({
        id: data.ratingId,
        title: data.ratingName,
      }));
      return newData || [];
    } else {
      return [];
    }
  } catch {
    return [];
  }
};

const getCompanies = async () => {
  try {
    const response = await genericGet({ url: GET_COMPANIES });
    if (response.data && response.data.companies) {
      let newData = response.data.companies.map((data) => ({
        id: data.companyId,
        title: data.companyName,

      }));
      return newData || [];
    } else {
      return [];
    }
  } catch (error) {
    console.error("Failed to fetch companies:", error);
    return [];
  }
};


const getCountries = async () => {
  try {
    const response = await genericGet({ url: GET_COUNTRIES });
    if (response.data) {
      let newData = response.data?.country.map((data) => ({
        id: data.id,
        title: data.name,

      }));
      return newData || [];
    } else {
      return [];
    }
  } catch {
    return [];
  }
};

const getStates = async ({ id }) => {
  try {
    const response = await genericGet({ url: `${GET_STATES}/${id}/states` });
    if (response.data) {
      let newData = response.data?.states.map((data) => ({
        id: data.id,
        title: data.name,

      }));
      return newData || [];
    } else {
      return [];
    }
  } catch {
    return [];
  }
};

const getCities = async ({ id }) => {
  try {
    const response = await genericGet({ url: `${GET_CITY}/${id}/cities` });
    if (response.data) {
      let newData = response.data?.cities.map((data) => ({
        id: data.id,
        title: data.name,

      }));
      return newData || [];
    } else {
      return [];
    }
  } catch {
    return [];
  }
};

const getCompanyTypes = async () => {
  try {
    const response = await genericGet({ url: GET_COMPANY_TYPES });
    if (response.data && response.data.companyTypes) {
      const newData = response.data.companyTypes.map((data) => ({
        id: data.companyTypeId,
        title: data.companyTypeName,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching company types:", error);
    return [];
  }
};

const getCompanyOwnershipType = async () => {
  try {
    const response = await genericGet({ url: GET_COMPANY_OWNERSHIP_TYPES });
    if (response.data && response.data.ownershipTypes) {
      const newData = response.data.ownershipTypes.map((data) => ({
        id: data.ownershipTypeId,
        title: data.ownershipTypeName,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching company ownership types:", error);
    return [];
  }
};

const getCallPurposeType = async () => {
  try {
    const response = await genericGet({ url: GET_CALL_PURPOSE_TYPES });
    if (response.data && response.data.callPurposes) {
      const newData = response.data.callPurposes.map((data) => ({
        id: data.callPurposeId,
        title: data.callPurposeName,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching call purposes:", error);
    return [];
  }
};

const getCallOutcomeType = async () => {
  try {
    const response = await genericGet({ url: GET_CALL_OUTCOME_TYPES });
    if (response.data && response.data.callOutcomes) {
      const newData = response.data.callOutcomes.map((data) => ({
        id: data.callOutcomeId,
        title: data.callOutcomeName,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching call outcomes:", error);
    return [];
  }
};


const getPrioritiesLossReasons = async () => {
  try {
    const response = await genericGet({ url: GET_PRIORITIES_LOSS_REASONS });
    if (response.data && response.data.lossReasons) {
      const newData = response.data.lossReasons.map((data) => ({
        id: data.lossReasonId,
        title: data.lossReasonName,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching loss reasons:", error);
    return [];
  }
};

const getCompanyModules = async () => {
  try {
    const response = await genericGet({ url: GET_COMPANY_MODULES });
    if (response.data && response.data.companyModules) {
      const newData = response.data.companyModules.map((data) => ({
        // Assuming each module object has an id and title, replace these fields with actual keys
        id: data.moduleId,
        title: data.moduleName,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching company modules:", error);
    return [];
  }
};

const getContactDepartments = async () => {
  try {
    const response = await genericGet({ url: GET_CONTACT_DEPARTMENTS });
    if (response.data && response.data.contactDepartments) {
      const newData = response.data.contactDepartments.map((data) => ({
        id: data.contactDepartmentId,
        title: data.contactDepartmentName,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching contact departments:", error);
    return [];
  }
};

const getContactGroups = async () => {
  try {
    const response = await genericGet({ url: GET_CONTACT_GROUPS });
    if (response.data && response.data.contactGroups) {
      const newData = response.data.contactGroups.map((data) => ({
        id: data.contactGroupId,
        companyId: data.companyId,
        title: data.contactGroupName,
        priority: data.priority,
        status: data.status,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching contact groups:", error);
    return [];
  }
};

const getContactTypes = async () => {
  try {
    const response = await genericGet({ url: GET_CONTACT_TYPES });
    if (response.data && response.data.contactTypes) {
      const newData = response.data.contactTypes.map((data) => ({
        id: data.contactTypeId,
        companyId: data.companyId,
        title: data.contactTypeName,
        priority: data.priority,
        status: data.status,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching contact types:", error);
    return [];
  }
};

const getModules = async () => {
  try {
    const response = await genericGet({ url: GET_MODULES }); // Adjust the URL to your endpoint
    if (response.data && response.data.modules) {
      const newData = response.data.modules.map((module) => ({
        id: module.moduleId,
        title: module.moduleName,
        priority: module.priority,
        status: module.status,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching modules:", error);
    return [];
  }
};

const getTaskStatuses = async () => {
  try {
    const response = await genericGet({ url: GET_TASK_STATUSES });
    if (response.data && response.data.taskStatuses) {
      const newData = response.data.taskStatuses.map((status) => ({
        id: status.taskStatusId,
        title: status.taskStatusName,
        priority: status.priority,
        status: status.status,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching task statuses:", error);
    return [];
  }
};

const getLeadStages = async () => {
  try {
    const response = await genericGet({ url: GET_LEAD_STAGES });
    if (response.data && response.data.leadStages) {
      const newData = response.data.leadStages.map((stage) => ({
        id: stage.stageId,
        title: stage.stageName,
        priority: stage.priority,
        status: stage.status,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching lead stages:", error);
    return [];
  }
};

const getPriorities = async () => {
  try {
    const response = await genericGet({ url: GET_PRIORITIES });
    if (response.data && response.data.priorities) {
      const newData = response.data.priorities.map((priority) => ({
        id: priority.priorityId,
        title: priority.priorityName,
        priority: priority.priority,
        status: priority.status,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching priorities:", error);
    return [];
  }
};

const getCIFFOBList = async () => {
  try {
    const response = await genericGet({ url: GET_CIF_FOB_LIST });
    if (response.data && response.data.cifFobList) {
      const newData = response.data.cifFobList.map((cifFob) => ({
        id: cifFob.cifFobId,
        title: cifFob.cifFobName,
        priority: cifFob.priority,
        status: cifFob.status,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching CIF/FOB list:", error);
    return [];
  }
};


const getShipmentTypes = async () => {
  try {
    const response = await genericGet({ url: GET_SHIPMENT_TYPES });
    if (response.data && response.data.shipmentTypes) {
      const newData = response.data.shipmentTypes.map((shipmentType) => ({
        id: shipmentType.shipmentTypeId,
        title: shipmentType.shipmentTypeName,
        priority: shipmentType.priority,
        status: shipmentType.status,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching shipment types:", error);
    return [];
  }
};

const getCurrencyList = async () => {
  try {
    const response = await genericGet({ url: GET_CURRENCY_LIST });

    if (response.data && response.data.success && response.data.currencyList) {
      const currencyData = response.data.currencyList.map((currency) => ({
        id: currency.id,
        name: currency.name,
        // title: currency.currency,
        title: `${currency.name} ${currency.currency_symbol} ${currency.currency}`,
        currencyName: currency.currency_name,
        currencySymbol: currency.currency_symbol,
      }));
      return currencyData;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching currency list:', error);
    return [];
  }
};

const getSuppliersList = async () => {
  try {
    const authToken = sessionStorage.getItem('accessToken');
    const payload = {
      companyTypeId: 1,
    };

    const response = await axios.post(GET_SUPPLIERS_LIST, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.data && response.data.success && response.data.clientCompanies) {
      const suppliersData = response.data.clientCompanies.map((supplier) => ({
        id: supplier.clientCompanyId,
        title: supplier.clientCompanyName,

      }));
      return suppliersData;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching suppliers list:', error);
    return [];
  }
};

const getCustumersList = async () => {
  try {
    const authToken = sessionStorage.getItem('accessToken');
    const payload = {
      companyTypeId: 2,
    };

    const response = await axios.post(GET_SUPPLIERS_LIST, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (response.data && response.data.success && response.data.clientCompanies) {
      const suppliersData = response.data.clientCompanies.map((supplier) => ({
        id: supplier.clientCompanyId,
        title: supplier.clientCompanyName,
      }));
      return suppliersData;
    } else {
      return [];
    }
  } catch (error) {
    console.error('Error fetching suppliers list:', error);
    return [];
  }
};

const getContactPersonList = async () => {
  try {
    const response = await genericGet({ url: GET_CONTACT_PERSON_LIST });
    if (response.data && response.data.contacts) {
      const contactList = response.data.contacts.map((contact) => ({
        id: contact.contactId,
        title: `${contact.firstName} ${contact.lastName}`,
      }));
      return contactList;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching contact person list:", error);
    return [];
  }
};


const getClientCompany = async () => {
  try {
    const response = await genericGet({ url: GET_clientCompanies });
    if (response.data && response.data.clientCompanies) {
      const newData = response.data.clientCompanies.map((company) => ({
        id: company.clientCompanyId,
        title: company.clientCompanyName,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching client companies:", error);
    return [];
  }
};

const getOwner = async () => {
  try {
    const response = await genericGet({ url: GET_LEAD_OWNER });
    if (response.data && response.data.leadOwner) {
      const newData = response.data.leadOwner.map((contact) => ({
        id: contact.userId,
        title: `${contact.firstName} ${contact.lastName}`,
        email: contact.email,
        mobile: contact.mobile,
      }));
      return newData;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching lead owners:", error);
    return [];
  }
};


const getShippingLines = async () => {
  try {
    const response = await genericGet({ url: GET_SHIPPING_LINE });

    if (response.data && response.data.success && response.data.shippingLine) {
      const newShippingLines = response.data.shippingLine.map((line) => ({
        id: line.shippingLineId,
        title: line.shippingLineName,
        shortName: line.shortName,
        createdAt: line.createdAt,
        updatedAt: line.updatedAt,
      }));
      return newShippingLines;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching shipping lines:", error);
    return [];
  }
};
const getFCLData = async () => {
  try {
    const response = await genericGet({ url: GET_FCL_LIST });
    if (response.data && response.data.success && response.data.fcl) {
      const fclData = response.data.fcl.map((fcl) => ({
        id: fcl.fclId,
        title: fcl.fclName,
        shortName: fcl.shortName,
        createdAt: fcl.createdAt,
        updatedAt: fcl.updatedAt,
      }));
      return fclData;
    } else {
      console.error('Failed to fetch FCL data:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching FCL data:', error);
    return [];
  }
};

const getDirectListData = async () => {
  try {
    const response = await genericGet({ url: GET_DIRECT_LIST });
    if (response.data && response.data.success && response.data.directList) {
      const directListData = response.data.directList.map((direct) => ({
        id: direct.directId,
        title: direct.directName,
        priority: direct.priority,
        status: direct.status,
        createdAt: direct.createdAt,
        updatedAt: direct.updatedAt,
      }));
      return directListData;
    } else {
      console.error('Failed to fetch direct list data:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching direct list data:', error);
    return [];
  }
};

const getFreightForwardAgencyData = async () => {
  try {
    const response = await genericGet({ url: GET_FREIGHT_FORWARD_AGENCY_LIST });
    if (response.data && response.data.success && response.data.freightForwardAgency) {
      const freightForwardAgencyData = response.data.freightForwardAgency.map((agency) => ({
        id: agency.agencyId,
        title: agency.agencyName,
        address: agency.address,
        countryId: agency.countryId,
        contactPerson: agency.contactPerson,
        phoneNo: agency.phoneNo,
        mobileNo: agency.mobileNo,
        emailId: agency.emailId,
        note: agency.note,
        createdAt: agency.createdAt,
        updatedAt: agency.updatedAt,
      }));
      return freightForwardAgencyData;
    } else {
      console.error('Failed to fetch freight forward agency data:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching freight forward agency data:', error);
    return [];
  }
};

const getCustomClearingAgencyData = async () => {
  try {
    const response = await genericGet({ url: GET_CUSTOM_CLEARING_AGENCY_LIST });
    if (response.data && response.data.success && response.data.customClearingAgencyList) {
      const customClearingAgencyData = response.data.customClearingAgencyList.map((agency) => ({
        id: agency.chaId,
        title: agency.chaName,
        address: agency.address,
        countryId: agency.countryId,
        contactPerson: agency.contactPerson,
        phoneNo: agency.phoneNo,
        mobileNo: agency.mobileNo,
        emailId: agency.emailId,
        note: agency.note,
        createdAt: agency.createdAt,
        updatedAt: agency.updatedAt,
      }));
      return customClearingAgencyData;
    } else {
      console.error('Failed to fetch custom clearing agency data:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching custom clearing agency data:', error);
    return [];
  }
};

const getPaymentType = async () => {
  try {
    const response = await genericGet({ url: GET_PAYMENT_TYPE_LIST });
    if (response.data && response.data.success && response.data.paymentType) {
      const paymentTypeData = response.data.paymentType.map((payment) => ({
        id: payment.paymentTypeId,
        title: payment.paymentName,
        createdAt: payment.createdAt,
        updatedAt: payment.updatedAt,
      }));
      return paymentTypeData;
    } else {
      console.error('Failed to fetch payment type data:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching payment type data:', error);
    return [];
  }
};
const getExpenseLedgerList = async () => {
  try {
    const response = await genericGet({ url: GET_EXPENSE_LEDGER_LIST });

    if (response.data && response.data.success && response.data.expenseLedgerList) {
      const expenseLedgerData = response.data.expenseLedgerList.map((ledger) => ({
        id: ledger.expenseLedgerId,
        title: ledger.ledgerName,
        userId: ledger.userId,
        companyId: ledger.companyId,
        ledgerShortName: ledger.ledgerShortName,
      }));

      return expenseLedgerData;
    } else {
      console.error('Failed to fetch expense ledger data:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching expense ledger data:', error);
    return [];
  }
};


const getshippingStatus = async () => {
  try {
    const response = await genericGet({ url: GET_SHIPPING_STATUS });
    if (response.data) {
      let newData = response.data?.shippingStatus.map((data) => ({
        id: data.shippingStatusId,
        title: data.title,
      }));
      return newData || [];
    } else {
      return [];
    }
  } catch {
    return [];
  }
};

const getUnitQualityCode = async () => {
  try {
    const response = await genericGet({ url: GET_UNIT_QUALITY_CODE });
    if (response.data) {
      const newData = response.data.unitQualityCode.map((data) => ({
        id: data.id,
        title: data.title,
      }));
      return newData || [];
    } else {
      return [];
    }
  } catch {
    return [];
  }
};
const getUnitList = async () => {
  try {
    const response = await genericGet({ url: GET_UNIT_LIST });
    if (response.data && response.data.success && response.data.unitMasters) {
      const unitList = response.data.unitMasters.map((unit) => ({
        id: unit.unitId,
        title: unit.shortFormOfUnit,
        formalName: unit.formalName,
        unitQuality: unit.unitQuality?.title,
        noOfDecimalPlaces: unit.noOfDecimalPlaces,
      }));
      return unitList;
    } else {
      console.error('Failed to fetch unit list data:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching unit list data:', error);
    return [];
  }
};
const getVoucher = async () => {
  try {
    const response = await genericGet({ url: GET_VOUCHER });
    if (response.data && response.data.success && response.data.voucher) {
      const voucherList = response.data.voucher.map((voucher) => ({
        id: voucher.voucherId,
        title: voucher.voucherName,
      }));
      return voucherList;
    } else {
      console.error('Failed to fetch data:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};






const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

//Notes: comman data fetch and create to pass commanShipmentId-0 for import and 1 for export
const importShipmentId = 0;
const getCommanImportShipmentId = importShipmentId;

const exportShipmentId = 1;
const getCommanExportShipmentId = exportShipmentId;

export {
  getIndustryType,
  getBusinessGroup,
  getLeadSource,
  getLeadStatus,
  getCompanies,
  getRating,
  getCountries,
  getStates,
  getCities,
  getCompanyTypes,
  getCompanyOwnershipType,
  getCallPurposeType,
  getCallOutcomeType,
  getPrioritiesLossReasons,
  getCompanyModules,
  getContactDepartments,
  getContactGroups,
  getContactTypes,
  getModules,
  getTaskStatuses,
  getLeadStages,
  getPriorities,
  getCIFFOBList,
  getShipmentTypes,
  getCurrencyList,
  getSuppliersList,
  getCustumersList,
  getClientCompany,
  getContactPersonList,
  getOwner,
  getShippingLines,
  getFCLData,
  getDirectListData,
  getFreightForwardAgencyData,
  getCustomClearingAgencyData,
  getPaymentType,
  getExpenseLedgerList,
  formatDate,
  getCommanImportShipmentId,
  getCommanExportShipmentId,
  getTitles,
  getshippingStatus,
  getUnitQualityCode,
  getUnitList,
  getVoucher,
};


