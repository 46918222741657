import { Box, Typography, Grid, CircularProgress, Divider, Table, TableHead, TableRow, TableCell, TableBody, Card } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import { ClearRounded, } from "@mui/icons-material";
import CustomButton from "../../common/CustomButton";
import axios from 'axios';
import React, { useState, useEffect } from "react";
import { READ_COA_EXPORT } from '../../../services/apiRoutes';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CustomCardView from '../../common/CustomCardView';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import DetailsParticulars from '../../common/DetailsPage/DetailsParticulars';



const COAView = ({ handleClose, setMessage, fetchData, coaId, companyName, phoneNumber }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch data for the commercial invoice
    useEffect(() => {
        if (!coaId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_COA_EXPORT(coaId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [coaId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;
    // Initialize form values
    const values = {
        coaId: data?.coaId || '',
        userId: data?.userId || '',
        companyId: data?.companyId || '',
        clientCompanyId: data?.clientCompanyId || '',
        toAddress: data?.toAddress || '',
        invoiceNo: data?.invoiceNo || '',
        date: data?.date || '',
        loadingPort: data?.loadingPort || '',
        destinationPort: data?.destinationPort || '',
        productName: data?.product?.productName || '',
        conculation: data?.conculation || '',
        analyst: data?.analyst || '',
        examiner: data?.examiner || '',
        approved: data?.approved || '',
        casNo: data?.casNo || '',
        iupac: data?.iupac || '',
        particulars: data?.particulars || [],
    };

    const labels = [
        { key: 'toAddress', label: 'To', sm: 12, md: 6, },
        { key: 'invoiceNo', label: 'Invoice No', sm: 12, md: 3, },
        { key: 'date', label: 'Date', sm: 12, md: 3, },
        { key: 'loadingPort', label: 'Loading Port', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'destinationPort', label: 'Destination Port', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'productName', label: 'Product Name', sm: 12, md: 6, sx: { pt: 2 } },
    ];
    const labelsFooter = [
        { key: 'analyst', label: 'Analyst', sm: 12, md: 6, },
        { key: 'examiner', label: 'Examiner', sm: 12, md: 6, },
        { key: 'casNo', label: 'CAS No', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'iupac', label: 'IUPAC', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'approved', label: 'Approved', sm: 12, md: 6, sx: { pt: 2 } },
        { key: 'conculation', label: 'Conculation', sm: 12, md: 7, sx: { pt: 2 } },
    ];

    const particularsColumns = [
        { id: 'items', label: 'Items', accessor: 'items' },
        { id: 'standard', label: 'Standard', accessor: 'standard' },
        { id: 'result', label: 'Result', accessor: 'result' },
    ];
    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik initialValues={values} enableReinitialize>
                {() => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#212529" }}>
                                    COA
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>
                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="print">
                                        <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close" onClick={handleClose} >
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                                    <Typography style={{ color: "#212529", fontWeight: 500, fontSize: "15px", textTransform: "uppercase" }}>
                                        Certification Of Analysis
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <CustomCardView label={labels} data={values} />
                            </Grid>

                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                        Particulars
                                    </Typography>
                                </Box>
                                <DetailsParticulars
                                    title={'Particulars'}
                                    columns={particularsColumns}
                                    data={data?.particulars}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <CustomCardView label={labelsFooter} data={values} />
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default COAView;