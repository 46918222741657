
import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Grid,
    CircularProgress,
    Divider,
} from '@mui/material';
import { Formik, Form } from 'formik';
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import { ClearRounded } from "@mui/icons-material";
import { READ_IMPORT_PAYMENT_VOUCHER } from '../../../services/apiRoutes';
import CustomCardView from "../../common/CustomCardView";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

const PaymentVoucherView = ({ handleClose, paymentVoucherId }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!paymentVoucherId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_IMPORT_PAYMENT_VOUCHER(paymentVoucherId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.paymentVoucher);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [paymentVoucherId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const labels = [
        { key: 'paymentVoucherNo', label: "Voucher No", sm: 12, md: 6, },
        { key: 'date', label: "Date", sm: 12, md: 6, },
        { key: 'amount', label: "Amount", sm: 12, md: 6, },
        { key: 'paymentTypeId', label: "Payment Type", sm: 12, md: 6, },
        { key: 'currencyId', label: "Currency", sm: 12, md: 6, },
        { key: 'baseCurrencyId', label: "Base Currency", sm: 12, md: 6, },
        { key: 'conversionRate', label: "Conversion Rate", sm: 12, md: 6, },
        { key: 'amountInBaseCurrency', label: "Amount in Base Currency", sm: 12, md: 6, },
        { key: 'bankCharges', label: "Bank Charges", sm: 12, md: 6, },
        { key: 'note', label: "Note", sm: 12, md: 12, },
    ];

    const values = {
        date: data?.date,
        paymentVoucherNo: data?.paymentVoucherNo,
        paymentTypeId: data?.paymentType?.paymentName,
        amount: data?.amount,
        currencyId: data?.currency?.currency,
        baseCurrencyId: data?.baseCurrency?.currency,
        conversionRate: data?.conversionRate,
        amountInBaseCurrency: data?.amountInBaseCurrency,
        bankCharges: data?.bankCharges,
        note: data?.note,
    };



    return (
        <Box sx={{ mx: 'auto', mt: 0, }}>
            <Formik
                initialValues={values}
                enableReinitialize
            >
                {() => (
                    <Form>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600, fontSize: "18px", paddingTop: "5px" }}>
                                    Payment Voucher
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>

                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="print">
                                        <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close">
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} onClick={handleClose} />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "0%" }}>
                            <Grid item xs={12}>
                                <CustomCardView label={labels} data={values} />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default PaymentVoucherView;
