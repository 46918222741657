import React from 'react';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
const CustomCardView = ({ title, label, data, icon }) => {

    const tittilestyle = {
        fontWeight: 700,
        fontSize: '16px',
        // color: '#007BFF',
        textTransform: 'capitalize',
        mb: 1,
    }

    const labelStyle = {
        fontWeight: 300,
        textAlign: 'left',
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
    };
    const dataStyle = {
        fontWeight: 'bold',
        textAlign: 'left',
        ml: 1,
        mr: 1,
        textTransform: 'capitalize',


    };
    const dessStyle = {
        fontWeight: 300,
        textAlign: 'left',
        ml: 1
    };
    const bodyStyle = {
        overflowX: 'auto',
    };

    return (
        <Box
            sx={bodyStyle}
        >
            <Box style={{ padding: "0px" }} sx={{ minWidth: 300, mt: 0, }} variant="outlined">
                {/* <CardContent> */}
                <Typography
                    variant="h6"
                    sx={tittilestyle}
                >
                    {title}
                </Typography>
                <Grid container spacing={0}

                >
                    {label.map((item, index) => (
                        <Grid
                            item
                            sx={{
                                ...item.sx
                            }}
                            xs={12}
                            sm={item.sm || 12}
                            md={item.md || 12}
                            key={index}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    mb: 1,
                                    alignItems: 'center',
                                }}
                                key={index}
                            >

                                <Typography
                                    display="flex" alignItems="center"
                                    variant="h5"
                                    sx={labelStyle}>
                                    {item.icon && <item.icon style={{ fontSize: "20px", marginRight: "2px", marginBottom: "4px" }} />}
                                    {item.label}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    sx={dessStyle}
                                >
                                    :
                                </Typography>
                                <Typography
                                    variant="h5"
                                    sx={dataStyle}
                                >
                                    {data[item.key] || 'N/A'}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                {/* </CardContent> */}
            </Box>
        </Box>
    );
};

export default CustomCardView;


