import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Logo from '../../assets/images/HRVERSE_logo.svg';
import { Typography } from '@mui/material';

const images = [
    {
        label: 'Integrated HRM',
        paragraph: "HRVERSE is an integrated HR platform to seamlessly carry out HR functions digitally for any organization be it startup or large business conglomerate. HRVERSE ensures easy, simple yet quick day-to-day HR task and employee management for optimum productivity. With a unique user experience HRVERSE offers comprehensive and secured Human Resource Management Universe customized for your industry.",
        imgPath: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpZnvG6fy-m6HLMd5ok9EaGAbxA7DraNuW7Q&s"
    },
    {
        label: 'All-In-One HR Universe',
        paragraph: "HRVERSE offers various HR management functions in simple and robust manner such as Employee Onboarding, Document Management, Attendance Management, Leave Management, Exit Process, Salary Generation, Payroll, Asset Management, Employee Expenses management along with dedicated dashboards for employees for instant salary slips, leave records, profiles etc.",
        imgPath: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpZnvG6fy-m6HLMd5ok9EaGAbxA7DraNuW7Q&s"
    },
    {
        label: 'Empowering HRD',
        paragraph: "We have created HRVERSE with an objective of empowering human resource departments with necessary tools, data & insights for faster decision making and reliability. It is convenient, easy and user friendly for efficiently managing employee data, employee records, employee documents along with entire human resource process from Employee on boarding to exit formalities",
        imgPath: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpZnvG6fy-m6HLMd5ok9EaGAbxA7DraNuW7Q&s"
    },
];

const Containercarousel = () => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
    };

    React.useEffect(() => {
        const timer = setInterval(() => {
            handleNext();
        }, 3000);
        return () => clearInterval(timer);
    }, []);

    return (
        <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
            <Container sx={{ textAlign: 'center', mt: 4, mb: 4, ml: 5 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <img
                        style={{
                            width: '15%',
                            height: '15%',
                            objectFit: 'contain',
                            marginBottom: "8px"
                        }}
                        src={Logo}
                        alt="Logo"
                    />
                    <Typography
                        variant="h1"
                        sx={{
                            ml: 2,
                            mt: 0,
                            fontSize: '2.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        At a Glance
                    </Typography>
                </Box>
            </Container>
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6} sm={6}>
                        <img
                            style={{ width: "80%", height: "90%", objectFit: "contain" }}
                            src={images[activeStep].imgPath}
                            alt={images[activeStep].label}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                        <h1 className='heading-nutshel'>{images[activeStep].label}</h1>
                        <p className='hiringparagraph'>{images[activeStep].paragraph}</p>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}
                        style={{ marginTop: "-25px" }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                // position: 'absolute',
                                // mb: 16,
                                // marginLeft: "50px"
                            }}
                        >
                            <MobileStepper
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                nextButton={
                                    <Button
                                        size="small"
                                        onClick={handleNext}
                                        disabled={activeStep === maxSteps - 1}
                                    >
                                        {theme.direction === 'rtl' ? (
                                            <ArrowBackOutlinedIcon style={{ fontSize: "40px", color: "#1C6BFE" }} />
                                        ) : (
                                            <ArrowForwardOutlinedIcon style={{ fontSize: "40px", color: "#1C6BFE" }} />
                                        )}
                                    </Button>
                                }
                                backButton={
                                    <Button
                                        size="small"
                                        onClick={handleBack}
                                        disabled={activeStep === 0}
                                    >
                                        {theme.direction === 'rtl' ? (
                                            <ArrowForwardOutlinedIcon style={{ fontSize: "40px", color: "#1C6BFE" }} />
                                        ) : (
                                            <ArrowBackOutlinedIcon style={{ fontSize: "40px", color: "#1C6BFE" }} />
                                        )}
                                    </Button>
                                }
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default Containercarousel;
