import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import { Box, Button, Grid, Typography } from '@mui/material';
import CustomButton from '../../common/CustomButton';
import { AddCircle, Backup, AddCircleOutline } from "@mui/icons-material";

// Styles for the components
const styles = {
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: "16px",
        color: "#212529",
        fontWeight: "600",
        textTransform: 'capitalize',
    },
    expandIcon: {
        color: '#212529',
    },
};

const labelStyle = {
    fontWeight: 400,
    // minWidth: '100px',
    textAlign: 'left',
    textTransform: 'capitalize',
    color: "rgb(108, 117, 125);",
    fontSize: "16px",
};

const dataStyle = {
    textAlign: 'left',
    ml: 1,
    // mr: 2,
    textTransform: 'capitalize',
    fontSize: "16px",
};

const dessStyle = {
    fontWeight: 300,
    textAlign: 'left',
    ml: 1,
};

const bodyStyle = {
    overflowX: 'auto',
};

const ClientCompanyDetail = ({ data, buttonTitle, handleOpen }) => {
    return (
        <div>
            {data.map((item, index) => (
                <Accordion key={index} defaultExpanded={item.defaultExpanded} sx={{ borderRadius: '8px', boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <AccordionSummary
                        style={styles.title}
                        expandIcon={<ExpandMoreIcon style={styles.expandIcon} />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}>
                        {item.title}
                    </AccordionSummary>
                    <Box >
                        < Divider />
                        <Box sx={bodyStyle}>
                            <CardContent>
                                <Grid container spacing={1}>
                                    {item.details.map((detail, i) => (
                                        <Grid key={i} item xs={12} sm={detail.sm} md={detail.md}>
                                            <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                                                <Typography variant="h5" sx={labelStyle}>
                                                    {detail.label}
                                                </Typography>
                                                <Typography variant="h5" sx={dessStyle}>-</Typography>
                                                <Typography variant="h5" sx={dataStyle}>{detail.value}</Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                                    <CustomButton
                                        startIcon={<Backup />}
                                        title={buttonTitle}
                                        size="small"
                                        variant="contained"
                                        onClick={handleOpen}
                                        sx={{ minWidth: 'auto', whiteSpace: 'nowrap' }}

                                    />
                                </Box>
                            </CardContent>
                        </Box>

                    </Box>
                </Accordion>
            ))
            }
        </div >
    );
};

export default ClientCompanyDetail;
