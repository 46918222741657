// LeadDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DetailPageCustum from '../../common/DetailsPage/DetailPageCustum';
import { GET_Lead_leadId } from '../../../services/apiRoutes';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
const LeadDetail = () => {
    const { leadId } = useParams();
    const [leadDetails, setLeadDetails] = useState(null);

    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');

        axios.get(GET_Lead_leadId(leadId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setLeadDetails(response.data.lead);
            })
            .catch(error => {
                console.error('Error fetching lead details:', error);
            });
    }, [leadId]);

    if (!leadDetails) {
        return <p>Loading...</p>;
    }


    const tableData = [
        { label: 'Client Company', value: leadDetails.clientCompany?.clientCompanyName || 'N/A' },
        { label: 'Contact Person', value: `${leadDetails?.title || 'N/A'} ${leadDetails?.firstName || 'N/A'} ${leadDetails?.lastName || ''}` },
        { label: 'Email', value: leadDetails.email || 'N/A' },
        { label: 'Mobile', value: leadDetails.mobile || 'N/A' },
        { label: 'Address', value: `${leadDetails?.street || ''}, ${leadDetails.city?.name || ''}, ${leadDetails.state?.name || ''}, ${leadDetails.country?.name || ''}, ${leadDetails.zipCode || ''}.` },
    ];

    return <DetailPageCustum title="Lead Details" data={tableData} />;
};

export default LeadDetail;
