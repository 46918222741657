import React from 'react';
import { TextareaAutosize, FormLabel, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
    width: '100%',
    border: '1px solid #CED4DA',
    borderRadius: '4px',
    padding: '10px',
    fontFamily: 'Poppins, sans-serif !important',
    color: '#495057',
    fontSize: '14px',
    '&:focus': {
        outline: 'none',
        borderColor: '#80bdff',
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
}));

const CustomTextarea = ({ name, value, onChange, fieldLabel, required, placeholder, rows = 4, ...props }) => {
    return (
        <Box style={{ marginBottom: '2px' }}>
            <FormLabel style={fieldLabelstyle}>{fieldLabel}</FormLabel>
            <StyledTextarea
                name={name}
                value={value || ''}
                onChange={onChange}
                placeholder={placeholder}
                rows={rows}
                style={required ? { ...style, ...requiredStyle } : style}
                {...props}
            />
        </Box>
    );
};

export default CustomTextarea;

const style = {
    width: '100%',
    border: '1px solid #CED4DA',
    borderRadius: '4px',
    padding: '10px',
    fontFamily: 'Poppins, sans-serif !important',
    color: '#495057',
    fontSize: '14px',
    marginTop: '4px',
};

const requiredStyle = {
    borderLeft: "2px solid #F27B77",
};

const fieldLabelstyle = {
    color: "#212529",
    fontWeight: "600",
    fontSize: "14px"
};
