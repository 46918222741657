// src/components/LeadStatuses.js

import React, { useEffect, useMemo, useState } from "react";
import DataTable from "../../components/common/DataTable";
import { getLeadStatus } from "../../services/CommonServices";
import { Box } from "@mui/material";
const title = "Lead Statuses";
const LeadStatuses = () => {
    const [leadStatuses, setLeadStatuses] = useState([]);
    const columns = useMemo(
        () => [
            { accessorKey: 'id', header: 'SN', size: 50 },
            { accessorKey: 'title', header: 'Lead Status', size: 200 },
            { accessorKey: 'shortName', header: 'Short Name', size: 150 },
        ],
        []
    );


    const fetchLeadStatuses = async () => {
        const leadStatusesData = await getLeadStatus();
        setLeadStatuses(leadStatusesData);
    };

    useEffect(() => {
        fetchLeadStatuses();
    }, []);

    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={leadStatuses}
                />
            </Box>
        </>
    );
};

export default LeadStatuses;
