import React, { useState, useContext } from 'react';
import { Box, Grid, Card, Typography, Tabs, Tab, CircularProgress } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../components/common/CustomButton';
import CustomTextField from '../components/common/CustomTextField';
import { genericPost } from '../services/authServices';
import { LOGIN_PATH } from '../services/apiRoutes';
import MessageBox from '../components/common/CustomMessageBox'
import { useNavigate } from 'react-router-dom';
import Register from '../components/AuthPages/Register';
import { AuthContext } from '../context/AuthContext';
const loginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
});

export default function AuthTabs() {
    const { login } = useContext(AuthContext)
    const [tabValue, setTabValue] = useState('login');
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })
    // Handle tab change
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const navigate = useNavigate()
    // Handle form submission
    const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            const response = await genericPost(LOGIN_PATH, values);
            if (response.status == 200) {

                let accessToken = response.data.accessToken
                let authUser = response.data.authUser && JSON.stringify(response.data.authUser)
                sessionStorage.setItem('authUser', authUser)
                sessionStorage.setItem('accessToken', accessToken)
                login(accessToken)
                setTimeout(() => {
                    if (response.data.firstTimeLogin) {
                        navigate('/add/company')
                    } else {
                        navigate('/app/dashboard')
                    }
                }, 1500)
            } else {
                let errorMessage = response.error.message
                setMessage({
                    open: true,
                    text: errorMessage || 'Something went wrong',
                    type: 'error'
                })
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error'
            })
        } finally {
            // setSubmitting(false);
        }
    };

    return (
        <Box sx={{
            flexGrow: 1,
            p: 0,
            backgroundColor: "rgb(77, 184, 255)",
            height: '100vh',
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                        p: 2,
                        borderRadius: "0px 40% 30px 0px",
                        backgroundImage: "linear-gradient(to bottom right, #FFFFFF, #FFFFFF)",
                        color: 'black'
                    }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography gutterBottom style={{ color: "#1C6BFE", fontSize: "60px" }}>
                                EXIM CRM
                            </Typography>
                            <img
                                src="https://media.istockphoto.com/id/1281150061/vector/register-account-submit-access-login-password-username-internet-online-website-concept.jpg?s=612x612&w=0&k=20&c=9HWSuA9IaU4o-CK6fALBS5eaO1ubnsM08EOYwgbwGBo="
                                alt="logo"
                                style={{
                                    width: '90%',
                                    height: 'auto',
                                    maxWidth: '100%',
                                    display: 'block',
                                    objectFit: "cover",
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                    <Card variant='outlined' style={{ marginTop: "20%", marginRight: "20px", marginLeft: "10px" }}>
                        <Box sx={{ textAlign: 'center', p: 2, width: '100%' }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                textColor="primary"
                                indicatorColor="primary"
                                aria-label="auth tabs"
                            >
                                <Tab value="login" label="Login" sx={{ fontWeight: "600" }} />
                                <Tab value="register" label="New User" sx={{ fontWeight: "600" }} />
                            </Tabs>

                            {tabValue == 'register' ? <Register setTabValue={setTabValue} /> : <Formik
                                initialValues={{ email: '', password: '' }}
                                validationSchema={loginSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <Box sx={{ mt: 2, color: "#404040" }}>
                                            <Typography variant="h6" sx={{ fontSize: "35px", fontWeight: "600", lineHeight: 1 }}>
                                                Welcome!
                                            </Typography>
                                            <Typography variant="h6" sx={{ fontSize: "25px", fontWeight: "500" }}>
                                                {tabValue === 'login' ? 'Login to your account' : 'Register your account'}
                                            </Typography>

                                            <Box sx={{ mb: 2 }}>
                                                <Field
                                                    as={CustomTextField}
                                                    type='text'
                                                    name='email'
                                                    placeholder="Enter Email"
                                                    fullWidth
                                                    error={<ErrorMessage name="email" />}
                                                    helperText={<ErrorMessage name="email" />}
                                                />
                                            </Box>
                                            <Box sx={{ mb: 2 }}>
                                                <Field
                                                    as={CustomTextField}
                                                    type='password'
                                                    name='password'
                                                    placeholder="Enter Password"
                                                    fullWidth
                                                    error={<ErrorMessage name="password" />}
                                                    helperText={<ErrorMessage name="password" />}
                                                />
                                            </Box>
                                            <CustomButton
                                                variant="contained"
                                                color="secondary"
                                                title={
                                                    isSubmitting ? (
                                                        <>
                                                            <CircularProgress size={20} color="inherit" />
                                                            <span style={{ marginLeft: 10 }}>
                                                                {tabValue === 'login' ? 'Login' : 'Registering...'}
                                                            </span>
                                                        </>
                                                    ) : (
                                                        tabValue === 'login' ? 'Login' : 'Register'
                                                    )
                                                }
                                                type="submit"
                                                disabled={isSubmitting}
                                            />
                                        </Box>
                                    </Form>
                                )}
                            </Formik>}
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                type={message.type}
            />
        </Box>
    );
}
