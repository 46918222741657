import { Box, Typography, Grid, CircularProgress, Divider, Card } from '@mui/material';
import { Formik, Form } from 'formik';
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { READ_SALES_ORDER_EXPORT } from '../../../services/apiRoutes';
import CustomButton from "../../common/CustomButton";
import CustomCardView from '../../common/CustomCardView';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { ClearRounded } from "@mui/icons-material";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import DetailsParticulars from '../../common/DetailsPage/DetailsParticulars';

const SalesOrderView = ({ handleClose, salesOrderId }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dataParticular, setDataParticular] = useState(null);
    const [totalQty, setTotalQty] = useState(0);
    const [totalRate, setTotalRate] = useState(0);
    const [amountData, setAmount] = useState(0);

    useEffect(() => {
        if (!salesOrderId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_SALES_ORDER_EXPORT(salesOrderId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const salesOrder = response.data.data;
                setData(salesOrder);
                // setDataParticular(salesOrder.particulars);
                const particulars = salesOrder.particulars.map(particular => ({
                    qty: particular.qty || 0,
                    amounts: particular.amount || 0,
                    description: particular.description || 0,
                    rate: parseFloat(particular.rate) || 0,
                    amount: (particular.qty || 0) * (parseFloat(particular.rate) || 0),
                    productName: particular.products.productName,
                    unitName: particular.productsUnit.unitName,
                }));
                const totalAmount = particulars.reduce((acc, item) => acc + item.amount, 0).toFixed(2); // Format total amount to two decimal points
                setAmount(totalAmount);
                setDataParticular(particulars);
                setTotalQty(salesOrder.particulars.reduce((acc, item) => acc + item.qty, 0));
                setTotalRate(salesOrder.particulars.reduce((acc, item) => acc + parseFloat(item.rate), 0));
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [salesOrderId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const values = {
        salesOrderId: data?.salesOrderId || '',
        userId: data?.userId || '',
        companyId: data?.companyId || '',
        customer: data?.customer?.clientCompanyName || '',
        reference: data?.reference || '',
        quotationNo: data?.quotationNo || '',
        date: data?.date || '',
        dueDate: data?.dueDate || '',
        note: data?.note || '',
        particulars: dataParticular.map(particular => ({
            particularName: particular.particularName,
            qty: particular.qty,
            rate: particular.rate,
            unit: particular.unit,
            description: particular.description,
        })) || [],
    };

    const labels = [
        { key: 'customer', label: 'customer ', sm: 12, md: 4, },
        { key: 'dueDate', label: 'Due Date', sm: 12, md: 4, },
        { key: 'quotationNo', label: 'Sales Order No', sm: 12, md: 4, },
        { key: 'reference', label: 'Reference', sm: 12, md: 4, },
        { key: 'date', label: 'Date', sm: 12, md: 4, },
    ];

    const particularsColumns = [

        {
            id: 'productName',
            label: 'Product Name',
            accessor: 'productName',
        },
        {
            id: 'qty',
            label: 'Quantity',
            accessor: 'qty',
            styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'rate',
            label: 'Rate',
            accessor: 'rate',
            styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'unitName',
            label: 'Unit',
            accessor: 'unitName',
            styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'amounts',
            label: 'Amount',
            accessor: 'amounts',
            styleAccessor: {
                textAlign: 'right',
            },
        },


    ];



    const footerData = [
        {
            label: 'Total',
            footerValue: '',
            style: {
                textAlign: 'right',
            },
            colSpan: 1,

        },
        {
            label: '',
            footerValue: totalQty,
            style: {
                textAlign: 'center',

            },

        },
        {
            label: '',
            footerValue: amountData,
            style: {
                textAlign: 'right',

            },
            colSpan: 3,

        },
    ];
    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik initialValues={values} enableReinitialize>
                {() => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#212529" }}>
                                    Sales Order
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>
                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="print">
                                        <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close" onClick={handleClose} >
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                </Box>

                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={0} sx={{ px: "0%" }}>
                            <Grid item xs={12}>
                                <CustomCardView title="Bill To" label={labels} data={values} />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                        Particulars
                                    </Typography>
                                </Box>
                                <DetailsParticulars
                                    title={'Particulars'}
                                    columns={particularsColumns}
                                    data={dataParticular}
                                    footerData={footerData}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <br />
                                <Box>
                                    <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                        In Words
                                    </Typography>
                                    <Typography style={{ color: "#212529", fontWeight: 400, fontSize: "14px", textTransform: "uppercase" }}>
                                        Fifteen thousand four hundred forty one.
                                        &nbsp;Only
                                    </Typography>
                                </Box>
                                <Box sx={{ pt: 5 }}>
                                    <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                        Note :
                                    </Typography>
                                    <Typography style={{ color: "#212529", fontWeight: 400, fontSize: "14px", textTransform: "capitalize" }}>
                                        {values.note}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <br />
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                        Summary
                                    </Typography>
                                </Box>
                                <Card sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 1, maxWidth: 400 }} variant="outlined">
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography>Total Quantity:</Typography>
                                        <Typography>{totalQty}</Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography>Amount:</Typography>
                                        <Typography>₹ {amountData}</Typography>
                                    </Box>

                                    <Divider sx={{ my: 0 }} />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography fontWeight="bold">Grand Total:</Typography>
                                        <Typography fontWeight="bold">₹ {amountData}</Typography>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default SalesOrderView;
