// styles.js
import { styled } from '@mui/material/styles';

// Define the drawerWidth constant (replace with actual value as needed)
// const drawerWidth = 240; 
const drawerWidth = 240;

// Create the Main styled component
export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        marginTop: '140px',
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            // marginLeft: "14%",
            marginLeft: "200px",
        }),
    })
);
