import React, { useState } from 'react';
import { Card, CardHeader, Divider, IconButton, Menu, MenuItem } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ImportPieChart = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const state = {
        series: [44, 55, 13, 43],
        options: {
            chart: {
                width: 380,
                type: 'pie'
            },
            labels: ['Electronics', 'Textiles', 'Furniture', 'Machinery'],

            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    };

    return (
        <Card>
            <CardHeader
                title="Volume of Containers Completed - Import"
                action={
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                }
            />
            <Divider />
            <div id="chart">
                <ReactApexChart
                    options={state.options}
                    series={state.series}
                    type="pie"
                    width={450}
                />
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>Shipper/Supplier</MenuItem>
                <MenuItem onClick={handleClose}>Shipping line</MenuItem>
                <MenuItem onClick={handleClose}>Country</MenuItem>
                <MenuItem onClick={handleClose}>Product Group</MenuItem>
            </Menu>
        </Card>
    );
};

export default ImportPieChart;
