import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

// Define the MessageBox component
const MessageBox = ({ open, onClose, message, type }) => {
    // Define icon based on message type
    const getIcon = () => {
        switch (type) {
            case 'success':
                return <CheckCircleIcon color="success" />;
            case 'error':
                return <ErrorIcon color="error" />;
            default:
                return null;
        }
    };

    // Define the severity for the Alert component
    const getSeverity = () => {
        switch (type) {
            case 'success':
                return 'success';
            case 'error':
                return 'error';
            default:
                return 'info';
        }
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000} // Duration before auto-close
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            action={
                <button onClick={onClose} style={{ color: 'white', background: 'transparent', border: 'none' }}>
                    Close
                </button>
            }
        >
            <Alert
                onClose={onClose}
                severity={getSeverity()}
                icon={getIcon()}
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default MessageBox;
