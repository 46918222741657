import React from 'react';
import DashboardCard from '../components/Dashboard/DashboardCard';
import Overview from '../components/Dashboard/Overview';
import ImportPieChart from '../components/Dashboard/ImportPieChart';
import { Box, Container, Grid } from '@mui/material';
import ExportPieChart from '../components/Dashboard/ExportPieChart';

const Dashboard = () => {
    return (
        <>
            <Box sx={{ backgroundColor: "#f2f5fa" }}>
                <DashboardCard />
            </Box>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={6}>
                    <ExportPieChart />

                </Grid>
                <Grid item xs={12} sm={6}>
                    <ImportPieChart />
                </Grid>
            </Grid>


            <Overview />




        </>
    );
};

export default Dashboard;
